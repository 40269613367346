import { SalaryGridTiersMode, SalaryRangeEmployeeRangePositioning } from "@prisma/client";
import { type TFunction } from "next-i18next";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { assertNotNil } from "~/lib/utils";

type SalaryBandPositioning = {
  type: SalaryRangeEmployeeRangePositioning;
  min: number;
  max: number;
  color: "primary" | "secondary" | "info";
};

const BelowThreshold: SalaryBandPositioning = {
  type: SalaryRangeEmployeeRangePositioning.BELOW_RANGE,
  min: -Infinity,
  max: 0,
  color: "secondary",
};

const AboveThreshold: SalaryBandPositioning = {
  type: SalaryRangeEmployeeRangePositioning.ABOVE_RANGE,
  min: 1,
  max: Infinity,
  color: "primary",
};

const Thresholds: Record<SalaryGridTiersMode, SalaryBandPositioning[]> = {
  HALVES: [
    BelowThreshold,
    {
      type: SalaryRangeEmployeeRangePositioning.BOTTOM_RANGE,
      min: 0,
      max: 0.33,
      color: "secondary",
    },
    {
      type: SalaryRangeEmployeeRangePositioning.MID_RANGE,
      min: 0.33,
      max: 0.66,
      color: "info",
    },
    {
      type: SalaryRangeEmployeeRangePositioning.TOP_RANGE,
      min: 0.66,
      max: 1,
      color: "primary",
    },
    AboveThreshold,
  ],
  THIRDS: [
    BelowThreshold,
    {
      type: SalaryRangeEmployeeRangePositioning.BOTTOM_RANGE,
      min: 0,
      max: 0.33,
      color: "secondary",
    },
    {
      type: SalaryRangeEmployeeRangePositioning.MID_RANGE,
      min: 0.33,
      max: 0.66,
      color: "info",
    },
    {
      type: SalaryRangeEmployeeRangePositioning.TOP_RANGE,
      min: 0.66,
      max: 1,
      color: "primary",
    },
    AboveThreshold,
  ],
  QUARTERS: [
    BelowThreshold,
    {
      type: SalaryRangeEmployeeRangePositioning.BOTTOM_RANGE,
      min: 0,
      max: 0.25,
      color: "secondary",
    },
    {
      type: SalaryRangeEmployeeRangePositioning.BOTTOM_MID_RANGE,
      min: 0.25,
      max: 0.5,
      color: "secondary",
    },
    {
      type: SalaryRangeEmployeeRangePositioning.TOP_MID_RANGE,
      min: 0.5,
      max: 0.75,
      color: "primary",
    },
    {
      type: SalaryRangeEmployeeRangePositioning.TOP_RANGE,
      min: 0.75,
      max: 1,
      color: "primary",
    },
    AboveThreshold,
  ],
};

export const DEFAULT_TIERS_MODE = SalaryGridTiersMode.THIRDS;

export const getDefaultTierNames = (t: TFunction) => [
  t("enum.salary-range-employee-range-positioning.BOTTOM_RANGE"),
  t("enum.salary-range-employee-range-positioning.MID_RANGE"),
  t("enum.salary-range-employee-range-positioning.TOP_RANGE"),
];

export const getRelevantPositionings = (tierMode: SalaryGridTiersMode) => {
  const tierPositionings = match(tierMode)
    .with(SalaryGridTiersMode.HALVES, () => [
      SalaryRangeEmployeeRangePositioning.BOTTOM_RANGE,
      SalaryRangeEmployeeRangePositioning.MID_RANGE,
      SalaryRangeEmployeeRangePositioning.TOP_RANGE,
    ])
    .with(SalaryGridTiersMode.THIRDS, () => [
      SalaryRangeEmployeeRangePositioning.BOTTOM_RANGE,
      SalaryRangeEmployeeRangePositioning.MID_RANGE,
      SalaryRangeEmployeeRangePositioning.TOP_RANGE,
    ])
    .with(SalaryGridTiersMode.QUARTERS, () => [
      SalaryRangeEmployeeRangePositioning.BOTTOM_RANGE,
      SalaryRangeEmployeeRangePositioning.BOTTOM_MID_RANGE,
      SalaryRangeEmployeeRangePositioning.TOP_MID_RANGE,
      SalaryRangeEmployeeRangePositioning.TOP_RANGE,
    ])
    .exhaustive();

  return [
    SalaryRangeEmployeeRangePositioning.BELOW_RANGE,
    ...tierPositionings,
    SalaryRangeEmployeeRangePositioning.ABOVE_RANGE,
  ];
};

const getThresholdsIndex = (params: { tiersMode: SalaryGridTiersMode; rangePenetration: number }) => {
  const thresholds = Thresholds[params.tiersMode];

  // If the range penetration is exactly at the beginning of the range, return the index of the first tier after the below range
  if (params.rangePenetration === BelowThreshold.max) {
    return thresholds.findIndex((rank) => rank.type === BelowThreshold.type) + 1;
  }

  // If the range penetration is exactly at the end of the range, return the index of the last tier before the above range
  if (params.rangePenetration === AboveThreshold.min) {
    return thresholds.findIndex((rank) => rank.type === AboveThreshold.type) - 1;
  }

  // If the range penetration is before the below range, return the index of the below range
  if (params.rangePenetration < BelowThreshold.max) {
    return thresholds.findIndex((rank) => rank.type === BelowThreshold.type);
  }

  // If the range penetration is after the above range, return the index of the above range
  if (params.rangePenetration > AboveThreshold.min) {
    return thresholds.findIndex((rank) => rank.type === AboveThreshold.type);
  }

  // If the range penetration is in the middle of the range, return the index of the tier that contains the range penetration
  return Thresholds[params.tiersMode].findIndex((rank) => {
    if (params.rangePenetration > rank.max) {
      return false;
    }

    if (params.rangePenetration < rank.min) {
      return false;
    }

    return true;
  }) as number;
};

export const getSalaryBandPositioning = (params: { tiersMode: SalaryGridTiersMode; rangePenetration: number }) => {
  return Thresholds[params.tiersMode][getThresholdsIndex(params)] as SalaryBandPositioning;
};

export const getSalaryBandPositioningDetails = (
  t: TFunction,
  params: {
    tiersMode: SalaryGridTiersMode;
    tiersNames: string[];
    positioning: SalaryRangeEmployeeRangePositioning;
  }
) => {
  const tierIndex = Thresholds[params.tiersMode].findIndex((item) => item.type === params.positioning);
  const tier = assertNotNil(Thresholds[params.tiersMode][tierIndex]);

  const title = value(() => {
    if (params.tiersMode === SalaryGridTiersMode.HALVES) {
      return t(`enum.salary-range-employee-range-positioning.${params.positioning}`);
    }

    if (tier.type === SalaryRangeEmployeeRangePositioning.BELOW_RANGE) {
      return t("enum.salary-range-employee-range-positioning.BELOW_RANGE");
    }
    if (tier.type === SalaryRangeEmployeeRangePositioning.ABOVE_RANGE) {
      return t("enum.salary-range-employee-range-positioning.ABOVE_RANGE");
    }
    return assertNotNil(params.tiersNames[tierIndex - 1]);
  });

  return {
    ...tier,
    title,
  };
};
