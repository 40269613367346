import {
  type Employee,
  type ExternalEmployee,
  ExternalEmployeeDriftField,
  ExternalEmployeeStatus,
  ExternalRemunerationType,
  type Prisma,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { chain, isDate, isNil, isUndefined, pickBy, uniq } from "~/lib/lodash";
import { logInfo, logWarn } from "~/lib/logger";
import { isIn } from "~/lib/utils";
import {
  computeExternalEmployeeTotalRemuneration,
  type ExternalEmployeeForRemunerationComputation,
} from "~/services/external-employee";
import { type ExternalEmployeeForSync } from "~/services/synchronization/syncExternalEmployee";

const SUBSTANTIAL_REMUNERATION_DELTA = 10;

// These fields are automatically trusted, meaning if their value has changed it will be fixed
// in the database without causing a drift
export const automaticallyTrustedFields = [
  "firstName",
  "lastName",
  "isFounder",
  "hireDate",
  "birthDate",
  "gender",
  "currencyId",
  "employeeNumber",
  "source",
] as const;

export type TrustedField = (typeof automaticallyTrustedFields)[number];

// These fields can't be set to null after having been set to a value
const nonNullableFields: TrustedField[] = ["employeeNumber"];

const isTrustedField = (field: string): field is TrustedField => isIn(field, automaticallyTrustedFields);

const buildEmployeePayloadFromDifferences = (
  previousExternalEmployee: ExternalEmployeeForSync,
  nextExternalEmployee: ExternalEmployeeForSync
) =>
  pickBy(nextExternalEmployee, (nextValue, field) => {
    if (!isTrustedField(field)) {
      return false;
    }

    if (nonNullableFields.includes(field) && isNil(nextValue)) {
      return false;
    }

    if (isDate(previousExternalEmployee[field]) && isDate(nextValue)) {
      return new Date(previousExternalEmployee[field] as Date).getTime() !== new Date(nextValue).getTime();
    }

    return previousExternalEmployee[field] !== nextValue;
  }) as Partial<Pick<ExternalEmployeeForSync, TrustedField>>;

const isLoggableRemunerationDifference = (previousAmount?: number | null, nextAmount?: number | null) => {
  if (!previousAmount && previousAmount !== 0) {
    return false;
  }

  if (!nextAmount && nextAmount !== 0) {
    return false;
  }

  if (nextAmount < previousAmount) {
    return true;
  }

  const deltaBetweenAmounts = Math.abs(previousAmount - nextAmount);
  const driftPercentage = (deltaBetweenAmounts / previousAmount) * 100;

  return driftPercentage > SUBSTANTIAL_REMUNERATION_DELTA;
};

export const getNewCompensationFromDifferences = (
  ctx: AppContext,
  previousExternalEmployee: ExternalEmployeeForRemunerationComputation & Pick<ExternalEmployee, "id" | "companyId">,
  nextExternalEmployee: ExternalEmployeeForRemunerationComputation & Pick<ExternalEmployee, "id" | "companyId">,
  currentMappedEmployee: Pick<Employee, "id" | "baseSalary" | "fixedBonus" | "onTargetBonus"> | null
) => {
  return (type: ExternalRemunerationType) => {
    const previousRemunerationAmount = computeExternalEmployeeTotalRemuneration(previousExternalEmployee, type);
    const nextRemunerationAmount = computeExternalEmployeeTotalRemuneration(nextExternalEmployee, type);
    const currentEmployeeRemunerationAmount = match(type)
      .with(ExternalRemunerationType.FIXED_SALARY, () => currentMappedEmployee?.baseSalary ?? 0)
      .with(ExternalRemunerationType.FIXED_BONUS, () => currentMappedEmployee?.fixedBonus ?? 0)
      .with(ExternalRemunerationType.VARIABLE_BONUS, () => currentMappedEmployee?.onTargetBonus ?? 0)
      .otherwise(() => 0);

    if (
      previousRemunerationAmount === nextRemunerationAmount &&
      (!currentMappedEmployee || currentEmployeeRemunerationAmount === nextRemunerationAmount)
    ) {
      return undefined;
    }

    if (isLoggableRemunerationDifference(previousRemunerationAmount, nextRemunerationAmount)) {
      logWarn(ctx, `[drift] Unusual compensation update - above than 10% or lower than the previous`, {
        companyId: nextExternalEmployee.companyId,
        externalEmployeeId: nextExternalEmployee.id,
        previousRemunerationAmount,
        nextRemunerationAmount,
        remunerationType: type,
      });
    }

    return nextRemunerationAmount;
  };
};

export const getEmployeeUpdatePayload = (
  ctx: AppContext,
  previousExternalEmployee: ExternalEmployeeForSync,
  nextExternalEmployee: ExternalEmployeeForSync
) => {
  const updatePayload = buildEmployeePayloadFromDifferences(
    previousExternalEmployee,
    nextExternalEmployee
  ) as Prisma.EmployeeUncheckedUpdateInput;

  let needsBucketHashUpdate = false;

  if (
    nextExternalEmployee.location?.mappedLocation &&
    previousExternalEmployee.location?.id !== nextExternalEmployee.location?.id
  ) {
    const locationId = nextExternalEmployee.location.mappedLocation.id;
    const fallbackLocationId = nextExternalEmployee.location.mappedLocation.fallbackLocationId;

    updatePayload.locationId = fallbackLocationId ?? locationId;
    updatePayload.mappingLocationId = locationId;
    needsBucketHashUpdate = true;
  }

  if (previousExternalEmployee.job?.id !== nextExternalEmployee.job?.id) {
    if (nextExternalEmployee.job) {
      updatePayload.externalJobTitle = nextExternalEmployee.job.name;
      if (nextExternalEmployee.job.mappedJob) {
        updatePayload.jobId = nextExternalEmployee.job.mappedJob.id;
        needsBucketHashUpdate = true;
      }
    }
  }

  if (previousExternalEmployee.level?.id !== nextExternalEmployee.level?.id) {
    if (nextExternalEmployee.level) {
      updatePayload.externalLevel = nextExternalEmployee.level.name;

      if (nextExternalEmployee.level.mappedLevel) {
        updatePayload.level = nextExternalEmployee.level.mappedLevel;
        needsBucketHashUpdate = true;
      }
    }
  }

  if (needsBucketHashUpdate) {
    updatePayload.bucketHash = `${updatePayload.locationId}-${updatePayload.jobId}-${updatePayload.level}`;
  }

  const getNewCompensation = getNewCompensationFromDifferences(
    ctx,
    previousExternalEmployee,
    nextExternalEmployee,
    previousExternalEmployee?.mappedEmployee
  );

  const baseSalary = getNewCompensation(ExternalRemunerationType.FIXED_SALARY);
  const fixedBonus = getNewCompensation(ExternalRemunerationType.FIXED_BONUS);
  const onTargetBonus = getNewCompensation(ExternalRemunerationType.VARIABLE_BONUS);

  return {
    ...updatePayload,
    ...(!isUndefined(baseSalary) && { baseSalary: Math.round(baseSalary) }),
    ...(!isUndefined(fixedBonus) && { fixedBonus: Math.round(fixedBonus), fixedBonusPercentage: null }),
    ...(!isUndefined(onTargetBonus) && { onTargetBonus: Math.round(onTargetBonus), onTargetBonusPercentage: null }),
  };
};

export const changesRequiringHistoricalVersion = [
  "employeeNumber",
  "source",
  "baseSalary",
  "fixedBonus",
  "onTargetBonus",
  "jobId",
  "locationId",
  "level",
] as const;

export const requiresHistoricalVersion = (updatePayload: Prisma.EmployeeUncheckedUpdateInput) =>
  changesRequiringHistoricalVersion.some((key) => chain(updatePayload).omitBy(isUndefined).has(key).value());

type ExternalEmployeeForComputeDriftFields = Pick<ExternalEmployeeForSync, "job" | "location" | "level">;

export const computeDriftFields = (
  previousExternalEmployee: ExternalEmployeeForComputeDriftFields,
  nextExternalEmployee: ExternalEmployeeForComputeDriftFields
) => {
  const driftFields: ExternalEmployee["driftFields"] = [];

  const hasJobDrift =
    previousExternalEmployee.job?.id !== nextExternalEmployee.job?.id && !nextExternalEmployee.job?.mappedJobId;
  if (hasJobDrift) {
    driftFields.push(ExternalEmployeeDriftField.JOB);
  }

  const hasLocationDrift =
    previousExternalEmployee.location?.id !== nextExternalEmployee.location?.id &&
    !nextExternalEmployee.location?.mappedLocationId;
  if (hasLocationDrift) {
    driftFields.push(ExternalEmployeeDriftField.LOCATION);
  }

  const hasLevelDrift =
    previousExternalEmployee.level?.id !== nextExternalEmployee.level?.id && !nextExternalEmployee.level?.mappedLevel;
  if (hasLevelDrift) {
    driftFields.push(ExternalEmployeeDriftField.LEVEL);
  }

  return uniq(driftFields);
};

export const cleanEmployeeDrifts = async (ctx: AppContext, companyId: number) => {
  const driftExternalEmployees = await ctx.prisma.externalEmployee.findMany({
    where: {
      companyId,
      status: ExternalEmployeeStatus.NEEDS_REMAPPING,
    },
    select: {
      id: true,
      driftFields: true,
      location: { select: { mappedLocationId: true } },
      job: { select: { mappedJobId: true } },
      level: { select: { mappedLevel: true } },
      mappedEmployee: {
        select: {
          id: true,
          locationId: true,
          jobId: true,
          level: true,
        },
      },
    },
  });

  logInfo(ctx, "[drift] Cleaning drifts for company", { companyId });

  await mapSeries(driftExternalEmployees, async (externalEmployee) => {
    const { mappedEmployee } = externalEmployee;

    if (!mappedEmployee) {
      return;
    }

    const cleanedDrifts = externalEmployee.driftFields.filter((driftField) =>
      match(driftField)
        .with(
          ExternalEmployeeDriftField.LOCATION,
          () => externalEmployee.location?.mappedLocationId !== mappedEmployee.locationId
        )
        .with(ExternalEmployeeDriftField.JOB, () => externalEmployee.job?.mappedJobId !== mappedEmployee.jobId)
        .with(ExternalEmployeeDriftField.LEVEL, () => externalEmployee.level?.mappedLevel !== mappedEmployee.level)
        .exhaustive()
    );

    if (cleanedDrifts.length === 0) {
      logInfo(ctx, "[drift] Setting employee to MAPPED after all their drifts were cleaned", {
        externalEmployeeId: externalEmployee.id,
      });
    }

    await ctx.prisma.externalEmployee.update({
      where: { id: externalEmployee.id },
      data: {
        driftFields: cleanedDrifts,
        status: cleanedDrifts.length > 0 ? ExternalEmployeeStatus.NEEDS_REMAPPING : ExternalEmployeeStatus.MAPPED,
      },
    });
  });
};
