import { type AppContext } from "~/lib/context";

export const getGenderDataAccess = async (ctx: AppContext) => {
  const user = ctx.user;

  if (!user) {
    return true;
  }

  if (user.company?.statsId) {
    const stats = await ctx.prisma.companyStats.findUniqueOrThrow({ where: { id: user.company.statsId } });

    return stats.genderCompletionRate > 0.95;
  }

  if (user.company?.type === "VENTURE_CAPITAL") {
    return true;
  }

  return false;
};
