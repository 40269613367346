import { ComparisonGeographicStrategy, type EmployeeLevel, type Prisma } from "@prisma/client";
import { match } from "ts-pattern";
import { compact } from "~/lib/lodash";
import { getId } from "~/lib/utils";
import { type GenerateDatasetEmployeeWhereParams } from "~/services/employee-stats/dataset-employees-stats/fetchComparableDatasetEmployeesForStats";
import { type CompanyWithCompensationSettings } from "~/services/employee-stats/fetchCompanyWithCompensationSettings";
import { getMergedLevels } from "~/services/employee/employeeLevel";

export function generateDatasetEmployeeWhere(
  employeesForBucket: GenerateDatasetEmployeeWhereParams,
  params: {
    company: CompanyWithCompensationSettings;
    useBenchmarkLevelsRange: boolean;
    capitalLocations: Record<number, number>;
  }
): Prisma.DatasetEmployeeWhereInput {
  const whereArgs: Record<string, Prisma.DatasetEmployeeWhereInput> = {};

  const formattedBuckets = employeesForBucket.map((employee) => {
    const levels = getMergedLevels(employee.level, {
      mergeAdvancedLevels: !params.company.useAdvancedLevels,
    }) as EmployeeLevel[];

    const countryId = match(params.company.comparisonGeographicStrategy)
      .with(ComparisonGeographicStrategy.ENTIRE_COUNTRY, () => employee.location.countryId)
      .with(ComparisonGeographicStrategy.COUNTRY_CAPITAL, () => undefined)
      .with(ComparisonGeographicStrategy.EMPLOYEE_LOCATION, () => undefined)
      .with(ComparisonGeographicStrategy.SPECIFIC_LOCATION, () => undefined)
      .exhaustive();

    const locationIds = match(params.company.comparisonGeographicStrategy)
      .with(ComparisonGeographicStrategy.ENTIRE_COUNTRY, () => undefined)
      .with(ComparisonGeographicStrategy.COUNTRY_CAPITAL, () =>
        compact([params.capitalLocations[employee.location.countryId]])
      )
      .with(ComparisonGeographicStrategy.EMPLOYEE_LOCATION, () => [employee.location.id])
      .with(ComparisonGeographicStrategy.SPECIFIC_LOCATION, () => params.company.compareSpecificLocations.map(getId))
      .exhaustive();

    return {
      countryId,
      locationIds,
      jobId: employee.jobId,
      benchmarkLevelsRange: {
        min: employee.benchmarkLevel.min,
        max: employee.benchmarkLevel.max,
      },
      level: levels,
      useBenchmarkLevelsRange: params.useBenchmarkLevelsRange,
    };
  });

  formattedBuckets.map((employeeParams) => {
    whereArgs[JSON.stringify(employeeParams)] = {
      ...(employeeParams.countryId && { location: { country: { id: { in: [employeeParams.countryId] } } } }),
      ...(employeeParams.locationIds && { location: { id: { in: employeeParams.locationIds } } }),
      ...(employeeParams.useBenchmarkLevelsRange
        ? {
            OR: [
              {
                benchmarkLevelMin: { lte: employeeParams.benchmarkLevelsRange?.max },
                benchmarkLevelMax: { gte: employeeParams.benchmarkLevelsRange?.min },
              },
              {
                benchmarkLevelMin: { gte: employeeParams.benchmarkLevelsRange?.min },
                benchmarkLevelMax: { lte: employeeParams.benchmarkLevelsRange?.max },
              },
            ],
          }
        : { level: { in: employeeParams.level } }),
      jobId: employeeParams.jobId,
    };
  });

  return {
    OR: Object.values(whereArgs).map((conditions) => ({
      AND: [conditions],
    })),
  };
}

export function generateEmployeeWhere(
  employeesForBucket: GenerateDatasetEmployeeWhereParams,
  params: {
    company: CompanyWithCompensationSettings;
    capitalLocations: Record<number, number>;
  }
): Prisma.EmployeeWhereInput {
  const whereArgs: Record<string, Prisma.EmployeeWhereInput> = {};

  const formattedBuckets = employeesForBucket.map((employee) => {
    const levels = getMergedLevels(employee.level, {
      mergeAdvancedLevels: !params.company.useAdvancedLevels,
    }) as EmployeeLevel[];

    const countryId = match(params.company.comparisonGeographicStrategy)
      .with(ComparisonGeographicStrategy.ENTIRE_COUNTRY, () => employee.location.countryId)
      .with(ComparisonGeographicStrategy.COUNTRY_CAPITAL, () => undefined)
      .with(ComparisonGeographicStrategy.EMPLOYEE_LOCATION, () => undefined)
      .with(ComparisonGeographicStrategy.SPECIFIC_LOCATION, () => undefined)
      .exhaustive();

    const locationIds = match(params.company.comparisonGeographicStrategy)
      .with(ComparisonGeographicStrategy.ENTIRE_COUNTRY, () => undefined)
      .with(ComparisonGeographicStrategy.COUNTRY_CAPITAL, () =>
        compact([params.capitalLocations[employee.location.countryId]])
      )
      .with(ComparisonGeographicStrategy.EMPLOYEE_LOCATION, () => [employee.location.id])
      .with(ComparisonGeographicStrategy.SPECIFIC_LOCATION, () => params.company.compareSpecificLocations.map(getId))
      .exhaustive();

    return {
      countryId,
      locationIds,
      jobId: employee.jobId,
      benchmarkLevelsRange: {
        min: employee.benchmarkLevel.min,
        max: employee.benchmarkLevel.max,
      },
      level: levels,
    };
  });

  formattedBuckets.map((employeeParams) => {
    whereArgs[JSON.stringify(employeeParams)] = {
      ...(employeeParams.countryId && { location: { country: { id: { in: [employeeParams.countryId] } } } }),
      ...(employeeParams.locationIds && { location: { id: { in: employeeParams.locationIds } } }),
      OR: [
        {
          benchmarkLevel: {
            min: { lte: employeeParams.benchmarkLevelsRange?.max },
            max: { gte: employeeParams.benchmarkLevelsRange?.min },
          },
        },
        {
          benchmarkLevel: {
            min: { gte: employeeParams.benchmarkLevelsRange?.min },
            max: { lte: employeeParams.benchmarkLevelsRange?.max },
          },
        },
      ],
      jobId: employeeParams.jobId,
    };
  });

  return {
    OR: Object.values(whereArgs).map((conditions) => ({
      AND: [conditions],
    })),
  };
}
