import { EmployeeStatsComparisonScope } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { logInfo } from "~/lib/logger";
import { validateRequestAuthorised } from "~/lib/security";
import { computeBucketsCompensationStats } from "~/services/company-dashboard/computeBucketsCompensationStats";
import { computeCompensationStatsByJobFamilyCategory } from "~/services/company-dashboard/computeCompensationStatsByJobFamilyCategory";
import { computeGenderMarketFairnessStatsForBuckets } from "~/services/company-dashboard/computeGenderMarketFairnessStatsForBuckets";
import { computeGenderEqualityIndex } from "~/services/company-dashboard/genderEqualityIndex";
import { generateEmployeesBuckets } from "~/services/company-dashboard/generateEmployeesBuckets";
import { getDashboardCompany } from "~/services/company-dashboard/getDashboardCompany";
import { getDashboardComparableCompanies } from "~/services/company-dashboard/getDashboardComparableCompanies";
import { getDashboardEmployees } from "~/services/company-dashboard/getDashboardEmployees";
import { computeGenderEqualityManagementStats } from "~/services/company-dashboard/management";
import { computeBucketsGenderPayGapStats } from "~/services/company-dashboard/payGap";
import { computeGenderEqualityTopEarnersStats } from "~/services/company-dashboard/topEarners";
import { getDefaultCurrency } from "~/services/currency";
import { isFromCompany } from "~/services/employee";

export const getCompanyDashboardStats = async (
  ctx: AppContext,
  params: { companyId: number; companyEmployeesCount: number }
) => {
  const user = getRequiredUser(ctx);

  validateRequestAuthorised(ctx, { companyId: params.companyId });

  // for now, we don't want to compute stats for too big companies as it will takes too long and fail
  // the impact is minimal as we only use these stats for the VC dashboard
  // the threshold is kind of arbitrary
  const isTooBigCompany = params.companyEmployeesCount > 1500;

  if (isTooBigCompany) {
    logInfo(
      ctx,
      `[company-stats] Skipping some stats update for too big company with ${params.companyEmployeesCount} employees`,
      {
        companyId: params.companyId,
        employeesCount: params.companyEmployeesCount,
      }
    );
  }

  const currency = await getDefaultCurrency(ctx);
  const company = await getDashboardCompany(ctx, { companyId: params.companyId });
  const { comparableCompanies } = await getDashboardComparableCompanies(ctx, { currency, company });
  const employees = await getDashboardEmployees(ctx, {
    company,
    comparableCompanyIds: comparableCompanies.map(({ id }) => id),
  });
  const companyEmployees = employees.filter(isFromCompany([company.id]));

  const buckets = generateEmployeesBuckets({
    employees,
    company,
  });
  const countryBuckets = generateEmployeesBuckets({
    employees,
    company,
    locationScope: EmployeeStatsComparisonScope.COUNTRY,
  });
  const genderEqualityPayGapBuckets = generateEmployeesBuckets({
    employees: companyEmployees,
    company,
    levelScope: company.useExternalLevels ? "external" : "internal",
  });

  const compensationStats = isTooBigCompany
    ? null
    : await computeBucketsCompensationStats(ctx, { company, buckets: countryBuckets });
  const compensationStatsByJobFamilyCategory = isTooBigCompany
    ? null
    : await computeCompensationStatsByJobFamilyCategory(ctx, {
        company,
        buckets: countryBuckets,
      });

  const payGapStats = computeBucketsGenderPayGapStats({ buckets: genderEqualityPayGapBuckets });
  const marketFairnessStats = computeGenderMarketFairnessStatsForBuckets({ buckets });
  const topEarnersStats = computeGenderEqualityTopEarnersStats({
    employees: companyEmployees,
    anonymised: user.company.type === "VENTURE_CAPITAL",
  });
  const managementStats = computeGenderEqualityManagementStats({
    employees: companyEmployees,
  });
  const genderEqualityIndex = computeGenderEqualityIndex({
    payGapScore: payGapStats?.score ?? null,
    topEarnersScore: topEarnersStats?.score ?? null,
    managementScore: managementStats?.score ?? null,
    marketFairnessScore: marketFairnessStats?.score ?? null,
  });

  return {
    compensationStats,
    payGapStats,
    topEarnersStats,
    marketFairnessStats,
    managementStats,
    genderEqualityIndex,
    compensationStatsByJobFamilyCategory,
  };
};
