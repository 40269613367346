import { useQuery } from "~/hooks/useQueries";
import { IMPERSONATION_STATUS_QUERY_KEY } from "~/pages/api/auth/company-impersonation/impersonation-status";
import { type NullableAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { type ImpersonationStatus } from "~/services/impersonationStatus";

export const useImpersonationStatus = (params: {
  user: NullableAuthenticatedUser;
  initialImpersonatingStatus?: ImpersonationStatus;
}): ImpersonationStatus => {
  const { data: impersonationStatus } = useQuery<ImpersonationStatus>({
    path: IMPERSONATION_STATUS_QUERY_KEY,
    params: { companyId: params.user?.companyId },
    options: {
      enabled: !!params.user,
      initialData: params.initialImpersonatingStatus,
      keepPreviousData: true,
      cacheTime: 0,
    },
    config: { method: "POST" },
  });

  if (!impersonationStatus) {
    return {
      impersonatingUser: false,
      impersonatingCompany: false,
    };
  }

  return impersonationStatus;
};
