import { CompensationReviewCampaignStatus, CompensationReviewConfigurationStep } from "@prisma/client";
import { ensure } from "~/lib/ensure";
import { resetCampaignRecommendations } from "~/services/compensation-review/campaigns/admin/resetCampaignRecommendations";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { canConfigureCampaign } from "~/services/compensation-review/compensationReviewPermissions";

export const startCampaignSandbox = async (ctx: CompensationReviewCampaignContext) => {
  ensure(() => canConfigureCampaign({ ...ctx, step: CompensationReviewConfigurationStep.CONFIRM_AND_START }));

  await resetCampaignRecommendations(ctx);

  await ctx.prisma.compensationReviewCampaign.update({
    where: { id: ctx.campaign.id },
    data: {
      status: CompensationReviewCampaignStatus.SANDBOX,
      requiresSandboxReset: false,
    },
  });
};
