import { CompensationReviewRecommendationStatus } from "@prisma/client";
import { mapSeries } from "bluebird";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { type FetchCompensationReviewEmployeesResult } from "~/services/compensation-review/campaigns/admin/fetchCompensationReviewEmployees";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import { computeDateProration } from "~/services/compensation-review/shared/computeDateProration";

export const initialiseCompensationReviewRecommendations = async (
  ctx: CompensationReviewContext,
  params: {
    employee: FetchCompensationReviewEmployeesResult["items"][number];
  }
) => {
  const user = getRequiredUser(ctx);
  const { employee } = params;

  const commonPayload = {
    ...prismaCompensationReviewScope(ctx.scope),
    companyId: user.companyId,
    employeeId: employee.id,
  };

  if (
    employee.baseSalaryRecommendation ||
    employee.variablePayRecommendation ||
    employee.annualPerformanceBonusRecommendation
  ) {
    const { proratedAmount: proratedOnTargetEarningsIncrease } = computeDateProration({
      amount: employee.convertedOnTargetEarningsRecommendation,
      prorationStartDate: ctx.parameters.budget.prorationStartDate,
      effectiveDate: employee.effectiveDate,
    });

    await ctx.prisma.compensationReviewRecommendation.create({
      data: {
        ...commonPayload,
        position: -1,
        status: CompensationReviewRecommendationStatus.SUBMITTED,
        isFinalRecommendation: false,
        isListedToReviewer: true,
        baseSalaryIncrease: employee.baseSalaryRecommendation,
        variablePayIncrease: employee.variablePayRecommendation,
        convertedOnTargetEarningsIncrease: employee.convertedOnTargetEarningsRecommendation,
        proratedOnTargetEarningsIncrease,
        exceptionalBonus: null,
        annualPerformanceBonus: employee.annualPerformanceBonusRecommendation,
        submittedAt: new Date(),
      },
    });
  }

  const reviewersIds: number[] = [];

  const recommendationsPayload = {
    baseSalaryRecommendation: employee.baseSalaryRecommendation,
    convertedBaseSalaryRecommendation: employee.convertedBaseSalaryRecommendation,
    variablePayRecommendation: employee.variablePayRecommendation,
    convertedVariablePayRecommendation: employee.convertedVariablePayRecommendation,
    onTargetEarningsRecommendation: employee.onTargetEarningsRecommendation,
    convertedOnTargetEarningsRecommendation: employee.convertedOnTargetEarningsRecommendation,
    proratedOnTargetEarningsRecommendation: employee.proratedOnTargetEarningsRecommendation,
  };

  await mapSeries(employee.reviewers, async (reviewer, position) => {
    if (!reviewer) return;

    const isListedToReviewer = !reviewersIds.includes(reviewer.id);

    await ctx.prisma.compensationReviewRecommendation.create({
      data: {
        ...commonPayload,
        ...recommendationsPayload,
        reviewerId: reviewer.id,
        position,
        status:
          position === 0
            ? CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW
            : CompensationReviewRecommendationStatus.WAITING_FOR_ANOTHER_REVIEW,
        isFinalRecommendation: false,
        isListedToReviewer,
      },
    });

    reviewersIds.push(reviewer.id);
  });

  if (ctx.parameters.finalReviewerId) {
    const isListedToReviewer = !reviewersIds.includes(ctx.parameters.finalReviewerId);

    await ctx.prisma.compensationReviewRecommendation.create({
      data: {
        ...commonPayload,
        ...recommendationsPayload,
        reviewerId: ctx.parameters.finalReviewerId,
        position: reviewersIds.length,
        status: reviewersIds.length
          ? CompensationReviewRecommendationStatus.WAITING_FOR_ANOTHER_REVIEW
          : CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW,
        isFinalRecommendation: true,
        isListedToReviewer,
      },
    });
  }
};
