import { type Employee, IntegrationSource, type User } from "@prisma/client";
import { type JsonObject, type JsonPrimitive, type JsonValue } from "type-fest";
import { type AnySchema, type Asserts, date, number, string, type TypeOf } from "yup";
import packageJson from "~/../package.json";
import { config } from "~/config";
import { has, isArray, isNil, isObject, isString, mapValues } from "~/lib/lodash";

export type { TypeOf as YupInputType, Asserts as YupOutputType };

export type Predicate<T> = (item: T) => boolean;

export const getId = <T extends { id: number }>(item: T) => {
  return item.id;
};

export const idIs =
  (search?: number | null) =>
  <T extends { id: number }>(item: T) => {
    return item.id === search;
  };

export const idIsIn =
  (search: number[]) =>
  <T extends { id: number }>(item: T) => {
    return search.includes(item.id);
  };

export const arrayHasValues = <T>(array: T[] | null | undefined): array is T[] => {
  return Array.isArray(array) && array.length > 0;
};

export const pass = (): true => {
  return true;
};

export const skip = (): false => {
  return false;
};

export const not = <T>(predicate: Predicate<T>) => {
  return (item: T): boolean => {
    return !predicate(item);
  };
};

export const recursiveSort = (object: Record<string, unknown>): Record<string, unknown> => {
  if (isString(object)) {
    return object;
  }

  const keys = Object.keys(object);
  keys.sort();

  const newObject: typeof object = {};
  for (const key of keys) {
    newObject[key] = recursiveSort(object[key] as Record<string, unknown>);
  }

  return newObject;
};

/**
 * Type-safe Array.includes
 */
export const isIn = <T>(item: T, array: Readonly<T[]>): boolean => {
  return array.includes(item);
};

/**
 * Type-safe Object.keys
 */
export const getKeys = <T extends string>(object: Partial<Record<T, unknown>>): T[] => {
  return Object.keys(object) as T[];
};

export const fireAndForget = async <T>(promise: Promise<T>) => {
  if (config.app.env === "test" || config.app.isCli) {
    return await promise;
  }
};

export const isNotNull = <T>(value: T | null): value is T => {
  return value !== null;
};

export const assertNotNil = <T>(value?: T | null | undefined): T => {
  if (isNil(value)) {
    throw new Error("NotNil assertion failed");
  }

  return value;
};

export const assertProps = <T extends Record<string, unknown>, FieldName extends keyof T>(
  obj: T,
  props: FieldName[]
): typeof obj & Record<FieldName, NonNullable<T[FieldName]>> => {
  for (const prop of props) {
    if (!has(obj, prop)) {
      throw new Error(`Object is missing the following property : ${String(prop)}`);
    }
  }

  return obj as typeof obj & Record<FieldName, NonNullable<T[FieldName]>>;
};

export type SetNonNullable<T extends Record<string, unknown>, FieldName extends keyof T> = T &
  Record<FieldName, NonNullable<T[FieldName]>>;

export const hasNonNullableField = <T extends Record<string, unknown>, FieldName extends keyof T>(
  object: T,
  prop: FieldName
): object is SetNonNullable<T, FieldName> => {
  return object[prop] !== null;
};

export const humanJoin = (strings: string[]) => {
  let output = "";

  strings.forEach((string, index) => {
    if (index > 0) {
      if (index === strings.length - 1) {
        output += " & ";
      } else {
        output += ", ";
      }
    }

    output += string;
  });

  return output;
};

export const sleep = (milliseconds: number) => {
  return new Promise((res) => setTimeout(res, milliseconds));
};

type JsonAny = JsonObject | JsonPrimitive;

// Recursive mapValues
export const mapValuesDeep = (obj: JsonValue, iteree: (value: JsonValue) => JsonValue): JsonValue => {
  if (!isObject(obj)) {
    return iteree(obj);
  }

  if (isArray(obj)) {
    return obj.map((v) => mapValuesDeep(v, iteree));
  }

  return mapValues(obj, (v: JsonAny) => mapValuesDeep(v, iteree));
};

// Encodes all values in an email props so they are URL safe
export const safeEmailProps = (props: JsonAny) =>
  JSON.stringify(mapValuesDeep(props, (value) => (isString(value) ? encodeURIComponent(value) : value)));

export const genericAvatarUrl = (firstName: string, lastName: string) =>
  `https://eu.ui-avatars.com/api/?name=${firstName}+${lastName}`;

// Mostly used for mui numeric text field, that return a string representing the value
// empty string converted to NaN, we prefer to make it null.
export const nullEmptyString = (value: string) => (value === "" ? null : value);

export const isFiguresEmployee = (user: Pick<User, "companyId"> | null) => user && isFiguresCompany(user.companyId);

export const getServiceAccountUserId = () => 0 as const;

export const getFiguresCompanyId = () => 143 as const;

export const isFiguresCompany = (companyId: number) => companyId === getFiguresCompanyId();

export const isEmployeeImportedFromIntegration = (employee?: Pick<Employee, "source"> | null) =>
  !!employee && employee.source in IntegrationSource;

//takes a yup schema and casts the value or returns undefined
export const tryCast = (
  schema: AnySchema,
  value: string | number | boolean | Date | undefined,
  options?: { assert: boolean }
) => {
  try {
    return schema.cast(value, options);
  } catch (error) {
    return null;
  }
};

export const isPossibleDate = date().transform((value, originalValue) => {
  if (!value) return undefined;

  if (number().isValidSync(originalValue) || percentageRegex.test(originalValue)) {
    return undefined;
  }

  return date().cast(originalValue);
});

//takes a percentage string and returns a number
export const percentageRegex = /^\d+(\.\d{1,2})?%$/;
export const percentageToDecimalSchema = string()
  .test("is-percentage", "Invalid percentage format", (value) => {
    if (!value) return true;

    return percentageRegex.test(value);
  })
  .transform((value) => {
    if (value.includes("%")) {
      return parseFloat(value.replace("%", "")) / 100;
    }
    return undefined;
  });

export const welcomeMessage = `##   Welcome to
##    ________ _
##   |_   __  (_)
##     | |_ \\_|_   .--./) __   _  _ .--. .---.  .--.
##     |  _| [  | / /'\`\\;[  | | |[ \`/'\`\\] /__\\\\( (\`\\]
##    _| |_   | | \\ \\._// | \\_/ |,| |   | \\__., \`'.'.
##   |_____| [___].',__\`  '.__.'_[___]   '.__.'[\\__) )
##               ( ( __))
##
##   Version: ${packageJson.version}`;

export const removeAdjacentDuplicates = <T>(array: T[]): T[] => {
  const result: T[] = [];

  array.forEach((item, index) => {
    if (item !== array[index - 1]) {
      result.push(item);
    }
  });

  return result;
};

// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
export const stripDiacritics = (string: string) => {
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

type FindPredicate<T> = (value: T, index?: number, collection?: T[]) => Promise<boolean>;

export const findAsyncInArray = async <T>(elements: T[], predicate: FindPredicate<T>): Promise<T | undefined> => {
  for (const [index, element] of elements.entries()) {
    if (await predicate(element, index, elements)) {
      return element;
    }
  }

  return undefined;
};
