import { addSeconds } from "date-fns";
import { type SessionOptions } from "next-iron-session";
import { value } from "~/components/helpers";
import { type Sender } from "~/lib/external/sendinblue/client";

const url = process.env["NEXT_PUBLIC_URL"] ?? "https://app.figures.hr";

export const config = {
  app: {
    env: process.env["NODE_ENV"] ?? "production",
    debug: process.env["NODE_ENV"] === "development",
    rev: process.env["GIT_REV"] as string | null,

    url,
    websiteUrl: process.env["NEXT_PUBLIC_WEBSITE_URL"] ?? "https://figures.hr",
    publicAssetsUrl: process.env["NEXT_PUBLIC_FILES_URL"] ?? "https://app.figures.hr/assets",
    port: (process.env["PORT"] ?? 3000) as number,

    domain: "figures.hr",
    password: process.env["APP_PASSWORD"] as string,

    isProduction: url === "https://app.figures.hr",
    isPreview: url === "https://preview.figures.hr",
    isStaging: url === "https://staging.figures.hr",
    isLocal: url.startsWith("http://localhost:"),
    isJest: process.env.IS_JEST === "true",
    isCli: process.env.IS_CLI === "true",
    isCliOrTest: process.env.IS_CLI === "true" || process.env.IS_JEST === "true",
    environmentName: value(() => {
      if (url === "https://app.figures.hr") return "PRODUCTION";
      if (url === "https://preview.figures.hr") return "PREVIEW";
      if (url === "https://staging.figures.hr") return "STAGING";

      return "LOCAL";
    }),

    publicApiKey: {
      salaryConverter: process.env["PUBLIC_API_KEY"] || "i-heard-it-through-the-grapevine",
      oyster: process.env["OYSTER_PUBLIC_API_KEY"] || "there-s-the-moon-asking-to-stay",
      oyster2: process.env["OYSTER_2_PUBLIC_API_KEY"] || "a-ce-quon-est-serre-au-fond-de-cette-boite",
      workmotion: process.env["WORKMOTION_PUBLIC_API_KEY"] || "i-dont-know-the-lyrics",
      hubspot: process.env["HUBSPOT_PUBLIC_API_KEY"] || "instead-of-here-with-me",
      hireSweet: process.env["HIRESWEET_PUBLIC_API_KEY"] || "who-let-the-dogs-out",
      wttj: process.env["WTTJ_PUBLIC_API_KEY"] || "its-not-so-bad-that-hardcore-music",
      levelUp: process.env["LEVELUP_PUBLIC_API_KEY"] || "space-to-bakersfield-are-you-listening",
      hibob: process.env["HIBOB_PUBLIC_API_KEY"] || "i-want-to-break-free",
    },
  },

  queue: {
    driver: process.env["QUEUE_DRIVER"],
  },

  logger: {
    silent: !!process.env["LOGGER_SILENT"],
    useConsoleLoggerForJest: !!process.env["USE_CONSOLE_LOGGER_FOR_JEST"],
  },

  emails: {
    senders: {
      noReply: {
        email: "no_reply@figures.hr",
        name: "Figures",
        locale: "EN",
      } as Sender,
      onboarding: {
        email: "onboarding@figures.hr",
        name: "Figures",
        locale: "EN",
      } as Sender,
    },
  },

  dev: {
    nameOverride: process.env["DEV_EMAIL_NAME"] || "Bastien Formery",
    emailOverride: process.env["DEV_EMAIL_ADDRESS"] || "bastien@figures.hr",
    slackChannelOverride: process.env["DEV_SLACK_CHANNEL"] || "C01PALVNSCR", // #notif-tech-dev
    ignoreIntegrationProfilePics: !!process.env["DEV_IGNORE_INTEGRATION_PICS"] || false,
    ignoreExceptionalRightsChangeRedirect:
      !!process.env["NEXT_PUBLIC_DEV_IGNORE_EXCEPTIONAL_RIGHTS_CHANGE_REDIRECT"] || false,
    dangerouslyBypassSuperAdminCliRestriction: !!process.env["DANGEROUSLY_BYPASS_SUPER_ADMIN_CLI_RESTRICTION"] || false,
  },

  database: {
    url: process.env["DATABASE_URL"] as string,
    readonlyUrl: (process.env["READONLY_DATABASE_URL"] as string) || (process.env["DATABASE_URL"] as string),
    connectionLimitForApp: parseInt(process.env["DATABASE_CONNECTION_LIMIT_APP"] as string) || null,
    connectionLimitForCli: parseInt(process.env["DATABASE_CONNECTION_LIMIT_CLI"] as string) || null,
    connectionLimitForWorker: parseInt(process.env["DATABASE_CONNECTION_LIMIT_WORKER"] as string) || null,
    statementTimeoutMs: parseInt(process.env["DATABASE_STATEMENT_TIMEOUT_MS"] as string) || 10000,
    debug: !!process.env["DATABASE_DEBUG"],
  },

  worker: {
    pgbossSchema: process.env["PGBOSS_SCHEMA"] ?? "pgboss_v10",
    usePriority: process.env.WORKER_QUEUE_PRIORITY === "true",
    batchSize: process.env.WORKER_BATCH_SIZE ? parseInt(process.env.WORKER_BATCH_SIZE) : 2,
    pollingIntervalSeconds: process.env.WORKER_POLLING_INTERVAL_SECONDS
      ? parseInt(process.env.WORKER_POLLING_INTERVAL_SECONDS)
      : 1,
  },

  googleTagManager: {
    id: "GTM-P5WQQLB",
  },

  google: {
    clientId: process.env["GOOGLE_CLIENT_ID"],
    clientSecret: process.env["GOOGLE_CLIENT_SECRET"],
  },

  googleAdmin: {
    clientId: process.env["GOOGLE_ADMIN_CLIENT_ID"],
    clientSecret: process.env["GOOGLE_ADMIN_CLIENT_SECRET"],
    accessToken: process.env["GOOGLE_ADMIN_ACCESS_TOKEN"],
    refreshToken: process.env["GOOGLE_ADMIN_REFRESH_TOKEN"],
  },

  microsoft: {
    clientId: process.env["MICROSOFT_CLIENT_ID"] as string,
    clientSecret: process.env["MICROSOFT_CLIENT_SECRET"] as string,
  },

  redis: {
    url: process.env["REDIS_URL"] as string,
    prefix: process.env["REDIS_PREFIX"],
  },

  slack: {
    accessToken: process.env["SLACK_ACCESS_TOKEN"] as string,
  },

  sendinblue: {
    apiKey: process.env["SENDINBLUE_API_KEY"] as string,
  },

  segment: {
    writeKey: process.env["NEXT_PUBLIC_SEGMENT_WRITE_KEY"] ?? "d8sJXXx5oMYy4IFOWetzahJQx4E6RBOn",
  },

  intercom: {
    apiBase: process.env["NEXT_PUBLIC_INTERCOM_API_BASE"] ?? "https://api-iam.intercom.io",
    appId: process.env["NEXT_PUBLIC_INTERCOM_APP_ID"] ?? "fzbshzfq",

    secretKey: process.env["INTERCOM_SECRET_KEY"] ?? "fake_secret_key",
    accessToken: process.env["INTERCOM_ACCESS_TOKEN"],
  },

  datadog: {
    flushTimeInMs: parseInt(process.env["DATADOG_FLUSH_TIME_IN_MS"] as string) || 5000,
    clientToken: process.env["NEXT_PUBLIC_DATADOG_CLIENT_TOKEN"] as string,
  },

  exchangeRatesApi: {
    accessKey: process.env["EXCHANGES_RATES_API_ACCESS_KEY"],
  },

  countriesInflationRates: {
    apiKey: process.env["COUNTRY_INFLATION_RATES_API_KEY"],
  },

  files: {
    endpoint: process.env["FILES_ENDPOINT"] as string,
    accessKey: process.env["FILES_ACCESS_KEY"] as string,
    secretKey: process.env["FILES_SECRET_KEY"] as string,
    region: process.env["FILES_REGION"],
    bucket: process.env["FILES_BUCKET"] ?? "figures",
    companyFilesBucket: process.env["COMPANY_FILES_BUCKET"] ?? "figures",

    prefixes: {
      publicAssets: "assets",
      companiesFiles: "companies-files",
      companiesLogos: "companies-logos",
      companiesCovers: "companies-covers",
      employeePictures: "employees-pictures",
    },
  },

  hubspot: {
    forms: {
      portalId: "8715213",
      globalSalaryConverter: "2adf76be-5d1d-4e11-9adf-6e20588de73a",
    },
  },

  fair: {
    url: process.env["FAIR_API_URL"] as string,
    token: process.env["FAIR_API_TOKEN"] as string,
  },

  openai: {
    apiKey: process.env["OPENAI_API_KEY"] as string,
  },

  /**
   * Integrations
   */

  alexishr: {
    clientSecret: process.env["NEXT_PUBLIC_ALEXISHR_TEST_API_KEY"] ?? "",
  },

  bamboo: {
    domain: process.env["NEXT_PUBLIC_BAMBOO_TEST_DOMAIN"],
    clientSecret: process.env["NEXT_PUBLIC_BAMBOO_TEST_API_KEY"] ?? "",
  },

  charliehr: {
    clientId: process.env["NEXT_PUBLIC_CHARLIEHR_TEST_CLIENT_ID"],
    clientSecret: process.env["NEXT_PUBLIC_CHARLIEHR_TEST_CLIENT_SECRET"] ?? "",
  },

  factorial: {
    clientSecret: process.env["NEXT_PUBLIC_FACTORIAL_CLIENT_SECRET"] ?? "",
  },

  hibobDeprecated: {
    clientSecret: process.env["NEXT_PUBLIC_HIBOB_TEST_API_KEY"] ?? "",
  },

  hibob: {
    clientId: process.env["NEXT_PUBLIC_HIBOB_TEST_SERVICE_USER_ID"] ?? "",
    clientSecret: process.env["NEXT_PUBLIC_HIBOB_TEST_SERVICE_USER_TOKEN"] ?? "",
    hibobPartnerToken: process.env["HIBOB_PARTNER_TOKEN"] ?? "",
  },

  humaans: {
    clientSecret: process.env["NEXT_PUBLIC_HUMAANS_TEST_API_KEY"] ?? "",
  },

  kombo: {
    apiKey: process.env["KOMBO_API_KEY"] ?? "",
    connectionGuides: {
      afas: "https://help.kombo.dev/hc/en-us/articles/18767909752337",
      breathehr: "https://help.kombo.dev/hc/en-us/articles/20857974074257",
      catalystone: null,
      cezannehr: null,
      datev: "https://help.kombo.dev/hc/en-us/articles/17624306602641-Wie-verbinde-ich-meinen-DATEV-LODAS-Account-",
      deel: "https://help.kombo.dev/hc/en-us/articles/18401663397521",
      entraid: "https://help.kombo.dev/hc/en-us/articles/20372742922897",
      eurecia: null,
      googleworkspace: "https://help.kombo.dev/hc/en-us/articles/15719650405521",
      heavenhr: "https://help.kombo.dev/hc/en-us/articles/20587609783313",
      haileyhr: "https://help.kombo.dev/hc/en-us/articles/18742611341969",
      hibob: "https://help.kombo.dev/hc/en-us/articles/15410713576337-How-do-I-link-my-HiBob-account-",
      hrworks: "https://help.kombo.dev/hc/en-us/articles/15064269328145-How-do-I-link-my-HRworks-account-",
      factorial: "https://help.kombo.dev/hc/en-us/articles/18743884123409-Factorial-HR-How-do-I-link-my-account",
      iriscascade: null,
      kenjo: null,
      mirus: null,
      nmbrs: "https://help.kombo.dev/hc/en-us/articles/16969080424081-How-do-I-link-my-Nmbrs-account-",
      officient: null,
      okta: "https://help.kombo.dev/hc/en-us/articles/17889314378897",
      oraclehcm: "https://help.kombo.dev/hc/en-us/articles/21048576776209-Oracle-HCM-How-do-I-link-my-account-",
      payfit: "https://help.kombo.dev/hc/en-us/articles/20233940964241",
      planday: null,
      remote: "https://help.kombo.dev/hc/en-us/articles/18378241739665",
      sagehr: "https://help.kombo.dev/hc/en-us/articles/18200475423761",
      sapling: "https://help.kombo.dev/hc/en-us/articles/21712526249873-Sapling-How-do-I-link-my-account",
      sesamehr: null,
      successfactors:
        "https://help.kombo.dev/hc/en-us/articles/16225034050961-How-do-I-link-my-SAP-SuccessFactors-account-",
    },
  },

  lucca: {
    domain: process.env["NEXT_PUBLIC_LUCCA_TEST_URL"],
    clientSecret: process.env["NEXT_PUBLIC_LUCCA_TEST_API_KEY"] ?? "",
  },

  personio: {
    clientId: process.env["NEXT_PUBLIC_PERSONIO_TEST_CLIENT_ID"],
    clientSecret: process.env["NEXT_PUBLIC_PERSONIO_TEST_CLIENT_SECRET"] ?? "",
  },

  workday: {
    domain: process.env["NEXT_PUBLIC_WORKDAY_TEST_URL"] ?? "",
    clientId: process.env["NEXT_PUBLIC_WORKDAY_TEST_CLIENT_ID"] ?? "",
    clientSecret: process.env["NEXT_PUBLIC_WORKDAY_TEST_CLIENT_SECRET"] ?? "",
  },

  cultureAmp: {
    clientId: process.env["NEXT_PUBLIC_CULTURE_AMP_TEST_CLIENT_ID"] ?? "",
    clientSecret: process.env["NEXT_PUBLIC_CULTURE_AMP_TEST_CLIENT_SECRET"] ?? "",
    apiScope: process.env["CULTURE_AMP_API_SCOPE"] ?? "",
    apiUrl: process.env["CULTURE_AMP_API_URL"] ?? "",
  },

  linear: {
    apiKey: process.env["LINEAR_API_TOKEN"] ?? "",
    apiUrl: process.env["LINEAR_API_URL"] ?? "",
  },

  github: {
    apiKey: process.env["GITHUB_API_TOKEN"] ?? "",
    apiUrl: process.env["GITHUB_API_URL"] ?? "",
    resetEnvironmentToken: process.env["GITHUB_RESET_ENV_TOKEN"] ?? "",
    workflowReadToken: process.env["GITHUB_WORKFLOW_READ_TOKEN"] ?? "",
  },

  auth: {
    tokenExpirationInMinutes: parseInt(process.env["AUTHENTICATION_TOKEN_EXPIRATION_IN_MINUTES"] as string) || 1440,
  },
} as const;

export const getCookieConfig = () => {
  const sessionDuration = config.app.isLocal
    ? 7 * 24 * 60 * 60 // 7 days
    : 6 * 60 * 60; // 6 hours

  return {
    password: config.app.password,
    cookieName: config.app.domain,
    // This is the expiry of the session on the server
    ttl: sessionDuration,
    cookieOptions: {
      secure: !config.app.debug,
      // This is the expiry of the cookie on the browser
      maxAge: sessionDuration,
      // Some browsers don't support maxAge
      expires: addSeconds(new Date(), sessionDuration),
    },
  } satisfies SessionOptions;
};
