import {
  type Currency,
  type EmployeeLevel,
  type SalaryBandMarketPositioning,
  type SalaryGridMeasure,
} from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { uniqBy } from "~/lib/lodash";
import { resolveWeightedJobs } from "~/services/job";
import { getEmployeeLocationsForPredictiveBenchmark } from "~/services/market-data/getMarketDataPredictiveBenchmark";
import { computeMarketCompensationData } from "~/services/marketCompensationStats";
import { DefaultTargetPercentiles } from "~/services/percentiles";
import { computeAmountPercentileRank } from "~/services/salary-bands/benchmark/computeSalaryRangePercentileRank";
import { fetchLiveEmployeesForBenchmark } from "~/services/salary-bands/benchmark/fetchLiveEmployeesForBenchmark";

export const computeMarketDataForBenchmarks = async (
  ctx: AppContext,
  params: {
    currency: Currency;
    percentileRank?: number;
    targetAmount?: number;
    benchmark: {
      jobIds: number[];
      locationIds: number[];
      levels: EmployeeLevel[];
      benchmarkLevelIds: number[];
      marketPositioning: SalaryBandMarketPositioning | null;
    };
    measure: SalaryGridMeasure;
  }
) => {
  const employees = await fetchLiveEmployeesForBenchmark(ctx, params.benchmark);

  const distinctCompaniesCount = uniqBy(employees, (employee) => employee.companyId).length;

  const locations = await getEmployeeLocationsForPredictiveBenchmark(ctx, {
    locationIds: params.benchmark.locationIds,
  });

  const weightedJobs = await resolveWeightedJobs(ctx, { jobsIds: params.benchmark.jobIds.map((jobId) => `${jobId}`) });

  const industry = await value(async () => {
    if (!params.benchmark.marketPositioning?.industryId) {
      return null;
    }

    return ctx.prisma.companyTag.findUnique({
      where: { id: params.benchmark.marketPositioning.industryId },
    });
  });

  const marketData = await computeMarketCompensationData(ctx, employees, {
    currency: params.currency,
    percentiles: DefaultTargetPercentiles,
    targetPercentile: params.percentileRank,
    measures: ["baseSalary", "onTargetEarnings"],
    weightedJobs,
    locations,
    level: params.benchmark.levels[0],
    fallbackToPredictiveData: true,
    fundingRounds: params.benchmark.marketPositioning?.fundingRounds,
    headcount: params.benchmark.marketPositioning?.headcount,
    industry,
  });

  const marketDataStats = marketData.data?.measures;

  if (!marketDataStats?.baseSalary && !marketDataStats?.onTargetEarnings) {
    return null;
  }

  const percentileRank = params.targetAmount
    ? computeAmountPercentileRank({
        amount: params.targetAmount,
        currency: params.currency,
        employees,
        measure: params.measure,
      })
    : params.percentileRank;

  return {
    baseSalary: {
      p10: marketDataStats.baseSalary?.lowest.value ?? null,
      p25: marketDataStats.baseSalary?.low.value ?? null,
      p50: marketDataStats.baseSalary?.middle.value ?? null,
      p75: marketDataStats.baseSalary?.high.value ?? null,
      p90: marketDataStats.baseSalary?.highest.value ?? null,
      target: marketDataStats.baseSalary?.target.value ?? null,
      dataQuality: marketDataStats.baseSalary?.dataQuality,
    },
    onTargetEarnings: {
      p10: marketDataStats.onTargetEarnings?.lowest.value ?? null,
      p25: marketDataStats.onTargetEarnings?.low.value ?? null,
      p50: marketDataStats.onTargetEarnings?.middle.value ?? null,
      p75: marketDataStats.onTargetEarnings?.high.value ?? null,
      p90: marketDataStats.onTargetEarnings?.highest.value ?? null,
      target: marketDataStats.onTargetEarnings?.target.value ?? null,
      dataQuality: marketDataStats.onTargetEarnings?.dataQuality,
    },
    distinctCompaniesCount,
    employeesCount: employees.length,
    percentileRank,
  };
};

export type ComputeMarketDataForBenchmarksResult = AsyncReturnType<typeof computeMarketDataForBenchmarks>;
