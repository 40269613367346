import { type NullableAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { type FeatureFlagsStatus } from "~/services/featureFlags";
import { type PermissionsSchema } from "~/services/permissions/permissionsSchema";
import { type SubscriptionStatusWithPermissions } from "~/services/subscriptions/validateSubscription";
import * as actionPermissions from "./actionPermissions";
import * as compensationReviewPermissions from "./compensationReviewPermissions";
import * as featureAccessPermissions from "./featureAccessPermissions";
import * as marketDataPermissions from "./marketDataPermissions";
import { canAccessSalaryBandsTiering, canUpdateSalaryBands } from "./salaryBandsPermissions";

export type PermissionsParams = {
  user: NullableAuthenticatedUser;
  subscriptions: SubscriptionStatusWithPermissions;
  featureFlags: FeatureFlagsStatus;
  permissionsSchema: PermissionsSchema;
};

export const globalPermissions = {
  ...marketDataPermissions,
  ...compensationReviewPermissions,
  ...featureAccessPermissions,
  ...actionPermissions,
  canUpdateSalaryBands,
  canAccessSalaryBandsTiering,
};

export type Permissions = keyof typeof globalPermissions;

export type PermissionsStatus = Record<Permissions, boolean>;

// Default permissions with every permissions being false
export const defaultPermissions: PermissionsStatus = Object.fromEntries(
  Object.keys(globalPermissions).map((permission) => [permission, false])
) as Record<Permissions, boolean>;
