import { type Prisma, type QueueJobName } from "@prisma/client";
import { map } from "bluebird";
import { type ObjectShape, type OptionalObjectSchema } from "yup/lib/object";
import { type AppContext } from "~/lib/context";
import { chain, isArray } from "~/lib/lodash";
import { logError, logInfo, logWarn } from "~/lib/logger";
import { getId } from "~/lib/utils";

type CheckoutJobCacheData<T extends ObjectShape> = {
  companyId: number;
  queueJobName: QueueJobName;
  validationSchema: OptionalObjectSchema<T>;
  chunkSize?: number;
};

export const checkoutJobCache = async <T extends ObjectShape>(ctx: AppContext, params: CheckoutJobCacheData<T>) => {
  const rowsFromQueueJobCache = await ctx.prisma.queueJobCache.findMany({
    where: {
      jobName: params.queueJobName,
      metadata: { path: ["companyId"], equals: params.companyId },
      isBeingProcessed: false,
    },
    select: { id: true, data: true },
    ...(params.chunkSize && { take: params.chunkSize }),
  });

  await map(
    rowsFromQueueJobCache.map(getId),
    async (id) => {
      return ctx.prisma.queueJobCache.update({
        where: { id },
        data: { isBeingProcessed: true },
      });
    },
    { concurrency: 5 }
  );

  const unprocessableRows: number[] = [];
  const queueJobCacheIds: number[] = [];

  const data = chain(rowsFromQueueJobCache)
    .map((rowFromQueueJobCache) => {
      const { data } = rowFromQueueJobCache;

      if (params.validationSchema.isValidSync(data)) {
        queueJobCacheIds.push(rowFromQueueJobCache.id);
        return params.validationSchema.validateSync(data);
      } else {
        logError(ctx, "[queue-job-cache] An invalid QueueJobCache row was found and will be deleted", {
          companyId: params.companyId,
        });
        unprocessableRows.push(rowFromQueueJobCache.id);
      }
    })
    .compact()
    .value();

  if (unprocessableRows.length) {
    await ctx.prisma.queueJobCache.deleteMany({
      where: { id: { in: unprocessableRows } },
    });
  }

  return { queueJobCacheIds, data };
};

type FillJobCacheData<T extends ObjectShape> = {
  companyId: number;
  queueJobName: QueueJobName;
  data: OptionalObjectSchema<T>["__outputType"] | OptionalObjectSchema<T>["__outputType"][];
  validationSchema: OptionalObjectSchema<T>;
};

export const fillJobCache = async <T extends ObjectShape>(ctx: AppContext, params: FillJobCacheData<T>) => {
  const data = isArray(params.data) ? params.data : [params.data];

  const chunks = chain(data)
    .map((row) => {
      try {
        return params.validationSchema.validateSync(row);
      } catch (error) {
        logError(ctx, "[queue-job-cache] Error while validating data for queueJobCache", {
          error,
          companyId: params.companyId,
        });
      }
    })
    .compact()
    .chunk(50)
    .value();

  await map(
    chunks,
    async (chunk) =>
      ctx.prisma.queueJobCache.createMany({
        data: chunk.map((row) => ({
          jobName: params.queueJobName,
          metadata: { companyId: params.companyId },
          data: row as Prisma.InputJsonValue,
          isBeingProcessed: false,
        })),
      }),
    { concurrency: 2 }
  );
};

export const discardJobCache = async (
  ctx: AppContext,
  params: { companyId: number; queueJobName: QueueJobName; queueJobCacheIds: number[] }
) => {
  logInfo(ctx, "[queue-job-cache] Discarding job cache", { queueJobCacheIdCount: params.queueJobCacheIds.length });

  await map(
    params.queueJobCacheIds,
    async (id) => {
      try {
        return ctx.prisma.queueJobCache.deleteMany({
          where: {
            id,
            jobName: params.queueJobName,
            metadata: { path: ["companyId"], equals: params.companyId },
          },
        });
      } catch (error) {
        logWarn(ctx, "[queue-job-cache] Error while discarding job cache", { error });
      }
    },
    { concurrency: 5 }
  );
};

export const getJobCacheSize = async (ctx: AppContext, params: { companyId: number; queueJobName: QueueJobName }) =>
  ctx.prisma.queueJobCache.count({
    where: {
      jobName: params.queueJobName,
      isBeingProcessed: false,
      metadata: { path: ["companyId"], equals: params.companyId },
    },
  });

export const jobCacheTransaction = async <T extends ObjectShape>(
  ctx: AppContext,
  params: {
    companyId: number;
    queueJobName: QueueJobName;
    chunkSize?: number;
    validationSchema: OptionalObjectSchema<T>;
  },
  callback: (ctx: AppContext, data: OptionalObjectSchema<T>["__outputType"][]) => Promise<void>
) => {
  const { queueJobCacheIds, data } = await checkoutJobCache(ctx, {
    companyId: params.companyId,
    queueJobName: params.queueJobName,
    validationSchema: params.validationSchema,
    ...(params.chunkSize && { chunkSize: params.chunkSize }),
  });

  await callback(ctx, data);

  if (queueJobCacheIds.length > 0) {
    await discardJobCache(ctx, {
      companyId: params.companyId,
      queueJobName: params.queueJobName,
      queueJobCacheIds,
    });
  }

  const remainingLinesCount = await getJobCacheSize(ctx, params);

  return { remainingLinesCount };
};

export const filterValidRowsForSchema = <T extends ObjectShape>(
  data: OptionalObjectSchema<T>["__inputType"][],
  schema: OptionalObjectSchema<T>
): OptionalObjectSchema<T>["__outputType"][] => {
  return chain(data)
    .map((row) => {
      try {
        return schema.validateSync(row);
      } catch {
        // not an error, we just want to filter out invalid rows
      }
    })
    .compact()
    .value();
};
