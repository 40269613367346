import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { object, string } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createMutation } from "~/lib/reactQuery";
import { type YupOutputType } from "~/lib/utils";
import { TableColumnVisibilitySchema } from "~/pages/api/update-user-flag";
import { updateColumnsPreferences } from "~/services/users/updateColumnsPreferences";

export const UpdateColumnsPreferencesSchema = object({
  columns: TableColumnVisibilitySchema.required(),
  columnsKey: string().required(),
}).noUnknown();

export type updateColumnsPreferencesInput = YupOutputType<typeof UpdateColumnsPreferencesSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = UpdateColumnsPreferencesSchema.validateSync(req.body, { abortEarly: false });

  await updateColumnsPreferences(req, input);

  return res.status(HttpStatus.OK).json({});
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const UPDATE_COLUMNS_PREFERENCES_QUERY_KEY: ApiRoute["pathname"] = "/api/users/update-columns-preferences";

export const useUpdateColumnsPreferencesMutation = createMutation<
  typeof handler,
  typeof UpdateColumnsPreferencesSchema
>({
  path: UPDATE_COLUMNS_PREFERENCES_QUERY_KEY,
  schema: UpdateColumnsPreferencesSchema,
});
