import chalk from "chalk";
import jsonColorizer from "json-colorizer";
import { type NextApiRequest } from "next";
import { prefixes } from "next/dist/build/output/log";
import winston, { type Logger } from "winston";
import packageJson from "~/../package.json";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { isString, omit, padEnd } from "~/lib/lodash";
import { assertNotNil, getKeys } from "~/lib/utils";

export const CORRELATION_ID_KEY = "correlation_id";

const cachePath = require.resolve("next/dist/build/output/log");
const cacheObject = assertNotNil(require.cache[cachePath]);

// This is required to forcibly redefine all properties on the logger.
// From Next 13 and onwards they're defined as non-configurable, preventing them from being patched.
cacheObject.exports = { ...cacheObject.exports };

export function overrideNextLogger(ctx: AppContext) {
  Object.keys(prefixes).forEach((prefix) => {
    switch (prefix) {
      case "error":
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        Object.defineProperty(cacheObject.exports, prefix, { value: ctx.log.error.bind(ctx.log) });
        break;
      case "warn":
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        Object.defineProperty(cacheObject.exports, prefix, { value: ctx.log.warn.bind(ctx.log) });
        break;
      default:
        Object.defineProperty(cacheObject.exports, prefix, { value: ctx.log.info.bind(ctx.log) });
    }
  });
}

export type LoggerParams = { scriptName: string; args: Record<string, string> };

const { combine, errors, timestamp, printf, json, colorize } = winston.format;

let scriptMeta: LoggerParams | undefined;

export const makeLogger = (loggerParams?: LoggerParams): Logger => {
  if (config.app.isJest && config.logger.useConsoleLoggerForJest) {
    return {
      /* eslint-disable no-console */
      debug: console.debug.bind(console),
      info: console.info.bind(console),
      warn: console.warn.bind(console),
      error: console.error.bind(console),
      /* eslint-enable no-console */
    } as unknown as Logger;
  }

  if (!config.app.debug) {
    scriptMeta = loggerParams;
  }

  const developmentFormat = combine(
    colorize(),
    printf((info) => {
      // remove useless information from payload
      const { message, level, error, usr, http, companyId, employeeId, externalEmployeeId, ...rest } = omit(info, [
        "dd",
        "version",
        "network",
        "correlation_id",
      ]);

      // Build the suffixes that are appended to the message
      const scriptSuffix = chalk.yellow(loggerParams?.scriptName) ?? null;
      const companySuffix = companyId ? `🏢 ${companyId}` : null;
      const employeeSuffix = employeeId ? `🧙 ${employeeId}` : null;
      const externalEmployeeSuffix = externalEmployeeId ? `👷 ${externalEmployeeId}` : null;
      const suffixes = [companySuffix, employeeSuffix, externalEmployeeSuffix].filter(Boolean).join(" ");

      // `colorize` above adds ANSI coloring to the level, so the `level` string is not the size you think it is
      // 15 is the magic number that aligns all levels to the longest ones (error/debug)
      let line = [padEnd(level, 15, " "), scriptSuffix, `${message} ${suffixes}`].filter(Boolean).join(" - ");

      // Build the meta line with the request information
      const urlLine = http?.url ? `[${http.method}] ${new URL(http.url).pathname}` : null;
      const authorLine = usr ? `${usr.email} (${usr.companyId})` : null;
      const defaultMetaLine = [urlLine, authorLine].filter(Boolean).join(" : ");

      if (urlLine) {
        line += `\n        ${defaultMetaLine}`;
      }

      // Add the error and the stack if they are present
      if (error) {
        line += `\n${error.message || error}`;
      }
      if (error?.stack) {
        const inlineStack = isString(error.stack) ? error.stack : error.stack.join("\n");
        line += "\n" + fixStackTrace(inlineStack);
      }

      // The rest of the JSON payload is stringified, and only prettified if it is above 50 characters
      const stringifiedRest = JSON.stringify(rest);
      if (stringifiedRest !== "{}") {
        if (stringifiedRest.length < 50) {
          line += `\n        ${jsonColorizer(stringifiedRest)}`;
        } else {
          line += `\n${jsonColorizer(JSON.stringify(rest, null, 2))}`;
        }
      }

      return line;
    })
  );

  const productionFormat = combine(
    errors({
      stack: true,
    }),
    timestamp(),
    json()
  );

  return winston.createLogger({
    level: value(() => {
      if (!config.app.debug) {
        return "info";
      }

      return "debug";
    }),
    format: winston.format.json(),
    handleExceptions: true,
    transports: [
      new winston.transports.Console({
        format: config.app.debug ? developmentFormat : productionFormat,
      }),
    ],
    silent: config.app.env === "test" || config.logger.silent,
  });
};

function formatError(error: Error) {
  return {
    kind: error.constructor.name,
    message: error.message,
    stack: error.stack,
  };
}

export function buildUser(user: NonNullable<NextApiRequest["user"]>) {
  return {
    id: user.id.toString(),
    role: user.permissions.role,
    scope: user.flags
      ? getKeys(user.flags)
          .filter((key) => !["id", "createdAt", "updatedAt"].includes(key))
          .filter((key) => user.flags[key])
          .join(", ")
      : "",
    companyId: user.companyId.toString(),
    isSuperAdmin: user.isSuperAdmin.toString(),
  };
}

/**
 * We should try to follow this convention
 * https://docs.datadoghq.com/logs/log_configuration/attributes_naming_convention/#default-standard-attribute-list
 */
const buildMeta = (ctx: NextApiRequest | AppContext, payload?: Record<string, unknown>) => {
  const req = ctx as Partial<NextApiRequest>;

  const activeFeatureFlags = getKeys(ctx.featureFlags).filter((flag) => ctx.featureFlags[flag]);

  return {
    version: packageJson.version,
    usr: !!ctx.user ? buildUser(ctx.user) : null,
    ...(activeFeatureFlags.length && !config.app.debug && { activeFeatureFlags }),
    network: req.ip
      ? {
          client: {
            ip: req.ip,
          },
        }
      : null,
    http: {
      status_code: req.statusCode,
      method: req.method,
      ...(req.headers && {
        url: req.url ? new URL(req.url, `http://${req.headers.host}`).href : null,
        referer: req.headers.referer,
        useragent: Array.isArray(req.headers["user-agent"]) ? req.headers["user-agent"][0] : req.headers["user-agent"],
        request_id: Array.isArray(req.headers["x-request-id"])
          ? req.headers["x-request-id"][0]
          : req.headers["x-request-id"],
        headers: {
          "x-forwarded-for": req.headers["x-forwarded-for"],
        },
      }),
    },
    ...payload,
    ...(payload?.error instanceof Error && { error: formatError(payload.error) }),
    ...scriptMeta,
    ...(ctx.metadata.has(CORRELATION_ID_KEY) && { correlation_id: ctx.metadata.get(CORRELATION_ID_KEY) }),
  };
};

type ScopedMessage = `[${string}] ${string}`;

export const logDebug = (
  ctx: NextApiRequest | AppContext,
  message: ScopedMessage,
  payload?: Record<string, unknown>
): void => {
  ctx.log?.debug(message, buildMeta(ctx, payload));
};

export const logInfo = (
  ctx: NextApiRequest | AppContext,
  message: ScopedMessage,
  payload?: Record<string, unknown>
): void => {
  ctx.log?.info(message, buildMeta(ctx, payload));
};

export const logWarn = (
  ctx: NextApiRequest | AppContext,
  message: ScopedMessage,
  payload?: Record<string, unknown>
): void => {
  ctx.log?.warn(message, buildMeta(ctx, payload));
};

export const logError = (
  ctx: NextApiRequest | AppContext,
  message: ScopedMessage,
  payload?: Record<string, unknown>
): void => {
  ctx.log?.error(message, buildMeta(ctx, payload));
};

// Removes the first line of the stack trace ("Error"), and filters out lines coming from next or this script
export const fixStackTrace = (stack?: string): string => {
  return (
    stack
      ?.split("\n")
      .slice(1)
      .filter((line) => {
        return !line.includes("next/server") && !line.includes("lib/logger");
      })
      .join("\n") ?? ""
  );
};
