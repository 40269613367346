import {
  EmployeeStatus,
  EmployeeUpdateStrategy,
  ExternalEmployeeSource,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  Gender,
  type Prisma,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { XlsxImportError } from "~/lib/errors/xlsxImportError";
import { normaliseExternalEmployeeColumns } from "~/lib/external/externalEmployee";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/mapCustomRemunerationItem";
import { chain, compact, isNumber, isUndefined, uniq } from "~/lib/lodash";
import { logError, logInfo } from "~/lib/logger";
import { dangerouslyIncludeHistoricalExternalRemunerationItems } from "~/lib/prisma-restrictions/schemas/generateExternalRemunerationItemHistoricalSchema";
import { assertNotNil, getId } from "~/lib/utils";
import { getNatureByValues } from "~/services/additional-field/getAdditionalFieldsNature";
import { syncAdditionalFieldNatures } from "~/services/additional-field/syncAdditionalFieldNatures";
import { updateEmployeesStats } from "~/services/employee-stats/updateEmployeesStats";
import { computeDriftFields, getNewCompensationFromDifferences } from "~/services/employee/employeeDrift";
import { updateEmployee, type UpdateInput } from "~/services/employee/employeeUpdate";
import { externalEmployeeSelectForRemunerationComputation } from "~/services/external-employee";
import { type ParsePartialExternalEmployeesResult } from "~/services/external-employee/import/process/partial/parsePartialExternalEmployees";
import { transitionExternalEmployeeStatus } from "~/services/external-employee/statusHelpers";
import { findOrCreatePerformanceReviewCycle } from "~/services/performance-review/findOrCreatePerformanceReviewCycle";
import { savePerformanceReviewRatings } from "~/services/performance-review/savePerformanceReviewRatings";
import {
  externalEmployeeJobSelectForSync,
  externalEmployeeLevelSelectForSync,
  externalEmployeeLocationSelectForSync,
} from "~/services/synchronization/syncExternalEmployee";

const generateBaseEmployeeInput = (currentMappedEmployee: MappedEmployee) =>
  ({
    updateStrategy: EmployeeUpdateStrategy.NEW_VERSION,
    employeeNumber: currentMappedEmployee.employeeNumber,
    firstName: currentMappedEmployee.firstName,
    lastName: currentMappedEmployee.lastName,
    gender: currentMappedEmployee.gender ?? Gender.UNDISCLOSED,
    location: { id: currentMappedEmployee.locationId },
    job: { id: currentMappedEmployee.jobId },
    benchmarkLevel: { id: currentMappedEmployee.benchmarkLevelId },
    level: currentMappedEmployee.level,
    externalLevel: currentMappedEmployee.externalLevel,
    currency: { id: currentMappedEmployee.currencyId },
    baseSalary: currentMappedEmployee.baseSalary,
    fixedBonus: currentMappedEmployee.fixedBonus,
    onTargetBonus: currentMappedEmployee.onTargetBonus,
    isFounder: currentMappedEmployee.isFounder ?? false,
    pictureId: currentMappedEmployee.pictureId,
    hireDate: currentMappedEmployee.hireDate,
    birthDate: currentMappedEmployee.birthDate,
    externalJobTitle: currentMappedEmployee.externalJobTitle,
    onTargetBonusPercentage: currentMappedEmployee.onTargetBonusPercentage,
    fixedBonusPercentage: currentMappedEmployee.fixedBonusPercentage,
    reason: "Partial spreadsheet update",
  }) satisfies Omit<UpdateInput, "performanceReviewRatingId">;

const mappedEmployeeSelect = {
  id: true,
  companyId: true,
  employeeNumber: true,
  firstName: true,
  lastName: true,
  gender: true,
  locationId: true,
  jobId: true,
  benchmarkLevelId: true,
  level: true,
  externalLevel: true,
  currencyId: true,
  baseSalary: true,
  fixedBonus: true,
  onTargetBonus: true,
  isFounder: true,
  pictureId: true,
  hireDate: true,
  birthDate: true,
  externalJobTitle: true,
  onTargetBonusPercentage: true,
  fixedBonusPercentage: true,
  status: true,
} satisfies Prisma.EmployeeSelect;

type MappedEmployee = Prisma.EmployeeGetPayload<{
  select: typeof mappedEmployeeSelect;
}>;

const externalEmployeeForPersistenceSelect = {
  id: true,
  performanceReviewRatingId: true,
  companyId: true,
  currencyId: true,
  mappedEmployee: { select: mappedEmployeeSelect },
  job: {
    select: externalEmployeeJobSelectForSync,
  },
  level: { select: externalEmployeeLevelSelectForSync },
  location: {
    select: externalEmployeeLocationSelectForSync,
  },
  driftFields: true,
  ...externalEmployeeSelectForRemunerationComputation,
} satisfies Prisma.ExternalEmployeeSelect;

const updateExternalLevelIfNeeded = async (
  ctx: AppContext,
  input: {
    level: string;
  }
) => {
  const user = getRequiredUser(ctx);

  if (input.level === "") {
    return {
      level: {
        disconnect: true,
      },
    };
  }

  const existingExternalLevel = await ctx.prisma.externalLevel.findFirst({
    where: {
      companyId: user.companyId,
      name: input.level,
    },
  });

  if (!!existingExternalLevel) {
    return {
      level: {
        connect: { id: existingExternalLevel.id },
      },
    };
  }

  return {
    level: {
      create: {
        externalId: input.level,
        name: input.level,
        company: {
          connect: { id: user.companyId },
        },
      },
    },
  };
};

const updateExternalLocationIfNeeded = async (
  ctx: AppContext,
  input: {
    location: string;
  }
) => {
  const user = getRequiredUser(ctx);

  if (input.location === "") {
    return {
      location: {
        disconnect: true,
      },
    };
  }

  const existingExternalLocation = await ctx.prisma.externalLocation.findFirst({
    where: {
      companyId: user.companyId,
      name: input.location,
    },
  });

  if (!!existingExternalLocation) {
    return {
      location: {
        connect: { id: existingExternalLocation.id },
      },
    };
  }

  return {
    location: {
      create: {
        externalId: input.location,
        name: input.location,
        autoMappingEnabled: true,
        company: {
          connect: { id: user.companyId },
        },
      },
    },
  };
};

const updateExternalJobIfNeeded = async (
  ctx: AppContext,
  input: {
    jobTitle: string;
  }
) => {
  const user = getRequiredUser(ctx);

  if (input.jobTitle === "") {
    return {
      job: {
        disconnect: true,
      },
    };
  }

  const existingExternalJob = await ctx.prisma.externalJob.findFirst({
    where: {
      companyId: user.companyId,
      name: input.jobTitle,
    },
  });

  if (!!existingExternalJob) {
    return {
      job: {
        connect: { id: existingExternalJob.id },
      },
    };
  }

  return {
    job: {
      create: {
        externalId: input.jobTitle,
        name: input.jobTitle,
        company: {
          connect: { id: user.companyId },
        },
      },
    },
  };
};

const updateAndFetchExternalEmployee = async (
  ctx: AppContext,
  params: {
    externalEmployeeId: number;
    row: ParsePartialExternalEmployeesResult["rows"][number];
  }
) => {
  const user = getRequiredUser(ctx);

  const manager = await value(async () => {
    if (!params.row.manager) {
      return null;
    }

    const foundManager = await ctx.prisma.externalEmployee.findFirst({
      where: {
        companyId: user.companyId,
        employeeNumber: params.row.manager,
      },
      select: { id: true },
    });

    if (!foundManager) {
      throw new XlsxImportError(`Manager with employee number ${params.row.manager} not found`);
    }

    return foundManager;
  });

  if (params.row.additionalFields && Object.keys(params.row.additionalFields).length > 0) {
    const additionalFieldNatures = getNatureByValues([params.row.additionalFields]);

    await Promise.all(
      Object.keys(params.row.additionalFields).map(async (name) => {
        if (additionalFieldNatures[name]) {
          const additionalField = await ctx.prisma.additionalField.findUnique({
            where: {
              companyId_name: {
                companyId: user.companyId,
                name,
              },
            },
          });

          if (additionalField && params.row.additionalFields[name]) {
            await ctx.prisma.additionalField.update({
              where: {
                companyId_name: {
                  companyId: user.companyId,
                  name,
                },
              },
              data: {
                nature: additionalFieldNatures[name],
              },
            });

            const existingAdditionalFieldValue = await ctx.prisma.additionalFieldValue.findUnique({
              where: {
                additionalFieldId_externalEmployeeId: {
                  additionalFieldId: additionalField.id,
                  externalEmployeeId: params.externalEmployeeId,
                },
              },
            });

            if (existingAdditionalFieldValue) {
              if (
                !params.row.additionalFields[name].stringValue ||
                params.row.additionalFields[name].stringValue === ""
              ) {
                await ctx.prisma.additionalFieldValue.delete({
                  where: { id: existingAdditionalFieldValue.id },
                });

                return;
              }

              await ctx.prisma.additionalFieldValue.update({
                where: { id: existingAdditionalFieldValue.id },
                data: {
                  company: { connect: { id: user.companyId } },
                  externalEmployee: { connect: { id: params.externalEmployeeId } },
                  stringValue: params.row.additionalFields[name].stringValue,
                  numberValue: params.row.additionalFields[name].numberValue,
                  dateValue: params.row.additionalFields[name].dateValue,
                  percentageValue: params.row.additionalFields[name].percentageValue,
                  additionalField: { connect: { id: additionalField.id } },
                },
              });
              return;
            }

            if (
              !!params.row.additionalFields[name].stringValue &&
              params.row.additionalFields[name].stringValue !== ""
            ) {
              await ctx.prisma.additionalFieldValue.create({
                data: {
                  company: { connect: { id: user.companyId } },
                  externalEmployee: { connect: { id: params.externalEmployeeId } },
                  stringValue: params.row.additionalFields[name].stringValue,
                  numberValue: params.row.additionalFields[name].numberValue,
                  dateValue: params.row.additionalFields[name].dateValue,
                  percentageValue: params.row.additionalFields[name].percentageValue,
                  additionalField: { connect: { id: additionalField.id } },
                },
              });
            }
          }
        }
      })
    );
  }

  const externalEmployee = await ctx.prisma.externalEmployee.update({
    where: { id: params.externalEmployeeId, companyId: user.companyId },
    data: {
      employeeNumber: params.row.employeeNumber,
      firstName: params.row.firstName,
      lastName: params.row.lastName,
      email: params.row.email,
      gender: params.row.gender,
      isFounder: params.row.isFounder || false,
      hireDate: params.row.hireDate,
      birthDate: params.row.birthDate,
      fteDivider: params.row.fteDivider,
      businessUnit: params.row.businessUnit,
      ...((!params.row.performanceRating || params.row.performanceRating === "") && {
        performanceReviewRating: {
          disconnect: true,
        },
      }),
      ...(params.row.currency && {
        currency: {
          connect: { code: params.row.currency },
        },
      }),
      ...(manager && { manager: { connect: { id: manager.id } } }),
      ...(params.row.level && (await updateExternalLevelIfNeeded(ctx, { level: params.row.level }))),
      ...(params.row.location && (await updateExternalLocationIfNeeded(ctx, { location: params.row.location }))),
      ...(params.row.jobTitle && (await updateExternalJobIfNeeded(ctx, { jobTitle: params.row.jobTitle }))),
    },
    select: externalEmployeeForPersistenceSelect,
  });

  await normaliseExternalEmployeeColumns(ctx, {
    externalEmployee: { id: params.externalEmployeeId },
  });

  return externalEmployee;
};

const updateRemunerationItemsIfNeeded = async (
  ctx: AppContext,
  input: {
    baseSalary: number | undefined;
    fixedBonus: number | undefined;
    onTargetBonus: number | undefined;
    customRemunerationItems: Record<string, string>;
    employeeId: number;
  }
) => {
  if (!input.baseSalary && !input.fixedBonus && !input.onTargetBonus && !input.customRemunerationItems) {
    return;
  }

  const user = getRequiredUser(ctx);

  if (isNumber(input.baseSalary)) {
    await ctx.prisma.externalRemunerationItem.deleteMany({
      ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
      where: {
        employeeId: input.employeeId,
        nature: {
          mappedType: ExternalRemunerationType.FIXED_SALARY,
        },
      },
    });

    await ctx.prisma.externalRemunerationItem.create({
      data: {
        source: ExternalEmployeeSource.SPREADSHEET,
        employee: { connect: { id: input.employeeId } },
        externalId: "fixed-salary",
        amount: Math.round(input.baseSalary),
        status: ExternalRemunerationStatus.LIVE,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: user.companyId,
                source: ExternalEmployeeSource.SPREADSHEET,
                externalId: "fixed-salary",
              },
            },
            create: {
              source: ExternalEmployeeSource.SPREADSHEET,
              externalId: "fixed-salary",
              name: "Basic salary",
              mappedType: ExternalRemunerationType.FIXED_SALARY,
              company: { connect: { id: user.companyId } },
            },
          },
        },
      },
    });
  }

  if (isNumber(input.fixedBonus)) {
    await ctx.prisma.externalRemunerationItem.deleteMany({
      ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
      where: {
        employeeId: input.employeeId,
        nature: {
          mappedType: ExternalRemunerationType.FIXED_BONUS,
        },
      },
    });

    await ctx.prisma.externalRemunerationItem.create({
      data: {
        source: ExternalEmployeeSource.SPREADSHEET,
        employee: { connect: { id: input.employeeId } },
        externalId: "fixed-bonus",
        amount: Math.round(input.fixedBonus),
        status: ExternalRemunerationStatus.LIVE,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: user.companyId,
                source: ExternalEmployeeSource.SPREADSHEET,
                externalId: "fixed-bonus",
              },
            },
            create: {
              source: ExternalEmployeeSource.SPREADSHEET,
              externalId: "fixed-bonus",
              name: "Fixed bonus",
              mappedType: ExternalRemunerationType.FIXED_BONUS,
              company: { connect: { id: user.companyId } },
            },
          },
        },
      },
    });
  }

  if (isNumber(input.onTargetBonus)) {
    await ctx.prisma.externalRemunerationItem.deleteMany({
      ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
      where: {
        employeeId: input.employeeId,
        nature: {
          mappedType: ExternalRemunerationType.VARIABLE_BONUS,
        },
      },
    });

    await ctx.prisma.externalRemunerationItem.create({
      data: {
        source: ExternalEmployeeSource.SPREADSHEET,
        employee: { connect: { id: input.employeeId } },
        externalId: "variable-bonus",
        amount: Math.round(input.onTargetBonus),
        status: ExternalRemunerationStatus.LIVE,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: user.companyId,
                source: ExternalEmployeeSource.SPREADSHEET,
                externalId: "variable-bonus",
              },
            },
            create: {
              source: ExternalEmployeeSource.SPREADSHEET,
              externalId: "variable-bonus",
              name: "Variable bonus",
              mappedType: ExternalRemunerationType.VARIABLE_BONUS,
              company: { connect: { id: user.companyId } },
            },
          },
        },
      },
    });
  }

  if (!!input.customRemunerationItems && Object.keys(input.customRemunerationItems).length > 0) {
    const customRemunerationItemNames = Object.keys(input.customRemunerationItems);
    const customRemunerationItems = await ctx.prisma.customRemunerationItem.findMany({
      where: { name: { in: customRemunerationItemNames }, companyId: user.companyId },
    });

    await ctx.prisma.externalRemunerationItem.deleteMany({
      ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
      where: {
        customRemunerationItemId: { in: customRemunerationItems.map(getId) },
        employeeId: input.employeeId,
      },
    });

    const formattedCustomRemunerationItems = compact(
      customRemunerationItems.map((item) => {
        const formattedItem = {
          customRemunerationItemId: item.id,
          customRemunerationItem: {
            name: item.name,
            frequency: item.frequency,
            type: item.type,
            subtype: item.subtype,
          },
          value: input.customRemunerationItems[item.name] ?? null,
        };

        return mapCustomRemunerationItem(
          {
            source: ExternalEmployeeSource.SPREADSHEET,
            companyId: user.companyId,
          },
          formattedItem
        );
      })
    );

    await mapSeries(formattedCustomRemunerationItems, async (item) => {
      logInfo(ctx, "[sync] Creating remuneration item", {
        companyId: user.companyId,
        externalEmployeeId: input.employeeId,
        itemId: item.externalId,
      });

      try {
        await ctx.prisma.externalRemunerationItem.create({
          data: {
            ...item,
            employee: { connect: { id: input.employeeId } },
          },
        });
      } catch (error) {
        logError(ctx, "[sync] Error while creating remuneration item", {
          error,
          item,
          externalEmployee: input.employeeId,
        });

        throw error;
      }
    });
  }
};

export const persistPartialExternalEmployees = async (
  ctx: AppContext,
  params: {
    spreadsheetRows: ParsePartialExternalEmployeesResult["rows"];
  }
) => {
  const user = getRequiredUser(ctx);
  let nbAffectedEmployees = 0;

  const externalEmployees = await mapSeries(params.spreadsheetRows, async (row) => {
    const currentExternalEmployee = await ctx.prisma.externalEmployee.findFirst({
      where: { employeeNumber: row.employeeNumber, companyId: user.companyId },
      select: externalEmployeeForPersistenceSelect,
    });

    if (!currentExternalEmployee) {
      return;
    }

    if (
      row.baseSalary ||
      row.fixedBonus ||
      row.onTargetBonus ||
      (!!row.customRemunerationItems && Object.keys(row.customRemunerationItems).length > 0)
    ) {
      await updateRemunerationItemsIfNeeded(ctx, {
        employeeId: currentExternalEmployee.id,
        baseSalary: row.baseSalary,
        fixedBonus: row.fixedBonus,
        onTargetBonus: row.onTargetBonus,
        customRemunerationItems: row.customRemunerationItems,
      });
    }

    const externalEmployee = await updateAndFetchExternalEmployee(ctx, {
      externalEmployeeId: currentExternalEmployee.id,
      row,
    });

    if (!!externalEmployee.mappedEmployee && externalEmployee.mappedEmployee.status === EmployeeStatus.LIVE) {
      const getNewCompensation = getNewCompensationFromDifferences(
        ctx,
        currentExternalEmployee,
        externalEmployee,
        currentExternalEmployee.mappedEmployee
      );

      const baseSalary = getNewCompensation(ExternalRemunerationType.FIXED_SALARY);
      const fixedBonus = getNewCompensation(ExternalRemunerationType.FIXED_BONUS);
      const onTargetBonus = getNewCompensation(ExternalRemunerationType.VARIABLE_BONUS);

      const remunerationsPayload = {
        ...(!isUndefined(baseSalary) && { baseSalary }),
        ...(!isUndefined(fixedBonus) && { fixedBonus, fixedBonusPercentage: null }),
        ...(!isUndefined(onTargetBonus) && { onTargetBonus, onTargetBonusPercentage: null }),
      };

      await updateEmployee(ctx, externalEmployee.mappedEmployee.id, {
        ...generateBaseEmployeeInput(externalEmployee.mappedEmployee),
        performanceReviewRatingId: externalEmployee.performanceReviewRatingId,
        ...(row.gender && { gender: row.gender }),
        ...(row.isFounder && { isFounder: row.isFounder }),
        employeeNumber: row.employeeNumber,
        birthDate: row.birthDate,
        firstName: row.firstName,
        lastName: row.lastName,
        hireDate: row.hireDate,
        externalJobTitle: row.jobTitle,
        externalLevel: row.level,
        currency: { id: externalEmployee.currencyId },
        ...remunerationsPayload,
      });

      await updateEmployeesStats(ctx, {
        companyId: externalEmployee.mappedEmployee.companyId,
        employeesIds: [externalEmployee.mappedEmployee.id],
      });
    }

    const newDrifts = computeDriftFields(currentExternalEmployee, externalEmployee);
    const driftFields = uniq([...currentExternalEmployee.driftFields, ...newDrifts]);

    await ctx.prisma.externalEmployee.update({
      where: { id: externalEmployee.id },
      data: { driftFields },
    });

    await transitionExternalEmployeeStatus(ctx, externalEmployee.id, {
      jobId:
        row.jobTitle && row.jobTitle !== currentExternalEmployee?.job?.name
          ? externalEmployee?.job?.mappedJobId
          : externalEmployee.mappedEmployee?.jobId,
      locationId:
        row.location && row.location !== currentExternalEmployee?.location?.name
          ? externalEmployee?.location?.mappedLocationId
          : externalEmployee.mappedEmployee?.locationId,
      level:
        row.level && row.level !== currentExternalEmployee?.level?.name
          ? externalEmployee?.level?.mappedLevel
          : externalEmployee.mappedEmployee?.level,
    });

    nbAffectedEmployees = nbAffectedEmployees + 1;

    return { id: externalEmployee.id, performanceRating: row.performanceRating };
  });

  const performanceReviewCycle = await findOrCreatePerformanceReviewCycle(ctx, {
    companyId: getRequiredUser(ctx).companyId,
    performanceReviewScope: null,
  });

  const externalEmployeesWithRatings = chain(externalEmployees)
    .compact()
    .filter(({ performanceRating }) => !!performanceRating)
    .map(({ id, performanceRating }) => ({ id, performanceRating: assertNotNil(performanceRating) }))
    .value();

  await savePerformanceReviewRatings(ctx, {
    performanceReviewCycleId: performanceReviewCycle.id,
    externalEmployees: externalEmployeesWithRatings,
  });

  await syncAdditionalFieldNatures(ctx, user.companyId);

  const nbNewNeedsRemapping = await ctx.prisma.externalEmployee.count({
    where: {
      employeeNumber: { in: compact(params.spreadsheetRows.map((row) => row.employeeNumber)) },
      status: ExternalEmployeeStatus.NEEDS_REMAPPING,
    },
  });

  return {
    nbNewNeedsRemapping,
    nbAffectedEmployees,
  };
};
