import { SalaryGridStatus } from "@prisma/client";
import { type PermissionsParams } from "~/services/user/permissions/authenticationOptions";
import { canAccessSalaryBands, canAccessSettings } from "~/services/user/permissions/featureAccessPermissions";
import { type PermissionFunction } from "~/services/user/permissions/functions";

export const canUpdateSalaryBands = (params: PermissionsParams) => canAccessSettings(params);

export const canUpdateSalaryGrid: PermissionFunction<SalaryGridStatus> = (params, salaryGridStatus) =>
  canUpdateSalaryBands(params) && salaryGridStatus !== SalaryGridStatus.ARCHIVED;

export const canAccessSalaryBandsTiering = (params: PermissionsParams) => {
  if (!canAccessSalaryBands(params)) return false;

  return true;
};

export const canDeleteSalaryGrid = (params: PermissionsParams, salaryGridStatus: SalaryGridStatus) =>
  canUpdateSalaryBands(params) && salaryGridStatus === SalaryGridStatus.DRAFT;
