import { DataQuality, EmployeeStatsComparisonScope } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { type ParsedMarketPositioning } from "~/services/compensation-policy/parseMarketPositioning";
import { computeEmployeeCompensation } from "~/services/employee";
import { type DatasetEmployeeForStats } from "~/services/employee-stats/dataset-employees-stats/fetchComparableDatasetEmployeesForStats";
import { findComparableDatasetEmployees } from "~/services/employee-stats/dataset-employees-stats/findComparableDatasetEmployees";
import { getPercentileAmountForMeasureWithDatasetEmployeesComparison } from "~/services/employee-stats/dataset-employees-stats/utils";
import { type CompanyWithCompensationSettings } from "~/services/employee-stats/fetchCompanyWithCompensationSettings";
import { type EmployeeForStats } from "~/services/employee-stats/fetchComparableEmployeesForStats";
import { getAmountForPercentile, getTargetPercentileForEmployee } from "~/services/employee-stats/utils";
import { getPredictivePercentileAmountForMeasure } from "~/services/market-data/getMarketDataPredictiveBenchmark";

export const computeDatasetEmployeeStats = async (
  ctx: AppContext,
  params: {
    company: CompanyWithCompensationSettings;
    companyMarketPositioning: ParsedMarketPositioning | null;
    employee: EmployeeForStats;
    marketDatasetEmployees: DatasetEmployeeForStats[];
  }
) => {
  const comparison = findComparableDatasetEmployees(params, {
    useBenchmarkRange: ctx.featureFlags.CAN_ACCESS_LEVEL_FRAMEWORKS,
  });

  const targetPercentile = getTargetPercentileForEmployee(params);

  let baseSalaryMeasure = getPercentileAmountForMeasureWithDatasetEmployeesComparison({
    employee: params.employee,
    measure: "baseSalary",
    comparison,
    targetPercentile,
  });

  let totalCashMeasure = getPercentileAmountForMeasureWithDatasetEmployeesComparison({
    employee: params.employee,
    measure: "totalCash",
    comparison,
    targetPercentile,
  });

  let onTargetEarningsMeasure = getPercentileAmountForMeasureWithDatasetEmployeesComparison({
    employee: params.employee,
    measure: "onTargetEarnings",
    comparison,
    targetPercentile,
  });
  if (
    totalCashMeasure.dataQuality === DataQuality.NONE ||
    baseSalaryMeasure.dataQuality === DataQuality.NONE ||
    onTargetEarningsMeasure.dataQuality === DataQuality.NONE
  ) {
    const predictiveData = await getPredictivePercentileAmountForMeasure(ctx, {
      currency: params.employee.currency,
      percentile: targetPercentile,
      level: params.employee.level,
      jobName: params.employee.job.name,
      countryName: params.employee.location.country.name,
      locationName: params.employee.location.name,
      ...(params.companyMarketPositioning?.industry && { industry: params.companyMarketPositioning.industry }),
      ...(params.companyMarketPositioning?.headcount && {
        headcount: params.companyMarketPositioning.headcount,
      }),
      ...(params.companyMarketPositioning?.fundingRounds && {
        fundingRounds: params.companyMarketPositioning.fundingRounds,
      }),
    });

    if (predictiveData) {
      comparison.comparisonScope = EmployeeStatsComparisonScope.LOCATION;
      comparison.comparedLevels = [];

      if (totalCashMeasure.dataQuality === DataQuality.NONE) {
        const totalCash = computeEmployeeCompensation(params.employee, {
          measure: "totalCash",
          targetCurrency: params.employee.currency,
        });

        const { difference, percentageDifference } = getAmountForPercentile({
          amounts: [predictiveData["totalCash"].amountForPercentile],
          amount: totalCash,
          percentile: targetPercentile,
        });
        totalCashMeasure = {
          amountForPercentile: Math.round(predictiveData["totalCash"].amountForPercentile),
          difference,
          percentageDifference,
          dataQuality: predictiveData["totalCash"].dataQuality,
        };
      }

      if (baseSalaryMeasure.dataQuality === DataQuality.NONE) {
        const baseSalary = computeEmployeeCompensation(params.employee, {
          measure: "baseSalary",
          targetCurrency: params.employee.currency,
        });
        const { difference, percentageDifference } = getAmountForPercentile({
          amounts: [predictiveData["baseSalary"].amountForPercentile],
          amount: baseSalary,
          percentile: targetPercentile,
        });

        baseSalaryMeasure = {
          amountForPercentile: Math.round(predictiveData["baseSalary"].amountForPercentile),
          difference,
          percentageDifference,
          dataQuality: predictiveData["baseSalary"].dataQuality,
        };
      }

      if (onTargetEarningsMeasure.dataQuality === DataQuality.NONE) {
        const onTargetEarnings = computeEmployeeCompensation(params.employee, {
          measure: "onTargetEarnings",
          targetCurrency: params.employee.currency,
        });
        const { difference, percentageDifference } = getAmountForPercentile({
          amounts: [predictiveData["onTargetEarnings"].amountForPercentile],
          amount: onTargetEarnings,
          percentile: targetPercentile,
        });

        onTargetEarningsMeasure = {
          amountForPercentile: Math.round(predictiveData["onTargetEarnings"].amountForPercentile),
          difference,
          percentageDifference,
          dataQuality: predictiveData["onTargetEarnings"].dataQuality,
        };
      }
    }
  }

  return {
    comparison,
    targetPercentile,
    totalCashMeasure,
    baseSalaryMeasure,
    onTargetEarningsMeasure,
  };
};
