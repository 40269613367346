import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { maxBy, minBy } from "~/lib/lodash";
import { arrayHasValues } from "~/lib/utils";

export type FetchOverlappingBenchmarkLevelsOptions = {
  useBenchmarkLevelsInShare: boolean;
  benchmarkLevelIds: number[];
  min?: number;
  max?: number;
};

export type FetchBenchmarkLevelsRange = AsyncReturnType<typeof fetchBenchmarkLevelsRange>;

export const fetchBenchmarkLevelsRange = async (ctx: AppContext, options: FetchOverlappingBenchmarkLevelsOptions) => {
  const { benchmarkLevelIds, useBenchmarkLevelsInShare } = options;

  const benchmarkLevels = await ctx.prisma.benchmarkLevel.findMany({
    where: {
      id: {
        in: benchmarkLevelIds,
      },
    },
    select: {
      id: true,
      name: true,
      min: true,
      max: true,
    },
  });

  if (ctx.featureFlags.CAN_ACCESS_LEVEL_FRAMEWORKS && useBenchmarkLevelsInShare && !arrayHasValues(benchmarkLevels)) {
    throw new BusinessLogicError("No benchmark levels found");
  }

  const min = minBy(benchmarkLevels, "min")?.min ?? -1;
  const max = maxBy(benchmarkLevels, "max")?.max ?? -1;

  if (min === -1 || max === -1) {
    return {
      benchmarkLevels,
      min: 0,
      max: 0,
    };
  }

  return {
    benchmarkLevels,
    min: Math.min(...[min, options.min ?? min]),
    max: Math.max(...[max, options.max ?? max]),
  };
};
