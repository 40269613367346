import { ComparisonGeographicStrategy, type EmployeeLevel, EmployeeStatsComparisonScope } from "@prisma/client";
import { pick } from "~/lib/lodash";
import { hasCountry, hasJob, hasLevel, hasLocation } from "~/services/employee";
import { type CompanyWithCompensationSettings } from "~/services/employee-stats/fetchCompanyWithCompensationSettings";
import { type EmployeeForStats } from "~/services/employee-stats/fetchComparableEmployeesForStats";
import {
  countComparableCompanies,
  getComparedCountryIds,
  getComparedLocationIds,
} from "~/services/employee-stats/utils";
import { getComparedBenchmarkRange, getMergedLevels } from "~/services/employee/employeeLevel";
import { hasBenchmarkRange } from "~/services/employee/index";

//DOUBLE TYPED??

// This type is needed due to the recursive nature of the function,
// otherwise TS makes its return type to any
export type ComparableEmployeesWithinDataset = {
  companiesCount: number;
  comparedCountryIds: number[];
  comparedLocationIds: number[];
  comparedLevels: EmployeeLevel[];
  comparisonScope: EmployeeStatsComparisonScope;
  employees: EmployeeForStats[];
};

export const findComparableEmployees = (
  params: {
    company: CompanyWithCompensationSettings;
    employee: EmployeeForStats;
    marketEmployees: EmployeeForStats[];
  },
  options: { useBenchmarkRange: boolean }
): ComparableEmployeesWithinDataset => {
  const comparedCountryIds = getComparedCountryIds(params.company, params.employee);
  const comparedLocationIds = getComparedLocationIds(params.company, params.employee);
  const comparedLevels = getMergedLevels(params.employee.level, {
    mergeAdvancedLevels: !params.company.useAdvancedLevels,
  }) as EmployeeLevel[];

  const comparedBenchmarkRange = getComparedBenchmarkRange(params.employee);

  const comparisonParams = {
    comparedCountryIds,
    comparedLocationIds,
    comparedLevels,
    comparedBenchmarkRange,
    employeeCompanyId: params.company.id,
    jobId: params.employee.jobId,
    marketEmployees: params.marketEmployees,
  };

  return params.company.comparisonGeographicStrategy === ComparisonGeographicStrategy.ENTIRE_COUNTRY
    ? findComparableEmployeesForCountry(comparisonParams, { useBenchmarkRange: options.useBenchmarkRange })
    : findComparableEmployeesForLocation(comparisonParams, { useBenchmarkRange: options.useBenchmarkRange });
};

export type Comparison = ReturnType<typeof findComparableEmployees>;

const getLocationComparableEmployees = (
  params: {
    comparedLevels: EmployeeLevel[];
    comparedLocationIds: number[];
    comparedBenchmarkRange: { min: number; max: number };
    jobId: EmployeeForStats["jobId"];
    marketEmployees: EmployeeForStats[];
  },
  options: { useBenchmarkRange: boolean }
) => {
  return params.marketEmployees
    .filter(hasJob([params.jobId]))
    .filter((employee) => {
      if (options.useBenchmarkRange) {
        return hasBenchmarkRange(params.comparedBenchmarkRange)(employee);
      }

      return hasLevel(params.comparedLevels, [])(employee);
    })
    .filter(hasLocation(params.comparedLocationIds));
};

const findComparableEmployeesForLocation = (
  params: {
    comparedCountryIds: number[];
    comparedLocationIds: number[];
    comparedLevels: EmployeeLevel[];
    comparedBenchmarkRange: { min: number; max: number };
    employeeCompanyId: number;
    jobId: number;
    marketEmployees: EmployeeForStats[];
  },
  options: { useBenchmarkRange: boolean }
) => {
  const employees = getLocationComparableEmployees(
    pick(params, ["comparedLevels", "comparedLocationIds", "comparedBenchmarkRange", "jobId", "marketEmployees"]),
    options
  );

  const companiesCount = countComparableCompanies({ employees: employees });

  return {
    comparisonScope: EmployeeStatsComparisonScope.LOCATION,
    comparedCountryIds: params.comparedCountryIds,
    comparedLocationIds: params.comparedLocationIds,
    comparedLevels: params.comparedLevels,
    employees,
    companiesCount,
  };
};

const getCountryComparableEmployees = (
  params: {
    comparedCountryIds: number[];
    comparedLevels: EmployeeLevel[];
    comparedBenchmarkRange: { min: number; max: number };
    jobId: EmployeeForStats["jobId"];
    marketEmployees: EmployeeForStats[];
  },
  options: { useBenchmarkRange: boolean }
) => {
  return params.marketEmployees
    .filter(hasJob([params.jobId]))
    .filter((employee) => {
      if (options.useBenchmarkRange) {
        return hasBenchmarkRange(params.comparedBenchmarkRange)(employee);
      }

      return hasLevel(params.comparedLevels, [])(employee);
    })
    .filter(hasCountry(params.comparedCountryIds));
};

const findComparableEmployeesForCountry = (
  params: {
    comparedCountryIds: number[];
    comparedLocationIds: number[];
    comparedLevels: EmployeeLevel[];
    comparedBenchmarkRange: { min: number; max: number };
    employeeCompanyId: number;
    jobId: number;
    marketEmployees: EmployeeForStats[];
  },
  options: { useBenchmarkRange: boolean }
) => {
  const employees = getCountryComparableEmployees(
    pick(params, ["comparedCountryIds", "comparedLevels", "comparedBenchmarkRange", "jobId", "marketEmployees"]),
    options
  );
  const companiesCount = countComparableCompanies({ employees });

  return {
    comparisonScope: EmployeeStatsComparisonScope.COUNTRY,
    comparedLocationIds: params.comparedLocationIds,
    comparedCountryIds: params.comparedCountryIds,
    comparedLevels: params.comparedLevels,
    employees: employees,
    companiesCount: companiesCount,
  };
};
