import { DataQuality } from "@prisma/client";
import { computeWeightedPercentiles, percentageDifferenceToTarget } from "~/lib/math";
import { type GetMarketDataStatsMeasure } from "~/pages/api/get-market-data-stats";
import { computeDataQuality } from "~/services/dataQuality";
import { computeEmployeeCompensation } from "~/services/employee";
import { type ComparableEmployeesWithinDataset } from "~/services/employee-stats/dataset-employees-stats/findComparableDatasetEmployees";
import { type EmployeeForStats } from "~/services/employee-stats/fetchComparableEmployeesForStats";
import { computeDatasetEmployeeCompensation } from "~/services/market-data/dataset-employee/compute-compensations/computeDatasetEmployeeCompensation";
import { getMarketPositioningType } from "~/services/marketPositioning";

export type DatasetEmployeeStatsMeasure = Extract<
  GetMarketDataStatsMeasure,
  "baseSalary" | "onTargetEarnings" | "totalCash"
>;

export const getAmountForWeightedPercentile = (params: {
  amounts: { value: number; weight: number }[];
  amount: number;
  percentile: number;
}) => {
  const amountForPercentile = Math.round(computeWeightedPercentiles([params.percentile * 100], params.amounts)[0]!);

  const difference = amountForPercentile ? Math.round(params.amount - amountForPercentile) : null;
  const percentageDifference = amountForPercentile
    ? percentageDifferenceToTarget(params.amount, amountForPercentile)
    : null;

  return {
    amountForPercentile,
    difference,
    percentageDifference,
  };
};

export const getPercentileAmountForMeasureWithDatasetEmployeesComparison = (params: {
  employee: EmployeeForStats;
  measure: DatasetEmployeeStatsMeasure;
  comparison: ComparableEmployeesWithinDataset;
  targetPercentile: number;
}) => {
  const amounts = params.comparison.employees.map((employee) =>
    computeDatasetEmployeeCompensation(employee, {
      measure: params.measure,
      targetCurrency: params.employee.currency,
    })
  );

  const amount = computeEmployeeCompensation(params.employee, {
    measure: params.measure,
    targetCurrency: params.employee.currency,
  });

  const dataQuality = computeDataQuality(
    amounts.map(({ value }) => value),
    params.comparison.companiesCount
  );

  if (dataQuality === DataQuality.NONE) {
    return {
      amountForPercentile: null,
      difference: null,
      percentageDifference: null,
      marketPositioning: null,
      dataQuality: DataQuality.NONE,
    };
  }

  const { amountForPercentile, difference, percentageDifference } = getAmountForWeightedPercentile({
    amounts,
    amount,
    percentile: params.targetPercentile,
  });

  return {
    amountForPercentile,
    difference,
    percentageDifference,
    ...(percentageDifference && {
      marketPositioning: getMarketPositioningType(percentageDifference),
    }),
    dataQuality,
  };
};
