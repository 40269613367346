import { type UserFlags } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { array, boolean, object, string } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { createMutation } from "~/lib/reactQuery";
import { type YupOutputType } from "~/lib/utils";

export type TableColumnVisibilityKeys = keyof Pick<
  UserFlags,
  | "marketDataPeopleDashboardColumns"
  | "salaryBandsPeopleDashboardColumns"
  | "salaryRangeEmployeesColumns"
  | "externalEmployeesColumns"
  | "overviewCompensationReviewColumns"
  | "reviewsCompensationReviewColumns"
>;

export const TableColumnVisibilitySchema = array(
  object({
    id: string().required(),
    visible: boolean().required(),
  })
);

const UpdateUserFlagSchema = object({
  hasSeenCompaniesFilter: boolean().isTrue(),
  hasSeenDashboardCompanySwitch: boolean().isTrue(),
  hasSeenOfferDragHandle: boolean().isTrue(),
  collapseMarketDataFilters: boolean(),
  showDashboardCompensationIntro: boolean().isFalse(),
  showDashboardGenderEqualityIntro: boolean().isFalse(),
  showSalaryBandsMarket: boolean(),
  showSalaryBandsEmptyLevels: boolean(),
  compensationPlanningEligibleEmployeeSort: string(),
  marketDataPeopleDashboardColumns: TableColumnVisibilitySchema,
  salaryBandsPeopleDashboardColumns: TableColumnVisibilitySchema,
  salaryRangeEmployeesColumns: TableColumnVisibilitySchema,
  externalEmployeesColumns: TableColumnVisibilitySchema,
  overviewCompensationReviewColumns: TableColumnVisibilitySchema,
  reviewsCompensationReviewColumns: TableColumnVisibilitySchema,
});

export type UpdateUserFlagInput = YupOutputType<typeof UpdateUserFlagSchema>;

export type UpdateUserFlagResponse = {
  flags: UserFlags;
};

const handler: NextApiHandler<UpdateUserFlagResponse> = async (req, res) => {
  const user = getRequiredUser(req);

  const input = UpdateUserFlagSchema.validateSync(req.body, {
    abortEarly: false,
  });

  const flags = await req.prisma.userFlags.update({
    data: {
      hasSeenCompaniesFilter: input.hasSeenCompaniesFilter,
      hasSeenDashboardCompanySwitch: input.hasSeenDashboardCompanySwitch,
      hasSeenOfferDragHandle: input.hasSeenOfferDragHandle,
      collapseMarketDataFilters: input.collapseMarketDataFilters,
      showDashboardCompensationIntro: input.showDashboardCompensationIntro,
      showDashboardGenderEqualityIntro: input.showDashboardGenderEqualityIntro,
      showSalaryBandsMarket: input.showSalaryBandsMarket,
      showSalaryBandsEmptyLevels: input.showSalaryBandsEmptyLevels,
      compensationPlanningEligibleEmployeeSort: input.compensationPlanningEligibleEmployeeSort,
      marketDataPeopleDashboardColumns: input.marketDataPeopleDashboardColumns,
      salaryBandsPeopleDashboardColumns: input.salaryBandsPeopleDashboardColumns,
      salaryRangeEmployeesColumns: input.salaryRangeEmployeesColumns,
      externalEmployeesColumns: input.externalEmployeesColumns,
      overviewCompensationReviewColumns: input.overviewCompensationReviewColumns,
      reviewsCompensationReviewColumns: input.reviewsCompensationReviewColumns,
    },
    where: { id: user.flagsId },
  });

  res.status(HttpStatus.OK).json({
    flags,
  });
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const UPDATE_USER_FLAG_QUERY_KEY: ApiRoute["pathname"] = "/api/update-user-flag";

export const updateUserFlagMutation = createMutation<typeof handler, typeof UpdateUserFlagSchema>({
  path: UPDATE_USER_FLAG_QUERY_KEY,
  schema: UpdateUserFlagSchema,
});
