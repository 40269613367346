import { type CompanyTag, type CompanyType, type DatasetEmployee, OnboardingStep, type Prisma } from "@prisma/client";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { CacheKeys } from "~/lib/external/redis/cacheKeys";
import { compact } from "~/lib/lodash";
import { type Predicate } from "~/lib/utils";
import { type DisableCompanyInput } from "~/pages/api/admin/disable-company";
import { type ReEnableCompanyInput } from "~/pages/api/admin/re-enable-company";
import { type NullableAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { syncCompanyLogo } from "~/services/companyLogo";

export const hasTags = (tagsIds: number[]): Predicate<Record<"tags", Pick<CompanyTag, "id">[]>> => {
  return (company): boolean => {
    if (!tagsIds.length) {
      return true;
    }
    const companyTagsIds = company.tags.map((tag) => {
      return tag.id;
    });

    return tagsIds.every((tagId) => {
      return companyTagsIds.includes(tagId);
    });
  };
};

export const datasetEmployeeHasTags = (tagsIds: number[]) => {
  return (datasetEmployee: Pick<DatasetEmployee, "companyTagsIds">): boolean => {
    if (!tagsIds.length) {
      return true;
    }

    return datasetEmployee.companyTagsIds.some((tagId) => tagsIds.includes(tagId));
  };
};

export const formatCompanyType = (companyType: CompanyType): string => {
  return match(companyType)
    .with("PARTICIPANT", () => {
      return "Participant";
    })
    .with("VENTURE_CAPITAL", () => {
      return "Venture Capital";
    })
    .exhaustive();
};

export const formatOnboardingStep = (onboardingStep: OnboardingStep) =>
  match(onboardingStep)
    .with(OnboardingStep.LETS_GET_TO_KNOW_EACH_OTHER, () => "Let's get to know each other")
    .with(OnboardingStep.CONNECT_YOUR_HRIS, () => "Connect your HRIS")
    .with(OnboardingStep.DATA_REVIEW, () => "Review your data")
    .with(OnboardingStep.MAP_YOUR_LEVELS, () => "Map your levels")
    .with(OnboardingStep.MAP_YOUR_LOCATIONS, () => "Map your locations")
    .with(OnboardingStep.MAP_YOUR_JOBS, () => "Map your jobs")
    .with(OnboardingStep.MAP_YOUR_EMPLOYEES, () => "Map your employees")
    .with(OnboardingStep.CHECK_YOUR_DATA, () => "Check your data")
    .with(OnboardingStep.ENJOY_FIGURES, () => "Enjoy figures")
    .with(OnboardingStep.ONBOARDING_FINISHED, () => "Onboarding finished")
    .exhaustive();

export const whereCompanyIsPartOfDataset = (user?: NullableAuthenticatedUser) => {
  return {
    OR: compact([{ demo: false, excludedFromDataset: false }, !!user && { id: user.companyId }]),
  } satisfies Prisma.CompanyWhereInput;
};

export const disableCompany = async (ctx: AppContext, input: DisableCompanyInput) => {
  await ctx.prisma.company.update({
    where: {
      id: input.companyId,
    },
    data: {
      // Disable the company
      disabledAt: new Date(),
      // Block all users
      users: {
        updateMany: {
          where: {},
          data: {
            blockedAt: new Date(),
          },
        },
      },
      subscriptions: {
        updateMany: {
          where: {},
          data: {
            endsAt: new Date(),
            comment: "Company disabled",
          },
        },
      },
      //Removes company from feature flags
      featureFlags: {
        set: [],
      },
    },
  });

  await ctx.redis.del(CacheKeys.OrderedParticipants);
};

export const reEnableCompany = async (ctx: AppContext, input: ReEnableCompanyInput) => {
  const company = await ctx.prisma.company.update({
    where: {
      id: input.companyId,
    },
    data: {
      // Re-enable the company
      disabledAt: null,
      // Unblock all users
      users: {
        updateMany: {
          where: {},
          data: {
            blockedAt: null,
          },
        },
      },
      subscriptions: {
        updateMany: {
          where: {},
          data: {
            endsAt: null,
            comment: "Company re-enabled",
          },
        },
      },
    },
    select: { id: true, url: true, name: true, defaultCountry: { select: { defaultCurrencyId: true } } },
  });

  const survey = await ctx.prisma.companySurvey.create({
    data: {
      status: "LIVE",
      company: { connect: { id: input.companyId } },
      collectedAt: new Date(),
    },
  });

  await ctx.prisma.company.update({
    where: { id: input.companyId },
    data: { liveSurvey: { connect: { id: survey.id } } },
  });

  await syncCompanyLogo(ctx, company);

  await ctx.redis.del(CacheKeys.OrderedParticipants);
};
