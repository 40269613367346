import { type AppContext } from "~/lib/context";
import { EmployeeLevel } from "~/services/employee/employeeLevel";

export const FIGURES_FRAMEWORK_NAME = "Figures - Advanced";
export const FIGURES_FRAMEWORK_ID = 2;

//This is used as a fallback to create matching benchmark levels for the Figures levels
export const createMissingBenchmarkLevel = async (ctx: AppContext, params: { figuresLevel: EmployeeLevel }) => {
  const { figuresLevel } = params;

  const existingBenchmarkLevel = await ctx.prisma.benchmarkLevel.findFirst({
    where: { name: figuresLevel, framework: { name: FIGURES_FRAMEWORK_NAME } },
  });

  if (!!existingBenchmarkLevel) {
    return existingBenchmarkLevel;
  }

  const figuresFramework = await ctx.prisma.levelFramework.findFirst({
    where: { name: FIGURES_FRAMEWORK_NAME },
    select: { id: true },
  });

  if (!figuresFramework) {
    const framework = await ctx.prisma.levelFramework.create({
      data: {
        name: FIGURES_FRAMEWORK_NAME,
        isPublic: true,
      },
    });

    const benchmarkLevel = LEVELS_DETAILS.find((level) => level.name === figuresLevel);

    if (!benchmarkLevel) {
      return;
    }

    return ctx.prisma.benchmarkLevel.create({
      data: {
        name: benchmarkLevel?.name,
        min: benchmarkLevel?.min,
        max: benchmarkLevel?.max,
        frameworkId: framework.id,
      },
    });
  }

  const benchmarkLevel = LEVELS_DETAILS.find((level) => level.name === figuresLevel);

  if (!benchmarkLevel) {
    return;
  }

  return ctx.prisma.benchmarkLevel.create({
    data: {
      name: benchmarkLevel.name,
      min: benchmarkLevel.min,
      max: benchmarkLevel.max,
      frameworkId: figuresFramework.id,
    },
  });
};

const LEVELS_DETAILS = [
  { name: EmployeeLevel.BEGINNER, min: 1, max: 5 },
  { name: EmployeeLevel.JUNIOR, min: 6, max: 9 },
  { name: EmployeeLevel.INTERMEDIATE, min: 10, max: 13 },
  { name: EmployeeLevel.SENIOR, min: 14, max: 17 },
  { name: EmployeeLevel.STAFF, min: 18, max: 21 },
  { name: EmployeeLevel.PRINCIPAL, min: 22, max: 25 },
  { name: EmployeeLevel.TEAM_LEAD, min: 26, max: 29 },
  { name: EmployeeLevel.MANAGER, min: 30, max: 33 },
  { name: EmployeeLevel.DIRECTOR, min: 34, max: 35 },
  { name: EmployeeLevel.HEAD_OF, min: 36, max: 37 },
  { name: EmployeeLevel.VP, min: 38, max: 39 },
  { name: EmployeeLevel.C_LEVEL, min: 40, max: 40 },
] as const;
