import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import { type ValidationError } from "yup";
import type { AppContext } from "~/lib/context";
import { keyBy, mapValues } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";

export const handleValidationError = (ctx: AppContext, res: NextApiResponse, error: ValidationError) => {
  logWarn(ctx, "[error] Validation error", { error });

  return res.status(HttpStatus.UNPROCESSABLE_ENTITY).json({
    error: "Validation error",
    fields: mapValidationErrorFields(error),
  });
};

export const mapValidationErrorFields = (error: ValidationError) => {
  return mapValues(
    keyBy(error.inner, (field: ValidationError) => {
      return field.path;
    }),
    (field: ValidationError) => {
      return field.message;
    }
  );
};
