import { type Prisma } from "@prisma/client";
import { value } from "~/components/helpers";
import { salaryRangeEmployeeForRangePenetrationIndicator } from "~/components/salary-bands/RangePenetrationIndicator";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { externalEmployeeFieldsSelectForDisplay } from "~/services/additional-field/getAllAdditionalFields";
import { externalEmployeeSelectForDisplay } from "~/services/compensation-review/campaigns/admin/fetchCompensationReviewEmployees";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import {
  canUpdateRecommendation,
  isPendingRecommendation,
} from "~/services/compensation-review/shared/recommendationStatus";
import {
  selectCompensationReviewCurrency,
  transformCompensationReviewCurrency,
} from "~/services/compensation-review/shared/transformCompensationReviewCurrency";
import { externalEmployeePanelSelect } from "~/services/external-employee/fetchExternalEmployeeForPanel";

export const compensationReviewEmployeeSelect = {
  id: true,
  effectiveDate: true,
  isEligibleForExceptionalBonus: true,
  isPromoted: true,
  baseSalary: true,
  variablePay: true,
  otherBonus: true,
  onTargetEarnings: true,
  totalCash: true,
  budget: true,
  baseSalaryRecommendation: true,
  variablePayRecommendation: true,
  onTargetEarningsRecommendation: true,
  annualPerformanceBonusPayoutPercentage: true,
  annualPerformanceBonusRecommendation: true,
  baseSalaryIncrease: true,
  variablePayIncrease: true,
  onTargetEarningsIncrease: true,
  proratedOnTargetEarningsIncrease: true,
  proratedOnTargetEarningsRecommendation: true,
  isIncreaseDifferentFromRecommendation: true,
  annualPerformanceBonus: true,
  exceptionalBonus: true,
  compaRatio: true,
  rangePenetration: true,
  newCompaRatio: true,
  newRangePenetration: true,
  salaryBandMeasure: true,
  currency: { select: selectCompensationReviewCurrency },
  performanceRating: {
    select: {
      name: true,
      description: true,
    },
  },
  offCycleReviewRequest: {
    select: {
      id: true,
      status: true,
    },
  },
  externalEmployee: {
    select: {
      ...externalEmployeeSelectForDisplay,
      ...externalEmployeeFieldsSelectForDisplay,
      ...externalEmployeePanelSelect,
      manager: {
        select: externalEmployeeSelectForDisplay,
      },
      job: {
        select: {
          name: true,
        },
      },
      level: {
        select: {
          name: true,
        },
      },
      location: {
        select: {
          name: true,
          mappedLocation: {
            select: {
              name: true,
              country: {
                select: {
                  name: true,
                  alpha2: true,
                },
              },
            },
          },
          country: {
            select: {
              name: true,
              alpha2: true,
            },
          },
        },
      },
    },
  },
  targetRangeAfterPromotion: {
    select: {
      level: {
        select: { name: true },
      },
      min: true,
      max: true,
      midpoint: true,
      band: {
        select: {
          id: true,
          measure: true,
          currency: true,
          job: {
            select: { name: true },
          },
          location: {
            select: { name: true },
          },
        },
      },
    },
  },
  recommendations: {
    select: {
      id: true,
      position: true,
      baseSalaryIncrease: true,
      variablePayIncrease: true,
      exceptionalBonus: true,
      annualPerformanceBonus: true,
      status: true,
      comment: true,
      submittedAt: true,
      isFinalRecommendation: true,
      reviewer: {
        select: {
          id: true,
          canReviewExceptionalBonus: true,
          externalEmployee: {
            select: {
              ...externalEmployeeSelectForDisplay,
              userPermissions: {
                select: {
                  role: true,
                  user: {
                    select: { id: true, profilePictureUrl: true },
                  },
                },
              },
            },
          },
        },
      },
      skippedByReviewer: {
        select: {
          id: true,
          externalEmployee: {
            select: externalEmployeeSelectForDisplay,
          },
        },
      },
    },
    orderBy: { position: "asc" },
  },
} satisfies Prisma.CompensationReviewEmployeeSelect;

export type CompensationReviewEmployeeRow = Prisma.CompensationReviewEmployeeGetPayload<{
  select: typeof compensationReviewEmployeeSelect;
}>;

export const enrichCompensationReviewEmployees = async <T extends { employee: CompensationReviewEmployeeRow }>(
  ctx: CompensationReviewContext,
  params: { rows: T[] }
) => {
  const user = getRequiredUser(ctx);

  const salaryRangeEmployees = user.company.defaultSalaryGridId
    ? await ctx.prisma.salaryRangeEmployee.findMany({
        where: {
          gridId: user.company.defaultSalaryGridId,
          externalEmployeeId: { in: params.rows.map((row) => row.employee.externalEmployee.id) },
        },
        select: {
          externalEmployeeId: true,
          ...salaryRangeEmployeeForRangePenetrationIndicator,
          range: {
            select: {
              min: true,
              max: true,
              midpoint: true,
              band: {
                select: {
                  id: true,
                  measure: true,
                  currency: true,
                },
              },
            },
          },
        },
      })
    : [];

  return params.rows.map((row) => {
    const pendingRecommendation = row.employee.recommendations.find(isPendingRecommendation);

    const salaryRangeEmployee =
      salaryRangeEmployees.find(
        (salaryRangeEmployee) => salaryRangeEmployee.externalEmployeeId === row.employee.externalEmployee.id
      ) ?? null;

    return {
      ...row,

      employee: {
        ...row.employee,
        currency: transformCompensationReviewCurrency(row.employee.currency),

        salaryRangeEmployee,
        pendingRecommendation,

        recommendations: row.employee.recommendations.map((recommendation) => {
          const canEdit =
            recommendation.reviewer?.id === ctx.authenticatedReviewer?.id &&
            canUpdateRecommendation(recommendation) &&
            ctx.permissions.canReview;

          const canBeSetAsCurrentReviewer = value(() => {
            if (!recommendation.reviewer) return false;
            if (!ctx.permissions.canSetCurrentReviewer) return false;
            if (isPendingRecommendation(recommendation)) return false;

            const highestReviewerRecommendation = row.employee.recommendations.findLast(
              (otherRecommendation) => otherRecommendation.reviewer?.id === ctx.authenticatedReviewer?.id
            );
            if (highestReviewerRecommendation && highestReviewerRecommendation.position < recommendation.position)
              return false;

            return true;
          });

          const canDelete =
            ctx.permissions.canUpdateReviewers && !recommendation.isFinalRecommendation && !!recommendation.reviewer;

          return {
            ...recommendation,
            canEdit,
            canBeSetAsCurrentReviewer,
            canDelete,
          };
        }),
      },
    };
  });
};
