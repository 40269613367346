import { type Company, EmployeeStatsComparisonScope } from "@prisma/client";
import { chain } from "~/lib/lodash";
import { isFromCompany } from "~/services/employee";
import { getMappedLevel } from "~/services/employee/employeeLevel";
import { type EmployeeRow } from "~/services/employee/getLiveEmployees";

export type EmployeesBucket = ReturnType<typeof generateEmployeesBuckets>[number];

export const generateEmployeesBuckets = (params: {
  employees: EmployeeRow[];
  company: Pick<Company, "id" | "useAdvancedLevels">;
  levelScope?: "internal" | "external";
  locationScope?: EmployeeStatsComparisonScope;
}) => {
  const levelScope = params.levelScope ?? "internal";
  const locationScope = params.locationScope ?? EmployeeStatsComparisonScope.LOCATION;

  return chain(params.employees)
    .groupBy((employee) => {
      const levelKey =
        levelScope === "external"
          ? employee.externalEmployee?.levelId?.toString() ?? "N/A"
          : getMappedLevel(employee.level, { useAdvancedLevels: params.company.useAdvancedLevels });
      const locationKey =
        locationScope === EmployeeStatsComparisonScope.COUNTRY ? employee.location.countryId : employee.locationId;

      return [locationKey, employee.jobId, levelKey].join(",");
    })
    .values()
    .map((employees) => {
      const { externalLevel, job, level } = employees[0] as EmployeeRow;
      const companyEmployees = employees.filter(isFromCompany([params.company.id]));

      return {
        companyEmployees,
        employees,
        externalLevel,
        job,
        level: getMappedLevel(level, { useAdvancedLevels: params.company.useAdvancedLevels }),
        levelScope,
        locationScope,
      } as const;
    })
    .value();
};
