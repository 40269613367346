import { randomUUID } from "crypto";
import { CORRELATION_ID_KEY } from "~/lib/logger";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";

export const createCorrelationContext = async (ctx: MiddlewareContext) => {
  const currentRequestId = Array.isArray(ctx.req.headers["x-request-id"])
    ? ctx.req.headers["x-request-id"][0]
    : ctx.req.headers["x-request-id"];

  ctx.req.metadata.set(CORRELATION_ID_KEY, currentRequestId ?? randomUUID());

  return next(ctx);
};
