import React, { useContext } from "react";
import { useFeatureFlags } from "~/hooks/useFeatureFlags";
import { useSession } from "~/hooks/useSession";
import { useSubscriptions } from "~/hooks/useSubscriptions";
import { curry } from "~/lib/lodash";
import { type PermissionsSchema } from "~/services/permissions/permissionsSchema";
import { type PermissionsParams, type PermissionsStatus } from "~/services/user/permissions/authenticationOptions";
import {
  canAccessAdditionalField,
  canAccessExternalSalaryBandMarketData,
  canAccessFiguresSalaryBandMarketData,
  type PermissionFunction,
} from "~/services/user/permissions/functions";
import { canUpdateSalaryGrid } from "~/services/user/permissions/salaryBandsPermissions";
import { type UserRoles } from "~/services/user/permissions/utils/getUserRoles";

/* eslint-disable @typescript-eslint/no-explicit-any */
type OmitParams<F> = F extends (x: any, ...args: infer P) => infer R ? (...args: P) => R : never;

const forwardParams = <F extends PermissionFunction<any>>(fn: F, params: PermissionsParams) => curry(fn)(params);
/* eslint-enable @typescript-eslint/no-explicit-any */

type PermissionsMethods = {
  canAccessAdditionalField: OmitParams<typeof canAccessAdditionalField>;
  canAccessFiguresSalaryBandMarketData: OmitParams<typeof canAccessFiguresSalaryBandMarketData>;
  canAccessExternalSalaryBandMarketData: OmitParams<typeof canAccessExternalSalaryBandMarketData>;
  canUpdateSalaryGrid: OmitParams<typeof canUpdateSalaryGrid>;
};

type PermissionsProps = React.PropsWithChildren<{
  permissions: PermissionsStatus;
  role: UserRoles;
  permissionsSchema: PermissionsSchema;
}>;

type PermissionsReturnType = PermissionsProps & { functions: PermissionsMethods };

export const PermissionsContext = React.createContext<PermissionsReturnType | null>(null);

export const usePermissions = (): PermissionsReturnType => {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error("usePermissions must be used within a PermissionsProvider");
  }

  return context;
};

export const PermissionsProvider: React.FC<PermissionsProps> = ({ permissions, role, children, permissionsSchema }) => {
  const { user } = useSession();
  const featureFlags = useFeatureFlags();
  const { subscriptions } = useSubscriptions();
  const params = { featureFlags, subscriptions, user, permissionsSchema } as const;

  const functions = {
    canAccessAdditionalField: forwardParams(canAccessAdditionalField, params),
    canAccessFiguresSalaryBandMarketData: forwardParams(canAccessFiguresSalaryBandMarketData, params),
    canAccessExternalSalaryBandMarketData: forwardParams(canAccessExternalSalaryBandMarketData, params),
    canUpdateSalaryGrid: forwardParams(canUpdateSalaryGrid, params),
  };

  return (
    <PermissionsContext.Provider value={{ permissions, role, functions, permissionsSchema }}>
      {children}
    </PermissionsContext.Provider>
  );
};
