import { CompanyMarketPositioningType, type Prisma } from "@prisma/client";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { datasetEmployeeHasTags, whereCompanyIsPartOfDataset } from "~/services/company";
import { datasetEmployeeHasFundingRound, datasetEmployeeIsWithinSize } from "~/services/company-survey/helpers";
import { type CompanyWithCompensationSettings } from "~/services/employee-stats/fetchCompanyWithCompensationSettings";
import { generateDatasetEmployeeWhere } from "~/services/employee/createBucket";
import { getHeadcountRange } from "~/services/headcount";
import { getCapitalLocationsByCountryId } from "~/services/locations/location";

export const datasetEmployeeForStatsSelect = {
  id: true,
  externalId: true,
  companyId: true,
  churnedCompanyId: true,
  company: {
    select: {
      id: true,
      tags: { select: { id: true } },
    },
  },
  jobId: true,
  locationId: true,
  level: true,
  benchmarkLevelMax: true,
  benchmarkLevelMin: true,
  baseSalary: true,
  fixedBonus: true,
  companyHeadcount: true,
  companyFundingRound: true,
  companyTagsIds: true,
  currencyCode: true,
  euroExchangeRate: true,
  onTargetBonus: true,
  location: {
    select: {
      name: true,
      countryId: true,
      country: {
        select: {
          name: true,
        },
      },
    },
  },
  job: {
    select: {
      name: true,
      familyId: true,
    },
  },
  lastValidAt: true,
} satisfies Prisma.DatasetEmployeeSelect;

//getting companies employees for job id, location id and levels
const employeesForBucketCreate = async (
  ctx: AppContext,
  params: { company: CompanyWithCompensationSettings; employeesIds: number[] }
) => {
  return ctx.prisma.employee.findMany({
    where: { id: { in: params.employeesIds } },
    select: {
      jobId: true,
      level: true,
      location: {
        select: {
          id: true,
          countryId: true,
        },
      },
      benchmarkLevel: {
        select: {
          id: true,
          min: true,
          max: true,
        },
      },
    },
  });
};

export type GenerateDatasetEmployeeWhereParams = AsyncReturnType<typeof employeesForBucketCreate>;

export const fetchComparableDatasetEmployeesForStats = async (
  ctx: AppContext,
  params: { company: CompanyWithCompensationSettings; employeesIds: number[] }
) => {
  const employeesForBucket = await employeesForBucketCreate(ctx, params);

  const capitalLocationsByCountryId = await getCapitalLocationsByCountryId(ctx);

  const whereClause = generateDatasetEmployeeWhere(employeesForBucket, {
    company: params.company,
    capitalLocations: capitalLocationsByCountryId,
    useBenchmarkLevelsRange: ctx.featureFlags.CAN_ACCESS_LEVEL_FRAMEWORKS,
  });

  const comparableDatasetEmployees = await fetchComparableDatasetEmployees(ctx, {
    company: params.company,
    employeesIds: params.employeesIds,
    whereClause,
  });

  const comparableDatasetEmployeesWithCapitalLocationId = comparableDatasetEmployees.map((datasetEmployee) => {
    return {
      ...datasetEmployee,
      capitalLocationId: datasetEmployee.location?.countryId
        ? capitalLocationsByCountryId[datasetEmployee.location.countryId]
        : null,
    };
  });

  if (!params.company.marketPositioningId) {
    return comparableDatasetEmployeesWithCapitalLocationId;
  }

  const defaultFilter = await ctx.prisma.marketPositioning.findUniqueOrThrow({
    where: {
      id: params.company.marketPositioningId,
    },
  });

  return comparableDatasetEmployeesWithCapitalLocationId.filter((datasetEmployee) => {
    return match(defaultFilter.type)
      .with(CompanyMarketPositioningType.ENTIRE_MARKET, () => true)
      .with(CompanyMarketPositioningType.SIMILAR_HEADCOUNT, () => {
        const size = ctx.featureFlags.CAN_ACCESS_FIGURES_AI_V2
          ? getHeadcountRange(defaultFilter.headcount)
          : { min: defaultFilter.minHeadcount, max: defaultFilter.maxHeadcount };

        return datasetEmployeeIsWithinSize(size)(datasetEmployee);
      })
      .with(CompanyMarketPositioningType.SIMILAR_FUNDING_STAGE, () => {
        if (!defaultFilter.fundingRounds) {
          return true;
        }

        return datasetEmployeeHasFundingRound(defaultFilter.fundingRounds)(datasetEmployee);
      })
      .with(CompanyMarketPositioningType.SPECIFIC_INDUSTRY, () => {
        if (!defaultFilter.industryId) {
          return true;
        }

        return datasetEmployeeHasTags([defaultFilter.industryId])(datasetEmployee);
      })
      .exhaustive();
  });
};

export type DatasetEmployeeForStats = AsyncReturnType<typeof fetchComparableDatasetEmployeesForStats>[number];

const fetchComparableDatasetEmployees = async (
  ctx: AppContext,
  params: {
    company: CompanyWithCompensationSettings;
    employeesIds: number[];
    whereClause: Prisma.DatasetEmployeeWhereInput;
  }
) => {
  // Ensure whereClause.OR exists and is an array
  const whereConditions = params.whereClause.OR ?? [];

  // Execute queries in parallel for better performance
  const results = await Promise.all(
    whereConditions.map((condition) =>
      ctx.prisma.datasetEmployee.findMany({
        where: {
          company: whereCompanyIsPartOfDataset(ctx.user),
          excludedFromDataset: false,
          AND: condition.AND,
        },
        select: datasetEmployeeForStatsSelect,
        distinct: ["companyId", "externalId", "jobId", "locationId", "level"],
        orderBy: { liveAt: "desc" },
      })
    )
  );

  // Combine all results into a single array
  return results.flat();
};
