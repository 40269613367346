import { type Prisma } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { type AuthenticatedUser } from "~/lib/session";
import { type PermissionsSchema, type UserPermissionsSchemaFilters } from "~/services/permissions/permissionsSchema";

export const generateOwnCompanyPermissionsSchema = (ctx: AppContext, filters: UserPermissionsSchemaFilters) => {
  const user = getRequiredUser(ctx);
  const { ownedByCompany, inSameBusinessUnit, isAdmin } = filters;
  const ownCompanyPermissions = { read: ownedByCompany, update: ownedByCompany, delete: ownedByCompany };
  const customJobIsOwned = whereCustomJobIsOwned(user);

  return {
    CompanyFile: {
      read: {
        ...ownedByCompany,
        OR: [{ ownerUserId: user.id }, { sharedWithUsers: { some: { id: user.id } } }],
      },
      update: { ...ownedByCompany, ...(!isAdmin && { ownerUserId: user.id }) },
      delete: { ...ownedByCompany, ...(!isAdmin && { ownerUserId: user.id }) },
    },
    CompanySettings: {
      read: ownedByCompany,
      update: ownedByCompany,
      delete: ownedByCompany,
    },
    JobRequest: ownCompanyPermissions,
    CustomJob: {
      read: customJobIsOwned,
      update: customJobIsOwned,
      delete: customJobIsOwned,
    },
    CustomJobItem: {
      read: { customJob: customJobIsOwned },
      update: { customJob: customJobIsOwned },
      delete: { customJob: customJobIsOwned },
    },
    Population: {
      read: ownedByCompany,
      update: ownedByCompany,
      delete: ownedByCompany,
    },
    CompanyImpersonationAccess: ownCompanyPermissions,
    CompanyImpersonationLog: {
      read: { impersonationWhitelist: ownedByCompany },
      update: { impersonationWhitelist: ownedByCompany },
      delete: { impersonationWhitelist: ownedByCompany },
    },
    CompanyTargetPercentile: ownCompanyPermissions,
    CompanySurvey: ownCompanyPermissions,
    CompanyEmploymentCost: ownCompanyPermissions,
    EmployeeStats: {
      read: { employee: ownedByCompany },
      update: { employee: ownedByCompany },
      delete: { employee: ownedByCompany },
    },
    AnalyticsEmployee: ownCompanyPermissions,
    EmployeeDataValidationFlag: ownCompanyPermissions,
    CompanyDataValidationFlag: ownCompanyPermissions,
    GoogleSheet: ownCompanyPermissions,
    IntegrationSettings: ownCompanyPermissions,
    ExternalLocation: ownCompanyPermissions,
    ExternalJob: ownCompanyPermissions,
    ExternalJobMappingSuggestion: ownCompanyPermissions,
    ExternalLevel: ownCompanyPermissions,
    ExternalRemunerationItem: {
      read: { employee: ownedByCompany },
      update: { employee: ownedByCompany },
      delete: { employee: ownedByCompany },
    },
    AdditionalMonthRule: ownCompanyPermissions,
    CustomRemunerationItem: ownCompanyPermissions,
    CustomRemunerationItemMapping: ownCompanyPermissions,
    ExternalRemunerationNature: ownCompanyPermissions,
    CompensationPlanningCampaign: ownCompanyPermissions,
    CompensationPlanningInflationSettings: {
      read: { campaign: ownedByCompany },
      update: { campaign: ownedByCompany },
      delete: { campaign: ownedByCompany },
    },
    CompensationPlanningEmployee: {
      read: {
        campaign: ownedByCompany,
        ...(inSameBusinessUnit && { externalEmployee: inSameBusinessUnit }),
      },
      update: { campaign: ownedByCompany },
      delete: { campaign: ownedByCompany },
    },
    CompensationPlanningPerformanceRating: {
      read: { campaign: ownedByCompany },
      update: { campaign: ownedByCompany },
      delete: { campaign: ownedByCompany },
    },
    PerformanceReviewCycle: ownCompanyPermissions,
    PerformanceReviewRating: ownCompanyPermissions,
    PerformanceReviewIntegrationSettings: ownCompanyPermissions,
    RemappingRequest: ownCompanyPermissions,
    CompanyJobGroup: {
      read: ownedByCompany && isAdmin,
      update: ownedByCompany && isAdmin,
      delete: ownedByCompany && isAdmin,
    },
    MappedCompanyJobGroup: {
      read: ownedByCompany && isAdmin,
      update: ownedByCompany && isAdmin,
      delete: ownedByCompany && isAdmin,
    },
    CompanyLevelGroup: {
      read: ownedByCompany && isAdmin,
      update: ownedByCompany && isAdmin,
      delete: ownedByCompany && isAdmin,
    },
    MappedCompanyLevelGroup: {
      read: ownedByCompany && isAdmin,
      update: ownedByCompany && isAdmin,
      delete: ownedByCompany && isAdmin,
    },
    CompanyLocationGroup: {
      read: ownedByCompany && isAdmin,
      update: ownedByCompany && isAdmin,
      delete: ownedByCompany && isAdmin,
    },
    MappedCompanyLocationGroup: {
      read: ownedByCompany && isAdmin,
      update: ownedByCompany && isAdmin,
      delete: ownedByCompany && isAdmin,
    },
  } satisfies Partial<PermissionsSchema>;
};

export const whereCustomJobIsOwned = (user: AuthenticatedUser) => {
  return {
    OR: [
      {
        companyId: user.companyId,
        sharedWithCompany: true,
      },
      {
        userId: user.id,
      },
    ],
  } satisfies Prisma.CustomJobWhereInput;
};
