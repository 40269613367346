import { FeatureFlagScope, type Prisma, UserRole } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AsyncReturnType } from "type-fest";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { difference, keyBy, mapValues } from "~/lib/lodash";
import { logInfo } from "~/lib/logger";
import { dangerouslyIgnorePrismaRestrictions } from "~/lib/prismaTokens";
import { type RemoveCompanyFromFeatureFlagInput } from "~/pages/api/feature-flags/remove-company-from-feature-flag";
import { type UpdateFeatureFlagInput } from "~/pages/api/feature-flags/update-feature-flag";

export enum FeatureFlagName {
  CAN_ACCESS_BUSINESS_UNITS = "CAN_ACCESS_BUSINESS_UNITS",
  CAN_ACCESS_ASSISTED_ONBOARDING_FLOW = "CAN_ACCESS_ASSISTED_ONBOARDING_FLOW",
  CAN_ACCESS_COMPENSATION_PLANNING = "CAN_ACCESS_COMPENSATION_PLANNING",
  CAN_ACCESS_SAML_SSO = "CAN_ACCESS_SAML_SSO",
  CANNOT_ACCESS_PEOPLE_MARKET_POSITIONING = "CANNOT_ACCESS_PEOPLE_MARKET_POSITIONING",
  CAN_ACCESS_FIGURES_AI_V2 = "CAN_ACCESS_FIGURES_AI_V2",
  CAN_BYPASS_JOB_SELECTOR_LIMIT = "CAN_BYPASS_JOB_SELECTOR_LIMIT",
  CAN_ACCESS_EMPLOYEE_FORM_SUMMER24_EDITION = "CAN_ACCESS_EMPLOYEE_FORM_SUMMER24_EDITION",
  CAN_USE_BENCHMARK_DATA_FILTERS_WITH_MULTIPLE_VALUES = "CAN_USE_BENCHMARK_DATA_FILTERS_WITH_MULTIPLE_VALUES",
  FORCE_FIGURES_PROFILE_PICTURES = "FORCE_FIGURES_PROFILE_PICTURES",
  CAN_ACCESS_GENDER_PAY_GAP_REPORT = "CAN_ACCESS_GENDER_PAY_GAP_REPORT",
  AI_POWERED_JOB_AUTOMAPPING_ENABLED = "AI_POWERED_JOB_AUTOMAPPING_ENABLED", // automapping with AI
  CAN_ACCESS_BIG_FOUR_LABEL = "CAN_ACCESS_BIG_FOUR_LABEL",
  CANNOT_ACCESS_COMPANY_DASHBOARD = "CANNOT_ACCESS_COMPANY_DASHBOARD", // 🥹
  CAN_ACCESS_LEVEL_FRAMEWORKS = "CAN_ACCESS_LEVEL_FRAMEWORKS",
  CAN_ACCESS_SUBSIDIARIES = "CAN_ACCESS_SUBSIDIARIES",
  CAN_ACCESS_BENCHMARK_V2 = "CAN_ACCESS_BENCHMARK_V2",
  CAN_ACCESS_DETAILED_MEASURES = "CAN_ACCESS_DETAILED_MEASURES",
}

const whereCurrentlyKnownFeatureFlags = {
  name: { in: Object.values(FeatureFlagName) },
} satisfies Prisma.FeatureFlagWhereInput;

export const fetchFeatureFlagsForBackoffice = async (ctx: AppContext) => {
  return ctx.prisma.featureFlag.findMany({
    where: whereCurrentlyKnownFeatureFlags,
    include: {
      companies: {
        select: {
          id: true,
          name: true,
          logo: true,
        },
      },
    },
  });
};

export type FeatureFlagsWithCompaniesForBackoffice = AsyncReturnType<typeof fetchFeatureFlagsForBackoffice>;
export type FeatureFlagWithCompaniesForBackoffice = FeatureFlagsWithCompaniesForBackoffice[number];

export const fetchFeatureFlagsForCompany = async (ctx: AppContext) => {
  return ctx.prisma.featureFlag.findMany({
    ...dangerouslyIgnorePrismaRestrictions(),
    where: whereCurrentlyKnownFeatureFlags,
    include: {
      companies: {
        where: { id: getRequiredUser(ctx).companyId },
        select: {
          id: true,
          name: true,
          logo: true,
        },
      },
    },
  });
};

export type FeatureFlagsForCompany = AsyncReturnType<typeof fetchFeatureFlagsForCompany>;
export type FeatureFlagForCompany = FeatureFlagsForCompany[number];

export const isFeatureFlagActiveForCompany = (featureFlag: FeatureFlagForCompany, companyId?: number) => {
  if (featureFlag.scope === FeatureFlagScope.PUBLIC) {
    return true;
  }

  if (!companyId || featureFlag.scope === FeatureFlagScope.DISABLED) {
    return false;
  }

  return featureFlag.companies.some((company) => {
    return company.id === companyId;
  });
};

export type FeatureFlagsStatus = Record<FeatureFlagName, boolean>;

const computeFeatureFlagsWithCompany = async (ctx: AppContext): Promise<FeatureFlagsStatus> => {
  const user = getRequiredUser(ctx);
  const allFeatureFlags = await fetchFeatureFlagsForCompany(ctx);

  const flagsByName = keyBy(allFeatureFlags, "name");
  return mapValues(flagsByName, (flag) => {
    if (
      flag.scope === FeatureFlagScope.ADMINS_FROM_SELECTED_COMPANIES &&
      !user?.isSuperAdmin &&
      user?.permissions.role !== UserRole.ADMIN
    ) {
      return false;
    }

    return isFeatureFlagActiveForCompany(flag, user.companyId);
  }) as FeatureFlagsStatus;
};

const computeFeatureFlagsWithoutCompany = async (ctx: AppContext): Promise<FeatureFlagsStatus> => {
  const allFeatureFlags = await ctx.prisma.featureFlag.findMany({
    where: whereCurrentlyKnownFeatureFlags,
  });

  const flagsByName = keyBy(allFeatureFlags, "name");
  return mapValues(flagsByName, ({ scope }) => scope === FeatureFlagScope.PUBLIC) as FeatureFlagsStatus;
};

export const getActiveFeatureFlagsForUser = async (ctx: AppContext) => {
  if (ctx.user) {
    return computeFeatureFlagsWithCompany(ctx);
  }

  return computeFeatureFlagsWithoutCompany(ctx);
};

export const removeCompanyForFeatureFlag = async (ctx: AppContext, input: RemoveCompanyFromFeatureFlagInput) => {
  await ctx.prisma.company.findUniqueOrThrow({
    where: { id: input.companyId },
    select: { id: true },
  });

  return ctx.prisma.featureFlag.update({
    where: { name: input.name },
    data: {
      companies: {
        disconnect: {
          id: input.companyId,
        },
      },
    },
    include: {
      companies: true,
      _count: {
        select: { companies: true },
      },
    },
  });
};

export const updateFeatureFlagScope = async (ctx: AppContext, input: UpdateFeatureFlagInput) => {
  return ctx.prisma.featureFlag.update({
    where: { name: input.name },
    data: {
      scope: input.scope,
    },
  });
};

export const getFeatureFlagCompanyCount = async (ctx: AppContext, featureFlagName: string) => {
  return ctx.prisma.company.count({
    where: { featureFlags: { some: { name: featureFlagName } } },
  });
};

const locallyDisabledFeatureFlags = [
  FeatureFlagName.CAN_ACCESS_LEVEL_FRAMEWORKS,
  FeatureFlagName.FORCE_FIGURES_PROFILE_PICTURES,
  FeatureFlagName.CANNOT_ACCESS_COMPANY_DASHBOARD,
  FeatureFlagName.CANNOT_ACCESS_PEOPLE_MARKET_POSITIONING,
  FeatureFlagName.CAN_ACCESS_BENCHMARK_V2,
  FeatureFlagName.CAN_ACCESS_DETAILED_MEASURES,
];

export const setupFeatureFlags = async (ctx: AppContext) => {
  const dbFlags = await ctx.prisma.featureFlag.findMany({ select: { name: true } });
  const codeFlags = Object.values(FeatureFlagName);
  const flagsNotInDb = difference(
    codeFlags,
    dbFlags.map(({ name }) => name)
  );
  const isNotProductionEnvironment = config.app.env !== "production";

  await mapSeries(flagsNotInDb, async (flag) => {
    await ctx.prisma.featureFlag.create({
      data: {
        name: flag,
        ...(isNotProductionEnvironment && { scope: FeatureFlagScope.PUBLIC }),
      },
    });

    logInfo(ctx, "[chore] Created feature flag in database", {
      flag,
    });
  });

  if (isNotProductionEnvironment && codeFlags.some((flag) => flag.startsWith("CANNOT_"))) {
    await ctx.prisma.featureFlag.updateMany({
      where: {
        name: { in: locallyDisabledFeatureFlags },
      },
      data: {
        scope: FeatureFlagScope.DISABLED,
      },
    });

    logInfo(ctx, "[chore] CANNOT feature flags scope updated in database");
  }
};
