import { Prisma, type Product } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler, type NextApiRequest, type NextApiResponse } from "next";
import { type Route } from "nextjs-routes";
import { ValidationError } from "yup";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { ApiFeatureAccessError, handleFeatureAccessError } from "~/lib/errors/apiFeatureAccessError";
import {
  ApiImpersonationForbiddenError,
  handleImpersonationForbiddenError,
} from "~/lib/errors/apiImpersonationForbiddenError";
import {
  ApiImpersonationMismatchError,
  handleImpersonationMismatchError,
} from "~/lib/errors/apiImpersonationMismatchError";
import { ApiValidationError, handleApiValidationError } from "~/lib/errors/apiValidationError";
import { AuthenticationError, handleAuthenticationError } from "~/lib/errors/authenticationError";
import {
  AuthenticationRequiresProviderConfirmationError,
  handleAuthenticationRequiresProviderConfirmationError,
} from "~/lib/errors/authenticationRequiresProviderConfirmationError";
import { BusinessLogicError, handleBusinessLogicError } from "~/lib/errors/businessLogicError";
import { ForbiddenError, handleForbiddenError } from "~/lib/errors/forbiddenError";
import { GithubError, handleGithubError } from "~/lib/errors/githubError";
import { handleKomboError, KomboError } from "~/lib/errors/komboError";
import {
  handleSamlAuthenticationEnforcedError,
  SamlAuthenticationEnforcedError,
} from "~/lib/errors/samlAuthenticationEnforcedError";
import { handleUserNotFoundError, UserNotFoundError } from "~/lib/errors/userNotFoundError";
import { handleValidationError } from "~/lib/errors/validationError";
import { handleSpreadsheetImportError, XlsxImportError } from "~/lib/errors/xlsxImportError";
import { trackApiEndpointCalled } from "~/lib/external/segment/server/events";
import { translateYupError } from "~/lib/i18n/yupErrors";
import { initContext } from "~/lib/initContext";
import { logError, logInfo, logWarn } from "~/lib/logger";
import { assignRequestId } from "~/lib/middleware/assignRequestId";
import { cloneContextToRequest } from "~/lib/middleware/cloneContextToRequest";
import { createCorrelationContext } from "~/lib/middleware/createCorrelationContext";
import { fetchUserFromSession } from "~/lib/middleware/fetchUserFromSession";
import { fetchUserIdFromSession } from "~/lib/middleware/fetchUserIdFromSession";
import { createMiddlewareChain, MiddlewareRuntime } from "~/lib/middleware/middleware";
import { parseSessionCookies } from "~/lib/middleware/parseSessionCookies";
import { protectRouteFromBlockedUsers } from "~/lib/middleware/protectRouteFromBlockedUsers";
import { protectRouteWithAccess } from "~/lib/middleware/protectRouteWithAccess";
import { protectRouteWithApiKey } from "~/lib/middleware/protectRouteWithApiKey";
import { protectRouteWithFeatureFlag } from "~/lib/middleware/protectRouteWithFeatureFlag";
import { protectRouteWithPermissions } from "~/lib/middleware/protectRouteWithPermissions";
import { protectRouteWithSubscription } from "~/lib/middleware/protectRouteWithSubscription";
import { protectSuperAdminRoutes } from "~/lib/middleware/protectSuperAdminRoutes";
import { resolveCompanyImpersonation } from "~/lib/middleware/resolveCompanyImpersonation";
import { resolveSubsidiaryImpersonation } from "~/lib/middleware/resolveSubsidiaryImpersonation";
import { resolveUserImpersonation } from "~/lib/middleware/resolveUserImpersonation";
import { setupContext } from "~/lib/middleware/setupContext";
import { setupServer } from "~/lib/middleware/setupServer";
import { updateImpersonationContext } from "~/lib/middleware/updateImpersonationContext";
import { updateLastActivity } from "~/lib/middleware/updateLastActivity";
import { getServerContext } from "~/lib/serverContext";
import { type AuthenticationOptions } from "~/lib/session";
import { type FeatureFlagName } from "~/services/featureFlags";

const handlePrismaNotFoundError = (
  ctx: AppContext,
  res: NextApiResponse,
  error: Prisma.PrismaClientKnownRequestError
) => {
  logWarn(ctx, "[error] Prisma: Entity not found", { error });

  return res.status(HttpStatus.NOT_FOUND).json({
    error: "Entity not found",
  });
};

const handlePrismaUnexpectedError = (
  ctx: AppContext,
  res: NextApiResponse,
  error: Prisma.PrismaClientKnownRequestError
) => {
  logWarn(ctx, "[error] Unexpected Prisma failure", { error });

  return res.status(HttpStatus.INTERNAL_SERVER_ERROR).json({
    error: "An unforeseen error occurred, our engineers have been notified",
  });
};

const handleMethodNotAllowedError = (ctx: AppContext, res: NextApiResponse) => {
  logInfo(ctx, "[error] Method not allowed");

  return res.status(HttpStatus.METHOD_NOT_ALLOWED).end();
};

const handleGenericError = (ctx: AppContext, res: NextApiResponse, error: Error) => {
  logError(ctx, `[error] Generic: ${error.message ?? "Unknown"}`, { error });

  return res.status(HttpStatus.INTERNAL_SERVER_ERROR).json({
    error: "Internal server error",
    ...(config.app.debug && {
      message: error.message,
      stack: error.stack,
    }),
  });
};

export type ApiHandlerOptions = {
  authentication?: AuthenticationOptions;
  feature?: FeatureFlagName;
  product?: Product;
  method?: "GET" | "POST";
  access?: (ctx: AppContext) => Route | undefined;
};

export type ApiHandler<T> = (req: NextApiRequest, res: NextApiResponse<T>) => unknown | Promise<unknown>;

export const api = <T>(handler: ApiHandler<T>, options?: ApiHandlerOptions): NextApiHandler<T> => {
  return async (req, res) => {
    try {
      if (options?.method && options.method !== req.method) {
        return handleMethodNotAllowedError(req, res);
      }

      const authenticationOptions = options?.authentication ?? {};

      await createMiddlewareChain({ req, res, runtime: MiddlewareRuntime.API })
        .use(assignRequestId)
        .use(cloneContextToRequest, { context: getServerContext() })
        .use(createCorrelationContext)
        .use(setupServer)
        .use(parseSessionCookies)
        .use(protectRouteWithApiKey, { apiKey: authenticationOptions.apiKey })
        .use(fetchUserIdFromSession, { optional: authenticationOptions.optional ?? false })
        .use(fetchUserFromSession, { optional: authenticationOptions.optional ?? false })
        .use(protectRouteFromBlockedUsers)
        .use(protectSuperAdminRoutes, { routeRequiresSuperAdmin: authenticationOptions.superAdmin ?? false })
        .use(resolveUserImpersonation)
        .use(resolveSubsidiaryImpersonation)
        .use(resolveCompanyImpersonation)
        .use(setupContext, { req })
        .use(protectRouteWithAccess, { access: options?.access })
        .use(protectRouteWithPermissions, { permissions: authenticationOptions })
        .use(protectRouteWithFeatureFlag, { feature: options?.feature })
        .use(protectRouteWithSubscription, { product: options?.product })
        .use(updateLastActivity)
        .use(updateImpersonationContext)
        .run();

      if (!req.user) {
        await initContext(req, null);
      }

      await handler(req, res);
    } catch (error) {
      if (error instanceof Prisma.PrismaClientKnownRequestError) {
        if (error.name === "NotFoundError") {
          return handlePrismaNotFoundError(req, res, error);
        }

        return handlePrismaUnexpectedError(req, res, error);
      }

      if (error instanceof ForbiddenError) return handleForbiddenError(req, res, error);
      if (error instanceof AuthenticationError) return handleAuthenticationError(req, res, error);
      if (error instanceof SamlAuthenticationEnforcedError) return handleSamlAuthenticationEnforcedError(res, error);
      if (error instanceof ApiFeatureAccessError) return handleFeatureAccessError(req, res, error);
      if (error instanceof ValidationError) return handleValidationError(req, res, translateYupError(req.t, error));
      if (error instanceof ApiValidationError) return handleApiValidationError(req, res, error);
      if (error instanceof ApiImpersonationForbiddenError) return handleImpersonationForbiddenError(req, res, error);
      if (error instanceof ApiImpersonationMismatchError) return handleImpersonationMismatchError(req, res, error);
      if (error instanceof BusinessLogicError) return handleBusinessLogicError(req, res, error);
      if (error instanceof XlsxImportError) return handleSpreadsheetImportError(req, res, error);
      if (error instanceof UserNotFoundError) return handleUserNotFoundError(req, res, error);
      if (error instanceof KomboError) return handleKomboError(req, res, error);
      if (error instanceof GithubError) return handleGithubError(req, res, error);
      if (error instanceof AuthenticationRequiresProviderConfirmationError)
        return handleAuthenticationRequiresProviderConfirmationError(res, error);

      return handleGenericError(req, res, error);
    } finally {
      await trackApiEndpointCalled(req, { req });
    }
  };
};
