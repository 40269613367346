import { ComparisonGeographicStrategy, type EmployeeLevel, EmployeeStatsComparisonScope } from "@prisma/client";
import { chain, pick } from "~/lib/lodash";
import { hasJob, hasLevel, hasLocation } from "~/services/employee";
import { type DatasetEmployeeForStats } from "~/services/employee-stats/dataset-employees-stats/fetchComparableDatasetEmployeesForStats";
import { type CompanyWithCompensationSettings } from "~/services/employee-stats/fetchCompanyWithCompensationSettings";
import { type EmployeeForStats } from "~/services/employee-stats/fetchComparableEmployeesForStats";
import { getComparedCountryIds, getComparedLocationIds } from "~/services/employee-stats/utils";
import { getComparedBenchmarkRange, getMergedLevels } from "~/services/employee/employeeLevel";
import { hasBenchmarkRange } from "~/services/employee/index";

export const findComparableDatasetEmployees = (
  params: {
    company: CompanyWithCompensationSettings;
    employee: EmployeeForStats;
    marketDatasetEmployees: DatasetEmployeeForStats[];
  },
  options: { useBenchmarkRange: boolean }
) => {
  const comparedCountryIds = getComparedCountryIds(params.company, params.employee);
  const comparedLocationIds = getComparedLocationIds(params.company, params.employee);
  const comparedLevels = getMergedLevels(params.employee.level, {
    mergeAdvancedLevels: !params.company.useAdvancedLevels,
  }) as EmployeeLevel[];

  const comparedBenchmarkRange = getComparedBenchmarkRange(params.employee);

  const comparisonParams = {
    comparedCountryIds,
    comparedLocationIds,
    comparedLevels,
    comparedBenchmarkRange,
    employeeCompanyId: params.company.id,
    jobId: params.employee.jobId,
    marketDatasetEmployees: params.marketDatasetEmployees,
  };

  return params.company.comparisonGeographicStrategy === ComparisonGeographicStrategy.ENTIRE_COUNTRY
    ? findComparableDatasetEmployeesForCountry(comparisonParams, options)
    : findComparableDatasetEmployeesForLocation(comparisonParams, options);
};

export type ComparableEmployeesWithinDataset = ReturnType<typeof findComparableDatasetEmployees>;

const getLocationComparableEmployees = (
  params: {
    comparedLevels: EmployeeLevel[];
    comparedBenchmarkRange: { min: number; max: number };
    comparedLocationIds: number[];
    jobId: EmployeeForStats["jobId"];
    marketDatasetEmployees: DatasetEmployeeForStats[];
  },
  options: { useBenchmarkRange: boolean }
) => {
  return params.marketDatasetEmployees
    .filter(hasJob([params.jobId]))
    .filter((employee) => {
      if (options.useBenchmarkRange) {
        return hasBenchmarkRange(params.comparedBenchmarkRange)({
          benchmarkLevel: { min: employee.benchmarkLevelMin ?? 0, max: employee.benchmarkLevelMax ?? 0 },
        });
      }
      return hasLevel(params.comparedLevels, [])(employee);
    })
    .filter(hasLocation(params.comparedLocationIds));
};

const findComparableDatasetEmployeesForLocation = (
  params: {
    comparedCountryIds: number[];
    comparedLocationIds: number[];
    comparedLevels: EmployeeLevel[];
    comparedBenchmarkRange: { min: number; max: number };
    employeeCompanyId: number;
    jobId: number;
    marketDatasetEmployees: DatasetEmployeeForStats[];
  },
  options: { useBenchmarkRange: boolean }
) => {
  const employees = getLocationComparableEmployees(
    pick(params, [
      "comparedLevels",
      "comparedLocationIds",
      "comparedBenchmarkRange",
      "jobId",
      "marketDatasetEmployees",
    ]),
    options
  );

  const companiesCount = countComparableDatasetCompanies({ employees: employees });

  return {
    comparisonScope: EmployeeStatsComparisonScope.LOCATION,
    comparedCountryIds: params.comparedCountryIds,
    comparedLocationIds: params.comparedLocationIds,
    comparedLevels: params.comparedLevels,
    employees,
    companiesCount,
  };
};

const getCountryComparableEmployees = (
  params: {
    comparedCountryIds: number[];
    comparedLevels: EmployeeLevel[];
    comparedBenchmarkRange: { min: number; max: number };
    jobId: EmployeeForStats["jobId"];
    marketDatasetEmployees: DatasetEmployeeForStats[];
  },
  options: { useBenchmarkRange: boolean }
) => {
  return params.marketDatasetEmployees
    .filter(hasJob([params.jobId]))
    .filter((employee) => {
      if (options.useBenchmarkRange) {
        return hasBenchmarkRange(params.comparedBenchmarkRange)({
          benchmarkLevel: { min: employee.benchmarkLevelMin ?? 0, max: employee.benchmarkLevelMax ?? 0 },
        });
      }

      return hasLevel(params.comparedLevels, [])(employee);
    })
    .filter(
      (employee) => employee.location?.countryId && params.comparedCountryIds.includes(employee.location.countryId)
    );
};

const findComparableDatasetEmployeesForCountry = (
  params: {
    comparedCountryIds: number[];
    comparedLocationIds: number[];
    comparedLevels: EmployeeLevel[];
    comparedBenchmarkRange: { min: number; max: number };
    employeeCompanyId: number;
    jobId: number;
    marketDatasetEmployees: DatasetEmployeeForStats[];
  },
  options: { useBenchmarkRange: boolean }
) => {
  const employees = getCountryComparableEmployees(
    pick(params, ["comparedCountryIds", "comparedLevels", "comparedBenchmarkRange", "jobId", "marketDatasetEmployees"]),
    options
  );
  const companiesCount = countComparableDatasetCompanies({ employees });

  return {
    comparisonScope: EmployeeStatsComparisonScope.COUNTRY,
    comparedLocationIds: params.comparedLocationIds,
    comparedCountryIds: params.comparedCountryIds,
    comparedLevels: params.comparedLevels,
    employees: employees,
    companiesCount: companiesCount,
  };
};

const countComparableDatasetCompanies = (params: { employees: DatasetEmployeeForStats[] }) => {
  return chain(params.employees)
    .uniqBy((employee) => employee.companyId ?? employee.churnedCompanyId)
    .size()
    .value();
};
