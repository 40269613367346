import { Autocomplete, ListItemText, MenuItem, Stack, TextField } from "@mui/material";
import { OffCycleReviewRequestReason } from "@prisma/client";
import { useEffect } from "react";
import { ConfigurationFormControl } from "~/components/compensation-review/ConfigurationFormControl";
import { Button } from "~/components/ui/core/Button";
import { DatePicker } from "~/components/ui/core/DatePicker";
import { DropDownSelector } from "~/components/ui/core/DropDownSelector";
import { LoadingIndicator } from "~/components/ui/core/LoadingIndicator";
import { Modal } from "~/components/ui/core/Modal";
import { useAlerts } from "~/hooks/useAlerts";
import { useForm } from "~/hooks/useForm";
import { usePermissions } from "~/hooks/usePermissions";
import { useI18n } from "~/lib/i18n/useI18n";
import { getKeys } from "~/lib/utils";
import {
  RequestOffCycleReviewSchema,
  useRequestOffCycleReviewMutation,
} from "~/pages/api/compensation-review/off-cycle-reviews/request-off-cycle-review";
import { useFetchExternalEmployeeNextSalaryRangeQuery } from "~/pages/api/salary-bands/fetch-external-employee-next-salary-range";
import { useFetchSalaryRangesForAutocompleteQuery } from "~/pages/api/salary-bands/fetch-salary-ranges-for-autocomplete";
import {
  formatOffCycleReviewRequestReason,
  reasonRequiresSalaryRangeUpdate,
} from "~/services/compensation-review/off-cycle-reviews/offCycleReviewRequestReason";

type FormValues = {
  externalEmployeeId: number;
  reason: OffCycleReviewRequestReason | null;
  targetRange: { id: number; name: string } | null;
  effectiveDate: Date | null;
  comment: string | null;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  externalEmployeeId: number;
};

export const OffCycleReviewRequestModal: React.FC<Props> = ({ isOpen, onClose, externalEmployeeId }) => {
  const { t } = useI18n();
  const { triggerAlert } = useAlerts();
  const { permissions } = usePermissions();

  const requestOffCycleReview = useRequestOffCycleReviewMutation();

  const form = useForm<FormValues>({
    initialValues: {
      externalEmployeeId,
      reason: null,
      targetRange: null,
      effectiveDate: null,
      comment: null,
    },
    validationSchema: RequestOffCycleReviewSchema,
    onSubmit: async (values) => {
      if (!values.reason || !values.effectiveDate || !values.comment) return;

      await requestOffCycleReview.mutateAsync({
        ...values,
        reason: values.reason,
        targetRangeId: values.targetRange?.id ?? null,
        effectiveDate: values.effectiveDate,
        comment: values.comment,
      });

      triggerAlert({
        message: t("components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.success"),
      });
      onClose();
    },
  });

  // to avoid an infinite rendering loop caused by useForm.validateOnMount=true when externalEmployeeId change
  useEffect(() => {
    if (form.values.externalEmployeeId !== externalEmployeeId) {
      void form.asyncUpdateValues({ externalEmployeeId });
    }
  }, [form.values.externalEmployeeId, externalEmployeeId]);

  const enablePromotionFields = permissions.canAccessSalaryBands && reasonRequiresSalaryRangeUpdate(form.values.reason);

  const { data: nextSalaryRange } = useFetchExternalEmployeeNextSalaryRangeQuery({
    input: { externalEmployeeId },
    options: { enabled: enablePromotionFields },
  });

  const { data: salaryRangesForAutoComplete } = useFetchSalaryRangesForAutocompleteQuery({
    options: { enabled: enablePromotionFields && form.values.reason === OffCycleReviewRequestReason.LEVEL_CHANGE },
  });

  useEffect(() => {
    if (nextSalaryRange) {
      form.updateValues({ targetRange: nextSalaryRange });
    }
  }, [nextSalaryRange]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.title")}
      subtitle={t("components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.description")}
    >
      <Stack className="p-4" component="form" spacing={4} onSubmit={form.handleSubmit}>
        <ConfigurationFormControl
          label={t("components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.reason")}
          description={t(
            "components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.reason-details"
          )}
          alignItems="center"
          control={
            <DropDownSelector
              className="h-10 text-base font-normal !text-black"
              disablePortal={false}
              value={form.values.reason}
              onChange={(reason) => form.updateValues({ reason })}
              options={getKeys(OffCycleReviewRequestReason)}
              placeholder={t(
                "components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.select-reason"
              )}
              formatOption={(option) => formatOffCycleReviewRequestReason(t, option)}
            />
          }
        />

        {enablePromotionFields && (
          <ConfigurationFormControl
            label={t("components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.target-range")}
            description={t(
              "components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.target-range-details"
            )}
            alignItems="center"
            control={
              salaryRangesForAutoComplete !== undefined ? (
                <Autocomplete
                  options={salaryRangesForAutoComplete}
                  getOptionLabel={(salaryRange) => salaryRange.name}
                  renderOption={(props, salaryRange) => (
                    <MenuItem {...props} className="whitespace-normal">
                      <ListItemText>{salaryRange.name}</ListItemText>
                    </MenuItem>
                  )}
                  value={form.values.targetRange ?? undefined}
                  isOptionEqualToValue={(salaryRange, value) => salaryRange.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      className="w-96"
                      {...params}
                      placeholder={t(
                        "components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.target-salary-range-placeholder"
                      )}
                    />
                  )}
                  onChange={(_, targetRange) => {
                    form.updateValues({ targetRange });
                  }}
                  disableClearable
                />
              ) : (
                <LoadingIndicator className="ml-2" size="xs" />
              )
            }
          />
        )}

        <ConfigurationFormControl
          label={t("components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.effective-date")}
          description={t(
            "components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.effective-date-details"
          )}
          alignItems="center"
          control={
            <DatePicker
              className="w-96"
              value={form.values.effectiveDate}
              onChange={(effectiveDate) => form.updateValues({ effectiveDate })}
            />
          }
        />

        <ConfigurationFormControl
          label={t("components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.comment")}
          description={t(
            "components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.comment-details"
          )}
          control={
            <TextField
              className="lg:min-w-96 flex-1"
              multiline
              rows={6}
              value={form.values.comment ?? ""}
              onChange={(event) => form.updateValues({ comment: event.target.value })}
            />
          }
        />

        <Button
          type="submit"
          variant="contained"
          size="small"
          className="!mt-8 self-end"
          isLoading={requestOffCycleReview.isLoading}
          disabled={!form.isValid}
        >
          {t("components.compensation-review.off-cycle-reviews.off-cycle-review-request-form.send-request")}
        </Button>
      </Stack>
    </Modal>
  );
};
