import { type CompanySurvey, type DatasetEmployee, type FundingRound } from "@prisma/client";
import { type NumberRange } from "~/components/ui/LegacyCompanyFilters";

const isHeadcountWithinRange = (headcount: number | null, range: NumberRange): boolean => {
  if (range.min === null && range.max === null) {
    return true;
  }

  if (!headcount) {
    return false;
  }

  if (range.min && headcount < range.min) {
    return false;
  }

  if (range.max && headcount > range.max) {
    return false;
  }

  return true;
};

export const isWithinSize = (range: NumberRange) => {
  return (survey: CompanySurvey): boolean => isHeadcountWithinRange(survey.employeesCount, range);
};

export const datasetEmployeeIsWithinSize = (range: NumberRange) => {
  return (datasetEmployee: Pick<DatasetEmployee, "companyHeadcount">): boolean =>
    isHeadcountWithinRange(datasetEmployee.companyHeadcount, range);
};

const hasFundingRoundMatch = (round: FundingRound | null, rounds: FundingRound[]): boolean => {
  if (!rounds.length) {
    return true;
  }

  if (!round) {
    return false;
  }

  return rounds.includes(round);
};

export const hasFundingRound = (rounds: FundingRound[]) => {
  return (survey: Pick<CompanySurvey, "lastFundingRound">): boolean =>
    hasFundingRoundMatch(survey.lastFundingRound, rounds);
};

export const datasetEmployeeHasFundingRound = (rounds: FundingRound[]) => {
  return (datasetEmployee: Pick<DatasetEmployee, "companyFundingRound">): boolean =>
    hasFundingRoundMatch(datasetEmployee.companyFundingRound, rounds);
};
