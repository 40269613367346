/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  ExternalEmployeeSource,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  Gender,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { isAfter, parseISO } from "date-fns";
import { type ParsedUrlQueryInput } from "querystring";
import { match } from "ts-pattern";
import { type RequireExactlyOne } from "type-fest";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { isValidParsableDate } from "~/lib/dates";
import { fetch } from "~/lib/fetch";
import { fetchWithRetry } from "~/lib/fetchWithRetry";
import { buildCustomBaseSalaryRemunerationItem } from "~/lib/hris/helpers/buildCustomBaseSalaryRemunerationItem";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/computeAdditionalFieldValuePayloads";
import { getEmployeeProfilePicture } from "~/lib/hris/helpers/getEmployeeProfilePicture";
import { getMissingCustomFields, type IntegrationCustomFields } from "~/lib/hris/helpers/getMissingCustomFields";
import { getNumberOfMonth } from "~/lib/hris/helpers/getNumberOfMonth";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/mapCustomRemunerationItem";
import {
  getSalariesFromDossierRh,
  getSalaryAmount,
  getSalaryDate,
  getSalaryItem,
  getSalaryType,
  mapDossierRhFixedBonus,
  mapDossierRhFixedSalary,
  mapDossierRhVariableBonus,
} from "~/lib/hris/luccaDossierrh";
import { getSalariesFromPagga, mapPaggaFixedSalary, mapPaggaVariableBonus } from "~/lib/hris/luccaPagga";
import { type StaticModels } from "~/lib/integration";
import { chain, compact, has, isArray, isNumber, isString, last } from "~/lib/lodash";
import { logError, logWarn } from "~/lib/logger";
import { buildExternalUrl, hasValidHostname } from "~/lib/url";
import { assertProps } from "~/lib/utils";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/syncExternalEmployees";

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["domain", "clientSecret"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export type Credentials = {
  domain: string;
  clientSecret: string;
  anonymous?: boolean;
};

export const validLuccaHostnames = ["ilucca.net", "ilucca-demo.net"] as const;

const luccaFetch = async <T>(
  ctx: AppContext,
  credentials: Credentials,
  path: string,
  params: ParsedUrlQueryInput,
  options?: { privateApi?: boolean }
) => {
  const privateApi = options?.privateApi ?? false;
  const url = buildExternalUrl(`https://${credentials.domain}${path}`, params);

  if (!hasValidHostname(url, validLuccaHostnames)) {
    throw new Error("Invalid Lucca domain name");
  }

  const res = await fetchWithRetry(ctx, url, {
    headers: {
      Accept: "application/json",
      Authorization: `Lucca application=${credentials.clientSecret}`,
    },
  });

  const json = await res.json();

  if (!res.ok) {
    throw new Error(`${res.status} : ${json?.Message ?? res.statusText}`);
  }

  return (privateApi ? json : json.data) as T;
};

export type JobQualification = {
  id: number;
  name: string;
  job: {
    id: number;
    name: string;
  };
  level: {
    id: number;
    name: string;
  };
};

type GetJobQualificationsResponse = {
  items: JobQualification[];
};

export const getLuccaJobQualifications = async (
  ctx: AppContext,
  credentials: Credentials
): Promise<JobQualification[]> => {
  const res = await luccaFetch<GetJobQualificationsResponse>(
    ctx,
    credentials,
    "/organization/structure/api/job-qualifications",
    { limit: 1000 },
    { privateApi: true }
  );

  return res.items;
};

type GetFixedRemunerationItemsResponse = {
  items: FixedRemunerationItem[];
};

const getFixedRemunerationItems = async (
  ctx: AppContext,
  credentials: Credentials
): Promise<FixedRemunerationItem[]> => {
  const res = await luccaFetch<GetFixedRemunerationItemsResponse>(ctx, credentials, "/api/v3/fixedRemunerationItems", {
    isdeleted: false,
    fields: [
      "id",
      "workContract[owner[id]]",
      "nature[Id,name,isInActualRemuneration,isPonctual]",
      "startsOn",
      "endsOn",
      "amount",
      "isCurrent",
      "lastModifiedAt",
      "isdeleted",
    ],
  });

  return res.items;
};

type GetVariableRemunationItemsResponse = {
  items: VariableRemunerationItem[];
};

const getVariableRemunerationItems = async (
  ctx: AppContext,
  credentials: Credentials
): Promise<VariableRemunerationItem[]> => {
  const res = await luccaFetch<GetVariableRemunationItemsResponse>(
    ctx,
    credentials,
    "/api/v3/variableRemunerationItems",
    {
      isdeleted: false,
      fields: [
        "id",
        "workContract[owner[id,legalEntity[name],employeenumber,firstname,lastname]]",
        "nature[Id,name]",
        "period[date]",
        "amount",
        "lastModifiedAt",
        "isdeleted",
      ],
    }
  );

  return res.items;
};

type LuccaCaseInsensitiveSalary = RequireExactlyOne<
  {
    e_salary: CustomSalary[];
    e_Salary: CustomSalary[];
  },
  "e_salary" | "e_Salary"
>;

export type LuccaEmployee = {
  id: number;
  legalEntity: {
    id: number;
    name: string;
    country: {
      code: string;
      name: string;
    };
    newCountry: {
      currency: {
        code: string;
      };
    };
  };
  employeeNumber: string;
  firstName?: string;
  lastName?: string;
  mail?: string;
  gender: string;
  birthDate?: string;
  dtContractStart?: string;
  seniorityDate?: string;
  jobQualification?: {
    id: number;
    name: string;
  };
  jobTitle?: string;
  picture?: {
    id: string;
  };
  manager?: {
    id: number;
  };

  extendedData: LuccaCaseInsensitiveSalary & {
    [key: string]: null | TopLevelField | NestedField | ArrayOfNestedFields;
  };
};

type TopLevelField = LuccaValue<string | number>;

type NestedField = LuccaValue<{ [subKey: string]: TopLevelField }>;

type ArrayOfNestedFields = NestedField[];

type LuccaValue<T> = {
  id: number;
  value: T;
};

export type CustomSalary = {
  id: number;

  value: RequireExactlyOne<
    {
      e_salary_amount: LuccaValue<string>;
      e_Salary_amount: LuccaValue<string>;
    },
    "e_Salary_amount" | "e_salary_amount"
  > &
    RequireExactlyOne<
      {
        e_salary_type: LuccaValue<number>;
        e_Salary_type: LuccaValue<number>;
      },
      "e_salary_type" | "e_Salary_type"
    > &
    RequireExactlyOne<
      {
        e_salary_date: LuccaValue<string>;
        e_Salary_date: LuccaValue<string>;
      },
      "e_salary_date" | "e_Salary_date"
    >;
};

type GetUsersResponse = {
  items: LuccaEmployee[];
};

type GetFieldsResponse = {
  items: {
    isArchived: boolean;
    id: string;
    name: string;
    isMultivalue: boolean;
    type: number;
  }[];
};

export type GetFieldTypeResponse = {
  extensionUserPropertyListEntries: {
    id: number;
    name: string;
  }[];
};

const anonymise = (user: LuccaEmployee): LuccaEmployee => {
  delete user.firstName;
  delete user.lastName;
  delete user.mail;
  delete user.birthDate;
  delete user.picture;
  return user;
};

export const getUsers = async (
  ctx: AppContext,
  credentials: Credentials,
  query?: ParsedUrlQueryInput
): Promise<LuccaEmployee[]> => {
  const res = await luccaFetch<GetUsersResponse>(ctx, credentials, "/api/v3/users", {
    fields: [
      "id",
      "legalentity[id,name,country[id,code,name],newCountry.currency]",
      "employeenumber",
      "firstName",
      "lastName",
      "mail",
      "gender",
      "jobQualification[id,name]",
      "jobtitle",
      "picture",
      "birthDate",
      "dtContractStart",
      "extendedData",
      "seniorityDate",
      "manager",
    ],
    ...query,
  });

  if (credentials.anonymous) {
    return res.items.map(anonymise);
  }

  return res.items;
};

const getLuccaProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: LuccaUser }
) => {
  if (!options.apiEmployee.picture?.id) {
    return undefined;
  }

  return getEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.id.toString(),
    source: ExternalEmployeeSource.LUCCA,
    integrationSettings: options.integrationSettings,
    fetch: () =>
      fetch(`https://${options.integrationSettings.domain}/getFile.ashx?id=${options.apiEmployee.picture?.id}`, {
        headers: {
          Authorization: `Lucca application=${options.integrationSettings.clientSecret}`,
        },
      }),
  });
};

const getLuccaCustomFields = async (ctx: AppContext, credentials: Credentials) => {
  try {
    const res = await luccaFetch<GetFieldsResponse>(ctx, credentials, "/api/v3/extensionuserdefinitions", {
      fields: ["isArchived", "id", "name", "isMultivalue", "type"],
    });

    // Currently we only consider fields that are single-value and of string type (type 4)
    return res.items
      .filter((item) => !item.isArchived)
      .filter((item) => !item.isMultivalue)
      .filter((item) => [1, 2, 3, 4].includes(item.type))
      .map((item) => ({
        id: item.id,
        type: item.type,
        name: item.name || item.id,
      }));
  } catch (error) {
    throw new Error(`Couldn't fetch custom fields from your Lucca instance : ${error.code || error.message}`);
  }
};

const getHasStandardSalaries = (employee: LuccaEmployee) => {
  const salaryItem = getSalaryItem(employee);

  if (!isArray(salaryItem)) {
    return false;
  }

  return salaryItem.some((item) => !!getSalaryType(item) && !!getSalaryDate(item) && !!getSalaryAmount(item));
};

export type LuccaIntegrationSettingsInput = Credentials & IntegrationCustomFields;

export const getLuccaDiagnostic = async (ctx: AppContext, input: LuccaIntegrationSettingsInput) => {
  try {
    const employees = await getUsers(ctx, input, { paging: [0, 50] });

    if (employees.length === 0) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your Lucca account",
        missingFields: [],
        availableFields: [],
      };
    }

    const hasPagga = await getHasPagga(ctx, input);
    const hasDossierRH = employees.some((employee) => has(employee, "extendedData"));
    const hasStandardSalaries = employees.some((employee) => getHasStandardSalaries(employee));
    const hasCustomSalaries = await value(async () => {
      if (hasStandardSalaries) return false;

      const customFieldsTables = await fetchCustomFieldsTables(ctx, input);

      return employees.some((employee) =>
        getCustomField(employee, input.baseSalaryCustomFieldName, customFieldsTables)
      );
    });

    const availableFields = await getLuccaCustomFields(ctx, input);
    const missingFields = getMissingCustomFields(input, availableFields);

    return {
      connection: true,
      connectionError: "",
      missingFields,
      availableFields,
      extra: { hasPagga, hasDossierRH, hasStandardSalaries, hasCustomSalaries },
    };
  } catch (error) {
    const connectionError =
      error.type === "invalid-json" ? "Connection error : are you sure your Lucca URL is correct ?" : error.message;
    return { connection: false, connectionError, missingFields: [], availableFields: [] };
  }
};

export const getHasPagga = async (ctx: AppContext, credentials: Credentials) => {
  try {
    const items = await getFixedRemunerationItems(ctx, credentials);

    return items.length > 0;
  } catch (error) {
    logWarn(ctx, "[lucca] Error when fetching Pagga data", { error });
    return false;
  }
};

type WorkContract = {
  owner: WorkContractOwner;
};

type WorkContractOwner = {
  id: number;
};

export type FixedRemunerationItem = {
  id: number;
  workContract: WorkContract;
  nature: {
    id: number;
    name: string;
    isInActualRemuneration: boolean;
    isPonctual: boolean;
  };
  startsOn: string;
  endsOn: string | null;
  amount: number;
  isCurrent: boolean;
  lastModifiedAt: string;
  isDeleted: boolean;
};

export type VariableRemunerationItem = {
  id: number;
  workContract: WorkContract;
  nature: {
    id: number;
    name: string;
  };
  period: {
    date: string;
  };
  amount: number;
  lastModifiedAt: string;
  isDeleted: boolean;
};

export type LuccaUser = LuccaEmployee & {
  // Pagga
  fixedSalariesFromPagga?: FixedRemunerationItem[];
  historicalFixedSalariesFromPagga?: FixedRemunerationItem[];
  variableBonusesFromPagga?: VariableRemunerationItem[];
  // Dossier RH
  fixedSalariesFromDossierRh?: CustomSalary[];
  historicalFixedSalariesFromDossierRh?: CustomSalary[];
  variableBonusesFromDossierRh?: CustomSalary[];
  fixedBonusesFromDossierRh?: CustomSalary[];
};

export const getLuccaEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: Credentials
): Promise<LuccaUser[]> => {
  // Get users and reconcile them with their compensation data from Pagga
  const users = await getUsers(ctx, integrationSettings);

  const allFixedItems = await value(async () => {
    try {
      return await getFixedRemunerationItems(ctx, integrationSettings);
    } catch (error) {
      logWarn(ctx, "[sync] Could not fetch fixed remuneration items for company", {
        companyId: company.id,
        error,
      });
      return [];
    }
  });

  const allVariableItems = await value(async () => {
    try {
      return await getVariableRemunerationItems(ctx, integrationSettings);
    } catch (error) {
      logWarn(ctx, "[sync] Could not fetch variable remuneration items for company", {
        companyId: company.id,
        error,
      });
      return [];
    }
  });

  // Pagga is not implemented or not used, look in Dossier RH for salary information
  if (allFixedItems.length === 0 && allVariableItems.length === 0) {
    try {
      const salaryTypesResponse = await luccaFetch<GetFieldTypeResponse>(
        ctx,
        integrationSettings,
        "/api/v3/extensionuserdefinitions/e_salary_type",
        {
          fields: ["extensionUserPropertyListEntries"],
        }
      );

      const employeesWithSalariesFromDossierRh = getSalariesFromDossierRh(
        users,
        salaryTypesResponse.extensionUserPropertyListEntries
      );

      return employeesWithSalariesFromDossierRh;
    } catch (error) {
      logError(ctx, "[lucca] Can't fetch the salary information", { error });
      throw error;
    }
  }

  // Pagga is used
  const employeesWithSalariesFromPagga = getSalariesFromPagga(users, allFixedItems, allVariableItems);
  return employeesWithSalariesFromPagga;
};

const getLuccaValue = (
  fieldValue: string | number,
  referenceDictionary: { id: number; name: string }[] = []
): string | null => {
  // If the value is a number, it might be an ID to be found in the reference dictionary
  if (isNumber(fieldValue)) {
    const referenceItem = referenceDictionary.find((item) => item.id === fieldValue);

    if (referenceItem) {
      return referenceItem.name;
    }

    return `${fieldValue}`;
  }

  if (isString(fieldValue)) {
    return fieldValue;
  }

  return null;
};

const getDeeplyNestedValue = (
  valueOrArrayOfValues: LuccaEmployee["extendedData"][string],
  fieldName: string
): LuccaValue<string | number> | null => {
  if (!isArray(valueOrArrayOfValues)) {
    if (!valueOrArrayOfValues) {
      return null;
    }

    if (isString(valueOrArrayOfValues.value) || isNumber(valueOrArrayOfValues.value)) {
      return valueOrArrayOfValues as LuccaValue<string | number>;
    }

    return valueOrArrayOfValues.value?.[fieldName] ?? null;
  }

  let dateColumn: string;
  const hasADateColumn = valueOrArrayOfValues.some((individualValue) =>
    Object.keys(individualValue.value).some((subFieldKey) => {
      const subField = individualValue.value[subFieldKey] as LuccaValue<string | number>;

      if (isValidParsableDate(subField.value)) {
        dateColumn = subFieldKey;

        return true;
      }

      return false;
    })
  );

  if (hasADateColumn) {
    const mostRelevantByDateColumn = chain(valueOrArrayOfValues)
      .filter((individualValue) => {
        const dateValue = individualValue.value[dateColumn]?.value;

        return isValidParsableDate(dateValue);
      })
      .filter((individualValue) => {
        const dateValue = individualValue.value[dateColumn]?.value;

        return !isAfter(parseISO(dateValue as string), new Date());
      })
      .maxBy((individualValue) => individualValue.value[dateColumn]?.value)
      .value();

    if (!!mostRelevantByDateColumn) {
      return mostRelevantByDateColumn.value[fieldName] ?? null;
    }
  }

  return last(valueOrArrayOfValues)?.value[fieldName] ?? null;
};

const getPossibleValuesForCustomField = (apiEmployee: LuccaEmployee, fieldName: string) => {
  const { extendedData } = apiEmployee;

  const topLevelField = extendedData[fieldName];

  if (topLevelField) {
    return topLevelField;
  }

  const subField = chain(apiEmployee.extendedData)
    .values()
    .find((item) => {
      if (isArray(item)) {
        return item.some((subItem) => has(subItem.value, fieldName));
      }

      return has(item?.value, fieldName);
    })
    .value();

  if (isArray(subField)) {
    return subField.filter((subItem) => has(subItem.value, fieldName));
  }

  return subField;
};

/**
 * The format of Lucca (Dossier RH) custom fields can get pretty hairy sometimes
 * Check out the test cases in tests/unit/lib/hris/lucca.test.ts
 * Also details on possible formats are here :
 * https://www.notion.so/figures-hr/Lucca-01f39b8005964b898e5330a29633687b?pvs=4#847e2dac75164adb8cc0426994caf7ec
 */
const getCustomField = (
  apiEmployee: LuccaEmployee,
  fieldName: string | null,
  customFieldsTables: { [key: string]: { id: number; name: string }[] }
): string | null => {
  if (!fieldName) {
    return null;
  }

  const referenceDictionary = customFieldsTables[fieldName];

  const possibleValuesForCustomField = getPossibleValuesForCustomField(apiEmployee, fieldName);
  const customFieldValue = getDeeplyNestedValue(possibleValuesForCustomField, fieldName);

  if (!customFieldValue) {
    return null;
  }

  return getLuccaValue(customFieldValue.value, referenceDictionary);
};

export const mapLuccaUser = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: LuccaUser,
  jobQualifications: JobQualification[],
  customFieldsTables: { [key: string]: { id: number; name: string }[] },
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  ignoreProfilePicture?: boolean
) => {
  const qualification = jobQualifications.find((jobQualification) => {
    return jobQualification.id === apiEmployee.jobQualification?.id;
  });

  const {
    externalIdCustomFieldName,
    fteCustomFieldName,
    levelCustomFieldName,
    baseSalaryCustomFieldName,
    baseSalaryCustomFieldFrequency,
    variableCustomFieldName,
    holidayAllowanceCustomFieldName,
    businessUnitCustomFieldName,
    locationCustomFieldName,
    jobCustomFieldName,
    additionalFieldMappings = [],
    customRemunerationItemMappings = [],
  } = integrationSettings;
  const customExternalId = getCustomField(apiEmployee, externalIdCustomFieldName, customFieldsTables);
  const customFte = getCustomField(apiEmployee, fteCustomFieldName, customFieldsTables);
  const customLevel = getCustomField(apiEmployee, levelCustomFieldName, customFieldsTables);
  const customBaseSalary = getCustomField(apiEmployee, baseSalaryCustomFieldName, customFieldsTables);
  const customVariable = getCustomField(apiEmployee, variableCustomFieldName, customFieldsTables);
  const holidayAllowanceValue = getCustomField(apiEmployee, holidayAllowanceCustomFieldName, customFieldsTables);
  const businessUnit = getCustomField(apiEmployee, businessUnitCustomFieldName, customFieldsTables);
  const customLocation = getCustomField(apiEmployee, locationCustomFieldName, customFieldsTables);
  const customJob = getCustomField(apiEmployee, jobCustomFieldName, customFieldsTables);

  const additionalFieldValues = additionalFieldMappings.map(({ hrisFieldName, additionalFieldId, id }) => ({
    additionalFieldMappingId: id,
    additionalFieldId,
    value: getCustomField(apiEmployee, hrisFieldName, customFieldsTables),
  }));

  const customRemunerationItemsValues = customRemunerationItemMappings.map((customRemunerationItemMapping) => ({
    ...customRemunerationItemMapping,
    value: getCustomField(apiEmployee, customRemunerationItemMapping.hrisFieldName, customFieldsTables),
  }));

  const hireDate = value(() => {
    if (apiEmployee.seniorityDate) {
      return parseISO(apiEmployee.seniorityDate);
    }

    if (apiEmployee.dtContractStart) {
      return parseISO(apiEmployee.dtContractStart);
    }

    return null;
  });

  const locationExternalId = customLocation ?? apiEmployee.legalEntity.id.toString();

  const input: EmployeeData["input"] = {
    source: ExternalEmployeeSource.LUCCA,
    externalId: apiEmployee.id.toString(),
    status: "UNMAPPED",
    employeeNumber: customExternalId ?? apiEmployee.employeeNumber,
    firstName: apiEmployee.firstName,
    lastName: apiEmployee.lastName,
    email: apiEmployee.mail,
    birthDate: apiEmployee.birthDate ? parseISO(apiEmployee.birthDate) : null,
    hireDate,
    gender: value(() => {
      if (apiEmployee.gender === "Female") {
        return Gender.FEMALE;
      }
      if (apiEmployee.gender === "Male") {
        return Gender.MALE;
      }

      return null;
    }),
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: apiEmployee.legalEntity.newCountry.currency.code },
    },
    location: {
      connectOrCreate: {
        where: {
          companyId_externalId: {
            companyId: company.id,
            externalId: locationExternalId,
          },
        },
        create: {
          externalId: locationExternalId,
          name: customLocation ?? apiEmployee.legalEntity.name,
          autoMappingEnabled: true,
          company: {
            connect: { id: company.id },
          },
          ...(!customLocation && {
            country: {
              connect: { alpha2: apiEmployee.legalEntity.country.code },
            },
          }),
        },
      },
    },
    ...(qualification && {
      job: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: customJob ?? qualification.job.id.toString(),
            },
          },
          create: {
            name: customJob ?? qualification.job.name,
            externalId: customJob ?? qualification.job.id.toString(),
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
    ...(!qualification &&
      apiEmployee.jobTitle && {
        job: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: customJob ?? apiEmployee.jobTitle,
              },
            },
            create: {
              name: customJob ?? apiEmployee.jobTitle,
              externalId: customJob ?? apiEmployee.jobTitle,
              company: {
                connect: { id: company.id },
              },
            },
          },
        },
      }),
    ...(!customLevel &&
      qualification && {
        level: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: qualification.level.id.toString(),
              },
            },
            create: {
              name: qualification.level.name,
              externalId: qualification.level.id.toString(),
              company: {
                connect: { id: company.id },
              },
            },
          },
        },
      }),

    ...(customLevel && {
      level: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: customLevel,
            },
          },
          create: {
            name: customLevel,
            externalId: customLevel,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
    ...(businessUnit && { businessUnit }),
  };

  const remunerationItems: EmployeeData["remunerationItems"] = [];

  const numberMonths = getNumberOfMonth({
    externalId: locationExternalId,
    additionalMonthRules: staticModels.additionalMonthRules,
    externalLocations: staticModels.externalLocations,
  });

  const customBaseSalaryRemunerationItem = buildCustomBaseSalaryRemunerationItem({
    customBaseSalary,
    baseSalaryCustomFieldFrequency,
    numberMonths,
    company,
    source: ExternalEmployeeSource.LUCCA,
  });

  if (customBaseSalaryRemunerationItem) {
    remunerationItems.push(customBaseSalaryRemunerationItem);
  } else {
    if (apiEmployee.fixedSalariesFromPagga) {
      const fixedItems = apiEmployee.fixedSalariesFromPagga.map(
        mapPaggaFixedSalary(company, numberMonths, ExternalRemunerationStatus.LIVE)
      );
      remunerationItems.push(...fixedItems);
    }

    if (apiEmployee.historicalFixedSalariesFromPagga) {
      const historicalFixedItems = apiEmployee.historicalFixedSalariesFromPagga.map(
        mapPaggaFixedSalary(company, numberMonths, ExternalRemunerationStatus.HISTORICAL)
      );
      remunerationItems.push(...historicalFixedItems);
    }

    if (apiEmployee.fixedSalariesFromDossierRh) {
      const customFixedItems = apiEmployee.fixedSalariesFromDossierRh.map(
        mapDossierRhFixedSalary(company, numberMonths, ExternalRemunerationStatus.LIVE)
      );
      remunerationItems.push(...customFixedItems);
    }

    if (apiEmployee.historicalFixedSalariesFromDossierRh) {
      const customFixedItems = apiEmployee.historicalFixedSalariesFromDossierRh.map(
        mapDossierRhFixedSalary(company, numberMonths, ExternalRemunerationStatus.HISTORICAL)
      );
      remunerationItems.push(...customFixedItems);
    }
  }

  if (apiEmployee.fixedBonusesFromDossierRh) {
    const customBonusItems = apiEmployee.fixedBonusesFromDossierRh.map(mapDossierRhFixedBonus(company));
    remunerationItems.push(...customBonusItems);
  }

  if (customRemunerationItemsValues.length > 0) {
    const customItems = compact(
      customRemunerationItemsValues.map((customRemunerationItem) =>
        mapCustomRemunerationItem(integrationSettings, customRemunerationItem)
      )
    );
    remunerationItems.push(...customItems);
  }

  if (integrationSettings.variableCustomFieldName && integrationSettings.variableCustomFieldFrequency) {
    if (customVariable) {
      const multiplier = match(integrationSettings.variableCustomFieldFrequency)
        .with("MONTHLY", () => 12)
        .with("QUARTERLY", () => 4)
        .with("YEARLY", () => 1)
        .exhaustive();

      const amount = Math.round(parseInt(customVariable) * multiplier * 100);

      if (amount > 0) {
        remunerationItems.push({
          source: ExternalEmployeeSource.LUCCA,
          externalId: "variable-bonus",
          amount: amount,
          status: ExternalRemunerationStatus.LIVE,
          nature: {
            connectOrCreate: {
              where: {
                companyId_source_externalId: {
                  companyId: company.id,
                  source: ExternalEmployeeSource.LUCCA,
                  externalId: "variable-bonus",
                },
              },
              create: {
                source: ExternalEmployeeSource.LUCCA,
                externalId: "variable-bonus",
                name: "Variable bonus",
                mappedType: ExternalRemunerationType.VARIABLE_BONUS,
                company: {
                  connect: {
                    id: company.id,
                  },
                },
              },
            },
          },
        });
      }
    }
  } else {
    if (apiEmployee.variableBonusesFromPagga) {
      const variableItems = apiEmployee.variableBonusesFromPagga.map(mapPaggaVariableBonus(company));
      remunerationItems.push(...variableItems);
    }

    if (apiEmployee.variableBonusesFromDossierRh) {
      const customVariableItems = apiEmployee.variableBonusesFromDossierRh.map(mapDossierRhVariableBonus(company));
      remunerationItems.push(...customVariableItems);
    }
  }

  const managerExternalId = apiEmployee.manager ? apiEmployee.manager.id.toString() : undefined;

  return {
    input,
    picturePath: !ignoreProfilePicture
      ? await getLuccaProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
    additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
    managerExternalId,
    holidayAllowanceValue,
    // If one day they support hourly rates, we just have to update this (cf. bamboo)
    ...(customFte && { fte: customFte, ignoreFte: false }),
  };
};

const fetchCustomFieldsTables = async (ctx: AppContext, integrationSettings: Credentials & IntegrationCustomFields) => {
  const availableFields = await getLuccaCustomFields(ctx, integrationSettings);

  const customFieldIds = chain([
    integrationSettings.fteCustomFieldName,
    integrationSettings.levelCustomFieldName,
    integrationSettings.externalIdCustomFieldName,
    integrationSettings.variableCustomFieldName,
    integrationSettings.holidayAllowanceCustomFieldName,
    integrationSettings.businessUnitCustomFieldName,
    integrationSettings.locationCustomFieldName,
    integrationSettings.baseSalaryCustomFieldName,
    integrationSettings.jobCustomFieldName,
    ...(integrationSettings.additionalFieldMappings ?? []).map((field) => field.hrisFieldName),
    ...(integrationSettings.customRemunerationItemMappings ?? []).map((item) => item.hrisFieldName),
  ])
    .compact()
    .uniq()
    .filter((fieldId) => {
      const availableField = availableFields.find((field) => field.id === fieldId);

      // 1 corresponds the "Integer or list of values"
      // https://lucca.stoplight.io/docs/lucca-legacyapi/s39732zvvgdu3-introduction#type
      return availableField?.type === 1;
    })
    .value();

  const fetchCustomFieldTable = async (customFieldId: string) => {
    try {
      const res = await luccaFetch<GetFieldTypeResponse>(
        ctx,
        integrationSettings,
        `/api/v3/extensionuserdefinitions/${customFieldId}`,
        {
          fields: ["extensionUserPropertyListEntries"],
        }
      );

      return res.extensionUserPropertyListEntries;
    } catch (error) {
      logWarn(ctx, "[lucca] Can't fetch the custom field table", { customFieldId, error });
      throw error;
    }
  };

  const customFieldsTables = await mapSeries(customFieldIds, async (customFieldId) => {
    const table = await fetchCustomFieldTable(customFieldId);

    return {
      customFieldId,
      table,
    };
  });

  return chain(customFieldsTables)
    .keyBy((row) => row.customFieldId)
    .mapValues((row) => row.table)
    .value();
};

export const getMappedLuccaEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const jobQualifications = await getLuccaJobQualifications(ctx, safeIntegrationSettings);
  const customFieldsTables = await fetchCustomFieldsTables(ctx, safeIntegrationSettings);

  const luccaEmployees = await getLuccaEmployees(ctx, company, safeIntegrationSettings);

  return mapSeries(luccaEmployees, (luccaEmployee) =>
    mapLuccaUser(
      ctx,
      company,
      luccaEmployee,
      jobQualifications,
      customFieldsTables,
      safeIntegrationSettings,
      staticModels,
      ignoreProfilePicture
    )
  );
};

export type LuccaEmployeesWithSalariesAndCustomFields = [
  LuccaEmployee[],
  JobQualification[],
  { [key: string]: { id: number; name: string }[] },
];

export const getRawLuccaEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<LuccaEmployeesWithSalariesAndCustomFields> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const jobQualifications = await getLuccaJobQualifications(ctx, safeIntegrationSettings);
  const customFieldsTables = await fetchCustomFieldsTables(ctx, safeIntegrationSettings);

  const luccaEmployees = await getLuccaEmployees(ctx, company, safeIntegrationSettings);

  return [luccaEmployees, jobQualifications, customFieldsTables];
};
