import { CompensationReviewConfigurationStep } from "@prisma/client";
import { mapSeries } from "bluebird";
import { ensure } from "~/lib/ensure";
import { transaction } from "~/lib/transaction";
import {
  buildFetchCompensationReviewEmployeesInput,
  fetchCompensationReviewEmployees,
} from "~/services/compensation-review/campaigns/admin/fetchCompensationReviewEmployees";
import { type CompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";
import { canConfigureCampaign } from "~/services/compensation-review/compensationReviewPermissions";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import { initialiseCompensationReviewRecommendations } from "./initialiseCompensationReviewRecommendations";

export const resetCampaignRecommendations = async (ctx: CompensationReviewCampaignContext) => {
  ensure(() => canConfigureCampaign({ ...ctx, step: CompensationReviewConfigurationStep.CONFIRM_AND_START }));

  await ctx.prisma.compensationReviewRecommendation.deleteMany({
    where: prismaCompensationReviewScope(ctx.scope),
  });

  await ctx.prisma.compensationReviewEmployee.updateMany({
    where: prismaCompensationReviewScope(ctx.scope),
    data: {
      baseSalaryIncrease: null,
      convertedBaseSalaryIncrease: null,
      variablePayIncrease: null,
      convertedVariablePayIncrease: null,
      onTargetEarningsIncrease: null,
      convertedOnTargetEarningsIncrease: null,
      proratedOnTargetEarningsIncrease: null,
      isIncreaseDifferentFromRecommendation: false,
      onTargetEarningsIncreasePercentage: null,
      exceptionalBonus: null,
      convertedExceptionalBonus: null,
      annualPerformanceBonus: null,
      convertedAnnualPerformanceBonus: null,
      newCompaRatio: null,
      newRangePenetration: null,
      convertedNewOnTargetEarnings: null,
    },
  });

  const employees = await fetchCompensationReviewEmployees(ctx, {
    scope: ctx.scope,
    ...buildFetchCompensationReviewEmployeesInput({
      onlyEligible: true,
    }),
  });

  await transaction(
    ctx,
    async (ctx) => {
      await mapSeries(employees.items, async (employee) =>
        initialiseCompensationReviewRecommendations(ctx, { employee })
      );
    },
    {
      maxWait: 60_000,
      timeout: 60_000,
    }
  );
};
