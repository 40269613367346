import { EmployeeLevel, FundingRound, Headcount } from "@prisma/client";
import { array, mixed, object, string } from "yup";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { isEmpty, omitBy } from "~/lib/lodash";
import { logError } from "~/lib/logger";
import { type YupOutputType } from "~/lib/utils";

const FairSchema = object({
  countryName: string().required(),
  fundingRounds: array(mixed<FundingRound>().oneOf(Object.values(FundingRound)).required()).defined(),
  headcount: mixed<Headcount>().nullable(),
  industry: string().nullable(),
  jobName: string().required(),
  level: mixed<EmployeeLevel>().oneOf(Object.values(EmployeeLevel)).required(),
  locationName: string().nullable(),
});

export type FairInput = YupOutputType<typeof FairSchema>;

type FairCompanySize = "1-24" | "25-50" | "51-150" | "151-250" | "251-1000" | "1001-3000" | "3001-5000" | "5000+";

type FairFundingRound =
  | "NOT_SHARED"
  | "PRE_SEED"
  | "SEED"
  | "SERIES_A"
  | "SERIES_B"
  | "SERIES_C_D_E"
  | "POST_ACQUISITION"
  | "POST_IPO"
  | "PUBLIC_FUNDING"
  | "SELF_FINANCED";

type FairPayload = {
  companySize: FairCompanySize | null;
  employeeCountry: string;
  employeeLocation?: string | null;
  industry?: string | null;
  job: string;
  lastFundingRound?: string | null;
  level: EmployeeLevel;
  quantiles: number[];
};

// WARNING: So far, we only need these 5 quantiles.
// When we need more, we'll need to update the type and the API call.
export type QuantileMap = {
  quantile10: number;
  quantile25: number;
  quantile50: number;
  quantile75: number;
  quantile90: number;
};

export type FairPredictions = {
  confidence: 0 | 1 | 2 | 3 | 4;
  totalCashInEuros: QuantileMap;
  onTargetEarningsInEuros: QuantileMap;
  baseSalaryInEuros: QuantileMap;
};

const getFairPredictionsCacheKey = (input: FairInput) => {
  return `fair-predictions-${JSON.stringify(input)}`;
};

export const getFairPredictions = async (ctx: AppContext, schema: FairInput) => {
  const input = FairSchema.validateSync(schema, { abortEarly: false });

  return ctx.remember(getFairPredictionsCacheKey(input), async () => {
    const fairPayload: FairPayload = {
      companySize: null,
      employeeCountry: input.countryName,
      employeeLocation: input.locationName,
      industry: null,
      job: input.jobName,
      lastFundingRound: getLastFundingRound(input.fundingRounds),
      level: input.level,
      quantiles: [0.1, 0.25, 0.5, 0.75, 0.9],
    };

    if (ctx.featureFlags.CAN_ACCESS_FIGURES_AI_V2) {
      fairPayload.companySize = getCompanySize(input.headcount);
      fairPayload.industry = input.industry;
    }

    try {
      const res = await fetch(`${config.fair.url}/api/v1/fair/quantile_regression/single_prediction`, {
        method: "POST",
        headers: {
          "x-token": config.fair.token,
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(omitBy(fairPayload, isEmpty)),
      });

      if (res.status !== 200) {
        throw new Error(`FAIR API error: ${res.status}`);
      }

      return (await res.json()) as FairPredictions;
    } catch (err) {
      logError(
        ctx,
        `[FAIR] Error fetching AI predictions for company ${ctx.user?.companyId ?? ctx.user?.company.id}: ${err}`,
        fairPayload
      );
      return null;
    }
  });
};

const getCompanySize = (headcount: Headcount | null | undefined) => {
  if (!headcount) return null;

  const companySizes: Record<Headcount, FairCompanySize> = {
    [Headcount.UP_TO_24]: "1-24",
    [Headcount.BETWEEN_25_AND_50]: "25-50",
    [Headcount.BETWEEN_51_AND_150]: "51-150",
    [Headcount.BETWEEN_151_AND_250]: "151-250",
    [Headcount.BETWEEN_251_AND_1000]: "251-1000",
    [Headcount.BETWEEN_1001_AND_3000]: "1001-3000",
    [Headcount.BETWEEN_3001_AND_5000]: "3001-5000",
    [Headcount.MORE_THAN_5000]: "5000+",
  };

  return companySizes[headcount];
};

const getLastFundingRound = (fundingRounds: FundingRound[]) => {
  const fundingRound = fundingRounds[0];

  if (!fundingRound) return null;

  const lastFundingRounds: Record<FundingRound, FairFundingRound> = {
    [FundingRound.NOT_SHARED]: "NOT_SHARED",
    [FundingRound.PRE_SEED]: "PRE_SEED",
    [FundingRound.SEED]: "SEED",
    [FundingRound.SERIES_A]: "SERIES_A",
    [FundingRound.SERIES_B]: "SERIES_B",
    [FundingRound.SERIES_C]: "SERIES_C_D_E",
    [FundingRound.SERIES_D]: "SERIES_C_D_E",
    [FundingRound.SERIES_E]: "SERIES_C_D_E",
    [FundingRound.POST_ACQUISITION]: "POST_ACQUISITION",
    [FundingRound.POST_IPO]: "POST_IPO",
    [FundingRound.PUBLIC_FUNDING]: "PUBLIC_FUNDING",
    [FundingRound.SELF_FINANCED]: "SELF_FINANCED",
  };

  return lastFundingRounds[fundingRound];
};
