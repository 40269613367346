import { addYears, differenceInDays } from "date-fns";
import { parseISO } from "~/lib/dates";

export function computeDateProration(params: { amount: number; prorationStartDate: Date; effectiveDate: Date }): {
  proration: number;
  proratedAmount: number;
  prorationEndDate: Date;
  daysInYear: number;
  daysUntilEndDate: number;
};

export function computeDateProration(params: {
  amount: number | null;
  prorationStartDate: Date | null;
  effectiveDate: Date | null;
}): {
  proration: number;
  proratedAmount: number | null;
  prorationEndDate: Date | null;
  daysInYear: number | null;
  daysUntilEndDate: number | null;
};

export function computeDateProration(params: {
  amount: number | null;
  prorationStartDate: Date | null;
  effectiveDate: Date | null;
}) {
  if (!params.prorationStartDate || !params.effectiveDate || params.amount === null) {
    return {
      proration: 1,
      proratedAmount: params.amount,
      prorationEndDate: null,
      daysInYear: null,
      daysUntilEndDate: null,
    };
  }

  const prorationStartDate = parseISO(params.prorationStartDate);
  const effectiveDate = parseISO(params.effectiveDate);

  const prorationEndDate = addYears(prorationStartDate, 1);
  const daysInYear = differenceInDays(prorationEndDate, prorationStartDate);
  const daysUntilEndDate = Math.max(differenceInDays(prorationEndDate, effectiveDate), 0);
  const proration = daysUntilEndDate / daysInYear;
  const proratedAmount = params.amount * proration;

  return {
    proration,
    proratedAmount,
    prorationEndDate,
    daysInYear,
    daysUntilEndDate,
  };
}
