import {
  CompanyMarketPositioningType,
  type EmployeeLevel,
  type Prisma,
  type SalaryBandMarketPositioning,
} from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { fetchBenchmarkLevelsRange } from "~/services/benchmark-level/fetchBenchmarkLevelsRange";
import { getMarketDataEmployees } from "~/services/market-data/getMarketDataEmployees";
import { type GetMarketDataStatsEmployeesOptions } from "~/services/market-data/getMarketDataStats";

export const selectSalaryRangeForFetchLiveEmployeesForBenchmark = {
  id: true,
  level: {
    select: {
      benchmarkedLevels: {
        select: {
          level: true,
          benchmarkLevelId: true,
        },
      },
    },
  },
  band: {
    select: {
      measure: true,
      marketPositioning: true,
      currency: true,
      benchmarkedJobs: {
        select: {
          job: {
            select: {
              id: true,
              familyId: true,
            },
          },
        },
      },
      benchmarkedLocations: {
        select: {
          location: {
            select: { id: true },
          },
        },
      },
    },
  },
} satisfies Prisma.SalaryRangeSelect;

export const fetchLiveEmployeesForBenchmark = async (
  ctx: AppContext,
  params: {
    jobIds: number[];
    locationIds: number[];
    levels: EmployeeLevel[];
    benchmarkLevelIds: number[];
    marketPositioning: Pick<
      SalaryBandMarketPositioning,
      "type" | "headcount" | "minHeadcount" | "maxHeadcount" | "fundingRounds" | "industryId"
    > | null;
  }
) => {
  const { marketPositioning, jobIds, locationIds, levels, benchmarkLevelIds } = params;
  if (!jobIds.length || !locationIds.length || !levels.length) {
    return [];
  }

  const input: GetMarketDataStatsEmployeesOptions = { jobIds, locationIds, levels, benchmarkLevelIds };

  if (marketPositioning) {
    if (marketPositioning.type === CompanyMarketPositioningType.SIMILAR_HEADCOUNT) {
      if (!!marketPositioning.headcount) {
        input.headcount = marketPositioning.headcount;
      } else if (marketPositioning.minHeadcount || marketPositioning.maxHeadcount) {
        input.companySize = { min: marketPositioning.minHeadcount, max: marketPositioning.maxHeadcount };
      }
    }

    if (
      marketPositioning.type === CompanyMarketPositioningType.SIMILAR_FUNDING_STAGE &&
      marketPositioning.fundingRounds
    ) {
      input.fundingRounds = marketPositioning.fundingRounds;
    }

    if (marketPositioning.type === CompanyMarketPositioningType.SPECIFIC_INDUSTRY && marketPositioning.industryId) {
      const industry = await ctx.prisma.companyTag.findFirstOrThrow({
        where: { id: marketPositioning.industryId },
      });

      input.industries = { tagIds: [industry.id] };
    }
  }

  if (ctx.featureFlags.CAN_ACCESS_LEVEL_FRAMEWORKS) {
    input.benchmarkLevelsRange = await fetchBenchmarkLevelsRange(ctx, {
      benchmarkLevelIds,
      useBenchmarkLevelsInShare: true,
    });
  }

  return getMarketDataEmployees(ctx, input);
};
