import { UserRole } from "@prisma/client";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { isNull } from "~/lib/lodash";
import { type AuthenticatedUser } from "~/lib/session";
import { getId, isIn } from "~/lib/utils";
import { fetchRequiredAuthenticatedUser, type NullableAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { canImpersonateUsers } from "~/services/user/permissions/actionPermissions";

export const resolveImpersonatedUser = async (
  ctx: AppContext,
  params: {
    subsidiaryId?: string | number | undefined;
    impersonatingUser: NullableAuthenticatedUser;
    impersonatedUserId: string | number | null;
  }
) => {
  if (isNull(params.impersonatingUser)) {
    return null;
  }

  if (isNull(params.impersonatedUserId)) {
    return null;
  }

  const hasUserImpersonationPermission = value(() => {
    // When in a subsidiary, user is considered ADMIN
    if (!!params.subsidiaryId) {
      return true;
    }

    return canImpersonateUsers({
      user: params.impersonatingUser,
      subscriptions: ctx.subscriptions,
      featureFlags: ctx.featureFlags,
      permissionsSchema: ctx.permissionsSchema,
    });
  });

  const canImpersonateFromSubsidiary = await value(async () => {
    if (!params.subsidiaryId) {
      return true;
    }

    const subsidiary = await ctx.prisma.company.findUniqueOrThrow({
      where: { id: +params.subsidiaryId },
      select: { masterUsers: { select: { id: true } } },
    });

    return isIn(params.impersonatingUser?.id ?? ctx.user?.id, subsidiary.masterUsers.map(getId));
  });

  // Re-calculating here, avoids risking a loop with the session
  if (!hasUserImpersonationPermission || !canImpersonateFromSubsidiary) {
    return null;
  }

  const impersonatedUser = await fetchRequiredAuthenticatedUser(ctx, {
    userId: +params.impersonatedUserId,
    ...(!params.impersonatingUser.isSuperAdmin && {
      extraWhere: {
        companyId: params.subsidiaryId ? +params.subsidiaryId : params.impersonatingUser.companyId,
      },
    }),
  });

  // When in a subsidiary, user is considered ADMIN
  if (
    !params.subsidiaryId &&
    !isUserAllowedToImpersonateRole({ user: params.impersonatingUser, role: impersonatedUser.permissions.role })
  ) {
    return null;
  }

  impersonatedUser.isSuperAdmin = false;

  return impersonatedUser;
};

export const isUserAllowedToImpersonateRole = (params: {
  user: Pick<AuthenticatedUser, "isSuperAdmin" | "permissions">;
  role: UserRole | null | undefined;
}): boolean => {
  if (params.user.isSuperAdmin) {
    return true;
  }

  if (!params.role) {
    return false;
  }

  const allowedRoles = match(params.user.permissions.role)
    .with(UserRole.ADMIN, () => [UserRole.ADMIN, UserRole.HR, UserRole.RECRUITER, UserRole.EMPLOYEE])
    .with(UserRole.HR, () => [UserRole.RECRUITER, UserRole.EMPLOYEE])
    .otherwise(() => []);

  return isIn(params.role, allowedRoles);
};
