import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { compact } from "~/lib/lodash";
import { updateEmployeesStats } from "~/services/employee-stats/updateEmployeesStats";
import { createOrUpdateEmployeeMappingFromExternalEmployee } from "~/services/employee/employeeMapping";
import { externalEmployeeMappingUpdateArgs } from "~/services/external-employee/externalEmployeeMapping";
import { synchroniseCompanySalaryRangeEmployees } from "~/services/salary-bands/configuration/synchroniseCompanySalaryRangeEmployees";

export const updateExternalEmployeesAfterMapping = async (
  ctx: AppContext,
  externalEmployeeIds: number[],
  companyId: number
) => {
  const impactedExternalEmployees = await ctx.prisma.externalEmployee.findMany({
    where: { id: { in: externalEmployeeIds }, companyId },
    ...externalEmployeeMappingUpdateArgs,
  });

  const impactedEmployees = await mapSeries(impactedExternalEmployees, async (externalEmployee) =>
    createOrUpdateEmployeeMappingFromExternalEmployee(ctx, externalEmployee)
  );

  // (flag) eligible for pub/sub
  await updateEmployeesStats(ctx, {
    companyId,
    employeesIds: compact(impactedEmployees).map((employee) => employee.id),
  });

  // (flag) eligible for pub/sub
  await synchroniseCompanySalaryRangeEmployees(ctx, { companyId });
};
