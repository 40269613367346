import { type Currency } from "@prisma/client";
import { match } from "ts-pattern";
import { convertCurrency } from "~/lib/money";
import { computeDatasetEmployeeWeight } from "~/services/dataset-employee/computeCompensationDataWeight";
import { Measure } from "~/services/employee";

type ComputeDatasetEmployeeCompensationInput = {
  baseSalary: number;
  fixedBonus: number | null;
  fixedBonusPercentage?: number | null;
  onTargetBonus: number | null;
  onTargetBonusPercentage?: number | null;
  currencyCode: string;
  euroExchangeRate: number;
  lastValidAt: Date | null;
  companyId: number | null;
  churnedCompanyId: string | null;
  id: number;
};

type ComputeDatasetEmployeeCompensationOptions = {
  targetCurrency?: Pick<Currency, "code" | "euroExchangeRate">;
  measure?: Measure;
};

export const computeDatasetEmployeeCompensation = (
  datasetEmployee: ComputeDatasetEmployeeCompensationInput,
  options?: ComputeDatasetEmployeeCompensationOptions
) => {
  const { measure = Measure.TotalCash, targetCurrency } = options ?? {};

  const value = match(measure)
    .with(Measure.BaseSalary, () => {
      return datasetEmployee.baseSalary;
    })
    .with(Measure.TotalCash, () => {
      let totalCash = datasetEmployee.baseSalary;

      if (datasetEmployee.onTargetBonusPercentage) {
        totalCash += datasetEmployee.baseSalary * datasetEmployee.onTargetBonusPercentage;
      } else if (datasetEmployee.onTargetBonus) {
        totalCash += datasetEmployee.onTargetBonus;
      }

      if (datasetEmployee.fixedBonusPercentage) {
        totalCash += datasetEmployee.baseSalary * datasetEmployee.fixedBonusPercentage;
      } else if (datasetEmployee.fixedBonus) {
        totalCash += datasetEmployee.fixedBonus;
      }

      return totalCash;
    })
    .with(Measure.OnTargetBonus, () => {
      return datasetEmployee.onTargetBonus ?? 0;
    })
    .with(Measure.OnTargetEarnings, () => {
      return datasetEmployee.baseSalary + (datasetEmployee.onTargetBonus ?? 0);
    })
    .with(Measure.FixedBonus, () => {
      return datasetEmployee.fixedBonus ?? 0;
    })
    .with(Measure.ProfitSharing, () => {
      return datasetEmployee.baseSalary; // TODO: implement profit sharing
    })
    .with(Measure.Equity, () => {
      return datasetEmployee.baseSalary; // TODO: implement equity
    })
    .with(Measure.PaidBonus, () => {
      return datasetEmployee.baseSalary; // TODO: implement paid bonus
    })
    .exhaustive();

  const currencyOnDatasetEmployee = {
    euroExchangeRate: datasetEmployee.euroExchangeRate,
    code: datasetEmployee.currencyCode,
  };

  const convertedAmount = targetCurrency
    ? convertCurrency(value, currencyOnDatasetEmployee, targetCurrency)
    : value / currencyOnDatasetEmployee.euroExchangeRate;

  const weight = computeDatasetEmployeeWeight(datasetEmployee);

  return {
    value: Math.round(convertedAmount),
    weight,
    companyId: datasetEmployee.companyId ?? datasetEmployee.churnedCompanyId,
    id: datasetEmployee.id,
  };
};
